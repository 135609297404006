import React, { useState } from 'react'
import Send from '../../../assets/images/send.png'
const InputSend = (props) => {
  const [textValue, settextValue] = useState('')
  return (
    <div className="inputSend">
      <form>
        <textarea
          rows="1"
          type="text"
          placeholder={props.placeholder}
          value={textValue}
          onChange={(e) => settextValue(e.target.value)}
        ></textarea>
        <img
          onClick={(e) => {
            props.onClick(e, textValue)
            settextValue('')
          }}
          className="input__send"
          src={Send}
          alt="img"
        />
      </form>
      <div className="clear"></div>
    </div>
  )
}

export default InputSend
