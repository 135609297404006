import { getById } from '../../helper/utils'
import {
  APP_LOGOUT,
  GET_CHAPTERS_START,
  GET_CHAPTERS_SUCCESS,
  GET_CHAPTERS_FAILURE,
  CLEAR_CHAPTER,
  CREATE_CHAPTER_START,
  CREATE_CHAPTER_SUCCESS,
  CREATE_CHAPTER_FAILURE,
  EDIT_CHAPTER_START,
  EDIT_CHAPTER_SUCCESS,
  EDIT_CHAPTER_FAILURE,
  DELETE_CHAPTER_START,
  DELETE_CHAPTER_SUCCESS,
  DELETE_CHAPTER_FAILURE,
} from '../actions'

let initialState = {
  error: false,
  isChaptersLoading: false,
  chapters: [],
  chaptersById: {},
}

export const chapter = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case CLEAR_CHAPTER:
      console.log('here')
      return Object.assign({}, state, {
        chapters: [],
      })
    case GET_CHAPTERS_START:
      return {
        ...state,
        isChaptersLoading: true,
      }
    case GET_CHAPTERS_SUCCESS:
      return {
        ...state,
        isChaptersLoading: false,
        error: false,
        chapters: action.chapters,
        chaptersById: getById({ data: action.chapters, key: '_id' }),
      }
    case GET_CHAPTERS_FAILURE:
      return {
        ...state,
        isChaptersLoading: false,
        error: true,
      }
    case CREATE_CHAPTER_START:
      return {
        ...state,
        isChaptersLoading: true,
        error: false,
      }
    case CREATE_CHAPTER_SUCCESS:
      return {
        ...state,
        isChaptersLoading: false,
        error: false,
      }
    case CREATE_CHAPTER_FAILURE:
      return {
        ...state,
        isChaptersLoading: false,
        error: true,
      }
    case EDIT_CHAPTER_START:
      return {
        ...state,
        isChaptersLoading: true,
        error: false,
      }
    case EDIT_CHAPTER_SUCCESS:
      return {
        ...state,
        isChaptersLoading: false,
        error: false,
      }
    case EDIT_CHAPTER_FAILURE:
      return {
        ...state,
        isChaptersLoading: false,
        error: true,
      }
    case DELETE_CHAPTER_START:
      return {
        ...state,
        isChaptersLoading: true,
        error: false,
      }
    case DELETE_CHAPTER_SUCCESS:
      return {
        ...state,
        isChaptersLoading: false,
        error: false,
        chapters: action.chapters,
      }
    case DELETE_CHAPTER_FAILURE:
      return {
        ...state,
        isChaptersLoading: false,
        error: true,
      }
    default:
      return state
  }
}

export const getIsChaptersLoading = (state) => state.chapter.isChaptersLoading
export const getChapters = (state) =>
  state.chapter.chapters.filter((chapter) => chapter?.visibility)
export const getChaptersById = (state) => state.chapter.chaptersById

export const getIsError = (state) => state.chapter.error
