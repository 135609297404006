import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { usePrevious, subjectPics } from '../../helpers'

import Loader from '../../assets/molecules/loader'
import Select from '../../assets/molecules/select'
import SubjectCard from '../../assets/molecules/subjectCard'
import NoPending from '../../../assets/images/no-pending.png'
import PopupForm from '../../assets/layout/popupForm'
import { AlertSuccess, AlertError } from '../../assets/molecules/alerts'

//actions
import {
  deleteSubject,
  editSubject,
  fetchBatchSubjectDataById,
  fetchSubjects,
} from '../../../redux/action/subject'
import {
  setBatch,
  setBatchSubjectId,
  setSubject,
} from '../../../redux/action/ui'
import { postSubject } from '../../../redux/action/subject'
import { startFileUploads, clearFileUpload } from '../../../redux/action/upload'

//reducers
import { getJwt } from '../../../redux/reducer/account'
import {
  groupByBatchSubject,
  isSubjectsloading,
  getIsError,
  getEditSubjectLoading,
  getDeleteSubjectLoading,
} from '../../../redux/reducer/subject'
import { getBatch, getSubjectId } from '../../../redux/reducer/ui'
import { getBatches } from '../../../redux/reducer/batches'
import { getIsLoadingUploadFile, getFile } from '../../../redux/reducer/upload'

//helpers
import { subjectIcons, subjectPicsByUrl } from '../../helpers'
import Popup from '../../assets/layout/popupConfirm'

//utils
import { getFormData } from '../../../helper/utils'

const Subject = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const fileInputRef = React.useRef(null)
  //get data from redux store
  //account
  let jwt = useSelector((state) => getJwt(state))
  const error = useSelector(getIsError)
  const isLoading = useSelector(isSubjectsloading)
  const isLoadingPrev = usePrevious(isLoading)
  const isFileLoading = useSelector(getIsLoadingUploadFile)
  const isFileLoadingPrev = usePrevious(isFileLoading)
  const uploadedFile = useSelector(getFile)

  const editSubjectLoading = useSelector(getEditSubjectLoading)
  const editSubjectLaodingPrev = usePrevious(editSubjectLoading)
  const deleteSubjectLoading = useSelector(getDeleteSubjectLoading)
  const deleteSubjectLoadingPrev = usePrevious(deleteSubjectLoading)

  //subject
  let subjects = useSelector((state) => groupByBatchSubject(state))
  let batches = useSelector((state) => getBatches(state))
  const subjectId = useSelector((state) => getSubjectId(state))

  //ui for batch selected
  let batch = useSelector((state) => getBatch(state))
  let batchId = useSelector(getBatch)
  let [formData, setFormData] = useState({})
  let [logoValue, setLogoValue] = useState({})
  let [errorMessage, setErrorMessage] = useState('')
  const [formPopClassName, setFormPopClassName] = useState('hidePop')
  const [showSuccess, setShowSuccess] = useState(false)
  const [pressStatus, setPressStatus] = useState('')
  const subjectPicKeys = Object.keys(subjectPics)
  const [isEdit, setIsEdit] = useState(false)
  const [popup, setPopup] = useState('hidePop')
  const [showEditSuccess, setShowEditSuccess] = useState(false)
  const [editSubjectPopClassName, setEditSubjectPopClassName] =
    useState('hidePop')
  const [selectedSubject, setSelectedSubject] = useState('')
  const [popClassName, setPopClassName] = useState('hidePop')

  //state for upload file
  const [selectedFile, setSelectedFile] = useState()
  const [fileName, setFileName] = useState('')
  const [deleteSubjectId, setDeleteSubjectId] = useState('')

  useEffect(() => {
    dispatch(clearFileUpload())
  }, [dispatch])

  const showPopup = () => {
    setFormPopClassName('showPop')
    setPopup('showPop')
    setShowSuccess(false)
    setPressStatus('')
    setShowEditSuccess(false)
    setSelectedFile(null)
    setFileName('')
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const deletePopup = (subjectId) => {
    setPopClassName('showPop')
    setDeleteSubjectId(subjectId)
  }

  const ondeleteSubject = (e, subjectId) => {
    e.preventDefault()
    dispatch(deleteSubject({ jwt, subjectId }))
    setPopClassName('hidePop')
    dispatch(fetchSubjects({ jwt }))
    setPressStatus('delete')
  }

  const closePopup = () => {
    setFormPopClassName('hidePop')
    setFormData({})
    setLogoValue({})
    setPopClassName('hidePop')
    setPopup('hidePop')
    setIsEdit(false)
    setEditSubjectPopClassName('hidePopup')
    setSelectedFile(null)
    setFileName('')
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  useEffect(() => {
    dispatch(fetchSubjects({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    if (!editSubjectLoading && editSubjectLaodingPrev !== undefined) {
      setShowSuccess(true)
      dispatch(fetchSubjects({ jwt }))
      dispatch(clearFileUpload())
    }
  }, [editSubjectLoading, editSubjectLaodingPrev, dispatch, jwt])

  useEffect(() => {
    if (!deleteSubjectLoading && deleteSubjectLoadingPrev !== undefined) {
      setShowSuccess(true)
      dispatch(fetchSubjects({ jwt }))
      dispatch(clearFileUpload())
    }
  }, [deleteSubjectLoading, deleteSubjectLoadingPrev, dispatch, jwt])

  useEffect(() => {
    if (error) {
      setErrorMessage('Something went wrong. Please try again.')
    }
  }, [error])

  useEffect(() => {
    if (
      isLoadingPrev !== undefined &&
      isLoadingPrev !== isLoading &&
      !isLoading &&
      !error
    ) {
      history.push({
        pathname: '/batch/subjects',
        state: { status: 'success' },
      })
    }
  }, [error, history, isLoading, isLoadingPrev])

  useEffect(() => {
    if (
      isFileLoadingPrev !== undefined &&
      isFileLoading !== isFileLoadingPrev &&
      !isFileLoading
    ) {
      if (isEdit) {
        const payload = {
          subject_title: formData.title || undefined,
          subject_description: formData.description || undefined,
          subject_code: formData.code || undefined,
          subject_logo_url:
            uploadedFile && uploadedFile?.length > 0
              ? uploadedFile[0].publicUrl
              : formData?.subject_logo_url
              ? formData?.subject_logo_url
              : undefined,
          subject_logo: logoValue.logo ? logoValue.logo : undefined,
        }

        dispatch(
          editSubject({
            payload,
            subjectId: selectedSubject._id,
            jwt,
          })
        )
        closeEditSubjectPopup()
        setIsEdit(false)
        setFormData({})
        dispatch(fetchSubjects({ jwt }))
        setPressStatus('edit')
      } else {
        // Handle create subject after file upload
        const payload = {
          title: formData.title ? formData.title : undefined,
          subject_logo: logoValue.logo ? logoValue.logo : undefined,
          description: formData.description ? formData.description : undefined,
          code: formData.code ? formData.code : undefined,
          subject_logo_url:
            uploadedFile && uploadedFile.length > 0
              ? uploadedFile[0].publicUrl
              : undefined,
        }

        dispatch(postSubject({ payload, jwt, batchId }))
        setFormPopClassName('hidePop')
        setFormData({})
        setLogoValue({})
        setPressStatus('create')
        dispatch(fetchSubjects({ jwt }))
      }
    }
  }, [
    batchId,
    dispatch,
    formData.code,
    formData.description,
    formData.subject_logo_url,
    formData.title,
    isEdit,
    isFileLoading,
    isFileLoadingPrev,
    jwt,
    logoValue.logo,
    selectedSubject._id,
    uploadedFile,
  ])

  const onChange = (e) => {
    e.preventDefault()
    setFormData({ ...formData, [e.target.name]: e.target.value })
    errorMessage && setErrorMessage('')
  }

  const closeEditSubjectPopup = () => {
    setIsEdit(false)
    setFormData('')
    setEditSubjectPopClassName('hidePopup')
  }

  const onLogoChange = (e) => {
    setLogoValue({
      logo_name: subjectPics[e.target.value],
      logo: e.target.value,
    })
  }

  const onFileChange = (e) => {
    const files = e.target.files
    if (files.length > 0) {
      setSelectedFile(files)
      setFileName(files[0].name)
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    if (formData?.title && formData?.code) {
      if (selectedFile) {
        const uploadFormData = getFormData(selectedFile)
        if (selectedFile.length === 1)
          uploadFormData.append('name', selectedFile[0])
        await dispatch(startFileUploads({ body: uploadFormData, jwt }))
      } else {
        // If no file is selected, create subject without file
        const payload = {
          title: formData.title ? formData.title : undefined,
          subject_logo: logoValue.logo ? logoValue.logo : undefined,
          description: formData.description ? formData.description : undefined,
          code: formData.code ? formData.code : undefined,
        }

        dispatch(postSubject({ payload, jwt, batchId }))
        setFormPopClassName('hidePop')
        setFormData({})
        setLogoValue({})
        setPressStatus('create')
        dispatch(fetchSubjects({ jwt }))
      }
    } else {
      setErrorMessage('Please fill the form')
    }
  }

  const openEditSubjectPopup = (subject) => {
    setIsEdit(true)
    setEditSubjectPopClassName('showPop')
    setSelectedSubject(subject)
    setFormData({
      title: subject.subject_title || '',
      description: subject.subject_description || '',
      code: subject.subject_code || '',
      subject_logo_url: subject.subject_logo_url || '',
    })

    setLogoValue({
      logo_name: subject.subject_logo || '',
      logo: subjectPicsByUrl[subject.subject_logo] || '',
    })

    if (subject.subject_logo_url) {
      const urlParts = subject.subject_logo_url.split('/')
      const extractedFileName = urlParts[urlParts.length - 1]
      setFileName(extractedFileName)
    } else {
      setFileName('')
    }

    setShowEditSuccess(false)
  }

  const onChangeBatch = (e) => {
    e.preventDefault()
    dispatch(setBatch(e.target.value))
  }

  const onSubjectSelect = (subject) => {
    dispatch(setSubject(subject.subject_id, subject.subject_title))

    history.push('/batch/subjects/chapters')
  }

  const onSubjectEdit = async () => {
    if (selectedFile) {
      const formData = getFormData(selectedFile)

      if (selectedFile.length === 1) formData.append('name', selectedFile)

      await dispatch(
        startFileUploads({
          body: formData,
          jwt,
        })
      )
    } else {
      // If no new file is selected, just update the subject without file upload
      const payload = {
        subject_title: formData.title || undefined,
        subject_description: formData.description || undefined,
        subject_code: formData.code || undefined,
        subject_logo_url: formData?.subject_logo_url || undefined,
        subject_logo: logoValue.logo ? logoValue.logo : undefined,
      }

      await dispatch(
        editSubject({
          payload,
          subjectId: selectedSubject._id,
          jwt,
        })
      )
      closeEditSubjectPopup()
      setIsEdit(false)
      setFormData({})
      dispatch(fetchSubjects({ jwt }))
      setPressStatus('edit')
    }
  }

  const filteredList = batches.filter((bat) => bat._id === batch)
  const currentBatch = filteredList.length > 0 ? filteredList[0] : {}

  const noSubjectsAvailable = (type) => {
    return (
      <div className="noPending">
        <div>
          <img src={NoPending} alt="" />
          <h2 className="text-center">No subjects available.</h2>
          <button onClick={() => showPopup()} className="button--blue">
            + Add Subjects
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="tabs__container">
      <section>
        {isLoading ? (
          <Loader />
        ) : batch ? (
          <React.Fragment>
            <div className="row">
              <div className="col-md-6 padding--0">
                <h1>Subjects</h1>
                {/* <h1>Subjects of {currentBatch.title}</h1> */}
              </div>
              <div className="col-md-6 padding--0">
                {/* <Link
                  to={{
                    pathname: `/batch/subjects/create`,
                  }}
                > */}
                <button
                  onClick={() => showPopup()}
                  className="button--blue float-right"
                >
                  + Add Subject
                </button>
                {/* </Link> */}
              </div>
              <div className="clear"></div>
            </div>

            <div className="row">
              <div className="col-md-6 padding--0">
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">{currentBatch.title}</Link>
                    </li>
                    <li className="breadcrumb-item active">Subjects</li>
                  </ol>
                </nav>
              </div>
              <div className="col-md-6 padding--0">
                <div className="customTab__dropdowns">
                  <Select
                    onChange={(e) => onChangeBatch(e)}
                    defaultValue={currentBatch._id}
                  >
                    <option value="" selected>
                      Select an Option
                    </option>
                    {batches?.map((batch, index) => {
                      return (
                        <option value={batch._id} key={index}>
                          {batch.title}
                        </option>
                      )
                    })}
                  </Select>
                </div>
              </div>
              <div className="clear"></div>
            </div>

            {subjects[batch]?.length > 0
              ? subjects[batch]?.map((subject, index) => {
                  return (
                    <SubjectCard
                      key={index}
                      className="subjectCard__moderator"
                      icon={
                        subject?.subject_logo_url
                          ? subject?.subject_logo_url
                          : subjectPics[subject?.subject_logo]
                          ? subjectPics[subject?.subject_logo]
                          : subjectIcons['others']
                      }
                      subjectName={subject.subject_title}
                      subjectCode={subject.subject_code}
                      noOfStudents={subject.students.length}
                      noOfTeachers={subject.faculties.length}
                      profile="true"
                      moderator="true"
                      onClick={() => onSubjectSelect(subject)}
                      editOnClick={() => openEditSubjectPopup(subject)}
                      deleteOnClick={(e) => deletePopup(subject._id)}
                    />
                  )
                })
              : noSubjectsAvailable()}
          </React.Fragment>
        ) : (
          <div> Please select a batch </div>
        )}
      </section>
      <Popup
        className={popClassName}
        close={() => closePopup()}
        onClick={(e) => ondeleteSubject(e, deleteSubjectId)}
        popupHead="Delete"
        buttonText="proceed"
      >
        <h2>Are you sure want to delete?</h2>
      </Popup>
      <PopupForm
        className={!isEdit ? formPopClassName : editSubjectPopClassName}
        close={() => {
          closePopup()
        }}
        onClickButton={!isEdit ? onSubmit : onSubjectEdit}
        buttonText={isEdit ? 'EDIT SUBJECT' : 'CREATE SUBJECT'}
        popupHead={isEdit ? 'Edit Subject' : 'Create Subject'}
      >
        <form>
          <ol>
            <li>
              <h2>Which subject is this?</h2>
              <p>
                This information will help the students understand the subject
                name.
              </p>
              <div className="form-group sessionTitle">
                <input
                  name="title"
                  value={formData.title || ''}
                  type="text"
                  placeholder="Title"
                  onChange={onChange}
                />
              </div>
            </li>
            <li>
              <h2>What are the subject code and logo for the subject?</h2>
              <p>It will help user to identify subject easily </p>
              <div className="form-row">
                <div className="form-group col sessionTopic">
                  <input
                    name="code"
                    value={formData.code || ''}
                    type="text"
                    placeholder="Subject Code"
                    onChange={onChange}
                  />
                </div>
                <div className="form-group col sessionTitle">
                  <select
                    name="logo"
                    onChange={(e) => {
                      onLogoChange(e)
                    }}
                  >
                    <option value="" selected>
                      Select a logo
                    </option>
                    {subjectPicKeys?.map((key, index) => {
                      return (
                        <option
                          key={index}
                          value={key || ''}
                          selected={logoValue.logo_name === key}
                        >
                          {key}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </li>
            <li>
              <h2>Upload Logo for the subject?</h2>
              <p>It will help user to identify subject easily </p>
              <div className="form-group sessionTitle">
                <input
                  type="file"
                  placeholder="upload"
                  name="url"
                  onChange={onFileChange}
                  ref={fileInputRef}
                />
                {fileName && <p>Selected File: {fileName}</p>}
              </div>
            </li>
            <li>
              <h2>Describe about subject here</h2>
              <p>It will help to get an idea about subject</p>
              <div className="form-group sessionDescription">
                <textarea
                  name="description"
                  value={formData.description || ''}
                  placeholder="Description"
                  onChange={onChange}
                ></textarea>
              </div>
            </li>
          </ol>

          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
        </form>
      </PopupForm>
      {showSuccess && pressStatus === 'create' ? (
        <AlertSuccess>You have successfully added a Subject</AlertSuccess>
      ) : null}
      {showSuccess && pressStatus === 'edit' ? (
        <AlertSuccess>You have successfully edited the Subject</AlertSuccess>
      ) : null}
      {showSuccess && pressStatus === 'delete' ? (
        <AlertSuccess>You have successfully removed the Subject</AlertSuccess>
      ) : null}
    </div>
  )
}

export default Subject
