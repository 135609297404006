import {
  GET_SUBJECTS_START,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_FAILURE,
  GET_SUBJECTS_DATA_START,
  GET_SUBJECTS_DATA_SUCCESS,
  GET_SUBJECTS_DATA_FAILURE,
  APP_LOGOUT,
  GET_STUDENT_SUBJECTS_START,
  GET_STUDENT_SUBJECTS_SUCCESS,
  GET_STUDENT_SUBJECTS_FAILURE,
  GET_BATCH_SUBJETS_OF_STUDENT_START,
  GET_BATCH_SUBJETS_OF_STUDENT_SUCCESS,
  GET_BATCH_SUBJETS_OF_STUDENT_FAILURE,
  POST_SUBJECT_START,
  POST_SUBJECT_SUCCESS,
  POST_SUBJECT_FAILURE,
  POST_ASSIGN_FACULTY_BATCH_SUBJECT_START,
  POST_ASSIGN_FACULTY_BATCH_SUBJECT_SUCCESS,
  POST_ASSIGN_FACULTY_BATCH_SUBJECT_FAILURE,
  GET_BATCH_SUBJECT_DATA_BY_ID_START,
  GET_BATCH_SUBJECT_DATA_BY_ID_SUCCESS,
  GET_BATCH_SUBJECT_DATA_BY_ID_FAILURE,
  EDIT_SUBJECT_START,
  EDIT_SUBJECT_SUCCESS,
  EDIT_SUBJECT_FAILURE,
  DELETE_SUBJECT_START,
  DELETE_SUBJECT_SUCCESS,
  DELETE_SUBJECT_FAILURE,
} from '../actions'

import _ from 'underscore'

let initialState = {
  subjects: [],
  subjectsData: [],
  subjectsDataById: {},
  isSubjectsloading: false,
  isSubjectsDataloading: false,
  isFacultyAssignToSubjectLoading: false,
  error: false,
  batchSubjects: [],
  batchSubject: {},
  isEditSubjectLoading: false,
  isDeleteSubjectLoading: false,
}

export const subject = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_SUBJECTS_START:
      return {
        ...state,
        isSubjectsloading: true,
      }
    case GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        isSubjectsloading: false,
        error: false,
        subjects: action.subjects,
      }
    case GET_SUBJECTS_FAILURE:
      return {
        ...state,
        isSubjectsloading: false,
        error: true,
      }
    case GET_SUBJECTS_DATA_START:
      return {
        ...state,
        isSubjectsDataloading: true,
      }
    case GET_SUBJECTS_DATA_SUCCESS:
      return {
        ...state,
        subjectsData: action.subjects,
        subjectsDataById: action.subjectsById,
        error: false,
        isSubjectsDataloading: false,
      }
    case GET_SUBJECTS_DATA_FAILURE:
      return {
        ...state,
        isSubjectsDataloading: false,
        error: true,
      }
    case GET_BATCH_SUBJETS_OF_STUDENT_START:
      return {
        ...state,
        error: false,
        isSubjectsloading: true,
      }
    case GET_BATCH_SUBJETS_OF_STUDENT_SUCCESS:
      return {
        ...state,
        isSubjectsloading: false,
        error: false,
        batchSubjects: action.batchSubjects,
      }
    case GET_BATCH_SUBJETS_OF_STUDENT_FAILURE:
      return {
        ...state,
        isSubjectsloading: false,
        error: true,
      }
    case GET_STUDENT_SUBJECTS_START:
      return {
        ...state,
        isSubjectsloading: true,
        error: false,
      }
    case GET_STUDENT_SUBJECTS_SUCCESS:
      return {
        ...state,
        isSubjectsloading: false,
        error: false,
        subjects: action.subjects,
      }
    case GET_STUDENT_SUBJECTS_FAILURE:
      return {
        ...state,
        isSubjectsloading: false,
        error: true,
      }

    case POST_SUBJECT_START:
      return {
        ...state,
        isSubjectsloading: true,
        error: false,
      }

    case GET_BATCH_SUBJECT_DATA_BY_ID_START:
      return {
        ...state,
        isSubjectsloading: true,
        error: false,
      }

    case POST_SUBJECT_SUCCESS:
      return {
        ...state,
        isSubjectsloading: false,
        error: false,
      }

    case GET_BATCH_SUBJECT_DATA_BY_ID_SUCCESS:
      return {
        ...state,
        isSubjectsloading: false,
        error: false,
        batchSubject: action.batch_subject,
      }
    case POST_SUBJECT_FAILURE:
      return {
        ...state,
        isSubjectsloading: false,
        error: true,
      }

    case GET_BATCH_SUBJECT_DATA_BY_ID_FAILURE:
      return {
        ...state,
        isSubjectsloading: false,
        error: true,
      }

    case POST_ASSIGN_FACULTY_BATCH_SUBJECT_START:
      return {
        ...state,
        isFacultyAssignToSubjectLoading: true,
        error: false,
      }
    case POST_ASSIGN_FACULTY_BATCH_SUBJECT_SUCCESS:
      return {
        ...state,
        isFacultyAssignToSubjectLoading: false,
        error: false,
      }
    case POST_ASSIGN_FACULTY_BATCH_SUBJECT_FAILURE:
      return {
        ...state,
        isFacultyAssignToSubjectLoading: false,
        error: true,
      }
    case EDIT_SUBJECT_START:
      return {
        ...state,
        isEditSubjectLoading: true,
        error: false,
      }
    case EDIT_SUBJECT_SUCCESS:
      return {
        ...state,
        isEditSubjectLoading: false,
        error: false,
      }
    case EDIT_SUBJECT_FAILURE:
      return {
        ...state,
        isEditSubjectLoading: false,
        error: true,
      }
    case DELETE_SUBJECT_START:
      return {
        ...state,
        isDeleteSubjectLoading: true,
        error: false,
      }
    case DELETE_SUBJECT_SUCCESS:
      return {
        ...state,
        isDeleteSubjectLoading: false,
        error: false,
      }
    case DELETE_SUBJECT_FAILURE:
      return {
        ...state,
        isDeleteSubjectLoading: false,
        error: true,
      }
    default:
      return state
  }
}

export const getSubjects = (state) => state.subject.subjects
export const getSubjectsData = (state) => state.subject.subjects
export const getSubjectsDataById = (state) => state.subject.subjectsDataById
export const groupByBatchSubject = (state) => {
  const subjectGroupBy = _.groupBy(state.subject.subjects, 'batch_id')
  return subjectGroupBy
}
export const isSubjectsloading = (state) => state.subject.isSubjectsloading
export const getIsFacultyAssignToSubjectLoading = (state) =>
  state.subject.isFacultyAssignToSubjectLoading
export const getIsSubjectsGettingFailed = (state) => state.subject.error

export const getBatchSubjects = (state) => state.subject.batchSubjects
export const getBatchSubject = (state) => state.subject.batchSubject
export const getStudentsOfBatchSubject = (state) =>
  state.subject?.batchSubject?.students

export const getIsError = (state) => state.subject.error
export const getSubjectLoading = (state) => state.subject.isSubjectsDataloading

export const getEditSubjectLoading = (state) =>
  state.subject.isEditSubjectLoading
export const getDeleteSubjectLoading = (state) =>
  state.subject.isDeleteSubjectLoading
