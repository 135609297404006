import _ from 'underscore'

import {
  APP_LOGOUT,
  GET_TOPICS_START,
  GET_TOPICS_SUCCESS,
  GET_TOPICS_FAILURE,
  ADD_TOPIC_START,
  ADD_TOPIC_SUCCESS,
  ADD_TOPIC_FAILURE,
  EDIT_TOPIC_START,
  EDIT_TOPIC_SUCCESS,
  EDIT_TOPIC_FAILURE,
  DELETE_TOPIC_START,
  DELETE_TOPIC_SUCCESS,
  DELETE_TOPIC_FAILURE,
  GET_TOPIC_START,
  GET_TOPIC_SUCCESS,
  GET_TOPIC_FAILURE,
  CLEAR_TOPICS,
  GET_ALL_TOPICS_START,
  GET_ALL_TOPICS_SUCCESS,
  GET_ALL_TOPICS_FAILURE,
  GET_TOPICS_BY_FACULTIES_START,
  GET_TOPICS_BY_FACULTIES,
  GET_TOPICS_BY_FACULTIES_FAILURE,
  UPLOAD_FILE_START_TOPIC,
  UPLOAD_FILE_TOPIC,
  UPLOAD_FILE_FAIL_TOPIC,
} from '../actions'

let initialState = {
  error: false,
  isTopicsLoading: false,
  isFacultyTopicsLoading: false,
  isFacultyTopicsError: false,
  topics: [],
  topicsForTeachers: [],
  topicsById: {},
  topic: {},
  allTopics: [],
  status: '',
  fileTopic: {},
  isLoadingUploadFileTopic: false,
}

export const topic = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_TOPICS_START:
      return {
        ...state,
        error: false,
        isTopicsLoading: true,
        status: '',
      }
    case GET_TOPICS_SUCCESS:
      return {
        ...state,
        isTopicsLoading: false,
        error: false,
        topics: action.topics,
        topicsById: action.topicsById,
        status: '',
      }
    case GET_TOPICS_FAILURE:
      return {
        ...state,
        isTopicsLoading: false,
        error: true,
        status: '',
      }
    case ADD_TOPIC_START:
      return {
        ...state,
        isTopicsLoading: true,
        error: false,
        status: '',
      }
    case ADD_TOPIC_SUCCESS:
      return {
        ...state,
        isTopicsLoading: false,
        error: false,
        status: 'added',
      }
    case ADD_TOPIC_FAILURE:
      return {
        ...state,
        isTopicsLoading: false,
        status: '',
      }
    case EDIT_TOPIC_START:
      return {
        ...state,
        isTopicsLoading: true,
        error: false,
        status: '',
      }
    case EDIT_TOPIC_SUCCESS:
      return {
        ...state,
        isTopicsLoading: false,
        error: false,
        status: 'edited',
      }
    case EDIT_TOPIC_FAILURE:
      return {
        ...state,
        isTopicsLoading: false,
        error: true,
        status: '',
      }
    case DELETE_TOPIC_START:
      return {
        ...state,
        isTopicsLoading: true,
        error: false,
        status: '',
      }
    case DELETE_TOPIC_SUCCESS:
      return {
        ...state,
        isTopicsLoading: false,
        error: false,
        topics: action.topics,
        status: 'deleted',
      }
    case DELETE_TOPIC_FAILURE:
      return {
        ...state,
        isTopicsLoading: false,
        error: true,
        status: '',
      }
    case GET_TOPIC_START:
      return {
        ...state,
        error: false,
        isTopicsLoading: true,
      }
    case GET_TOPIC_SUCCESS:
      return {
        ...state,
        isTopicsLoading: false,
        error: false,
        topic: action.topic,
      }
    case GET_TOPIC_FAILURE:
      return {
        ...state,
        isTopicsLoading: false,
        error: true,
      }
    case CLEAR_TOPICS:
      return {
        ...state,
        topics: [],
        topicsById: {},
        error: true,
      }
    case GET_ALL_TOPICS_START:
      return {
        ...state,
        error: false,
        isTopicsLoading: true,
      }
    case GET_ALL_TOPICS_SUCCESS:
      return {
        ...state,
        isTopicsLoading: false,
        error: false,
        allTopics: action.topics,
      }
    case GET_ALL_TOPICS_FAILURE:
      return {
        ...state,
        isTopicsLoading: false,
        error: true,
      }
    case GET_TOPICS_BY_FACULTIES_START:
      return {
        ...state,
        isFacultyTopicsError: false,
        isFacultyTopicsLoading: true,
      }
    case GET_TOPICS_BY_FACULTIES:
      return {
        ...state,
        isFacultyTopicsLoading: false,
        isFacultyTopicsError: false,
        topicsForTeachers: action.topics,
      }
    case GET_TOPICS_BY_FACULTIES_FAILURE:
      return {
        ...state,
        isFacultyTopicsLoading: false,
        isFacultyTopicsError: true,
      }
    case UPLOAD_FILE_START_TOPIC:
      return Object.assign({}, state, {
        isLoadingUploadFileTopic: true,
        error: false,
      })
    case UPLOAD_FILE_TOPIC:
      return Object.assign({}, initialState, {
        fileTopic: action.data,
        isLoadingUploadFileTopic: false,
        error: false,
      })
    case UPLOAD_FILE_FAIL_TOPIC:
      return Object.assign({}, state, {
        error: true,
        isLoadingUploadFileTopic: false,
      })
    default:
      return state
  }
}

export const getIsTopicsLoading = (state) => state.topic.isTopicsLoading
export const getTopics = (state) => state.topic.topics
export const getAllTopics = (state) => state.topic.allTopics
export const getTopicsById = (state) => state.topic.topicsById

export const getTopicsGroupByFaculty = (state) =>
  _.groupBy(state.topic.topicsForTeachers, 'author_id')
export const getIsLoadingTopicsByTeachers = (state) =>
  state.topic.isFacultyTopicsLoading
export const getIsErrorTopicsByTeachers = (state) =>
  state.topic.isFacultyTopicsError

export const getTopic = (state) => state.topic.topic

export const getIsError = (state) => state.topic.error
export const getStatus = (state) => state.topic.status

export const getIsLoadingUploadFileTopic = (state) =>
  state.topic.isLoadingUploadFileTopic
export const getFileTopic = (state) => state.topic.fileTopic
