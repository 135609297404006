import {
  GET_STUDENT_START,
  GET_STUDENT_SUCCESS,
  GET_STUDENT_FAILURE,
  PATCH_STUDENT_START,
  PATCH_STUDENT_SUCCESS,
  PATCH_STUDENT_FAILURE,
  REMOVE_STUDENT_START,
  REMOVE_STUDENT_SUCCESS,
  REMOVE_STUDENT_FAILURE,
  UNARCHIVE_STUDENT_START,
  UNARCHIVE_STUDENT_SUCCESS,
  UNARCHIVE_STUDENT_FAILURE,
  POST_STUDENT_START,
  POST_STUDENT_SUCCESS,
  POST_STUDENT_FAILURE,
  POST_BULK_STUDENT_START,
  POST_BULK_STUDENT_SUCCESS,
  POST_BULK_STUDENT_FAILURE,
  PUT_STUDENT_NEW_USER_START,
  PUT_STUDENT_NEW_USER_SUCCESS,
  PUT_STUDENT_NEW_USER_FAIL,
  POST_STUDENT_SUBJECT_START,
  POST_STUDENT_SUBJECT_SUCCESS,
  POST_STUDENT_SUBJECT_FAILURE,
  DELETE_STUDENT_SUBJECT_START,
  DELETE_STUDENT_SUBJECT_SUCCESS,
  DELETE_STUDENT_SUBJECT_FAILURE,
} from '../actions'

import { checkStatus } from '../helpers.js'

const getStudentStart = () => {
  return {
    type: GET_STUDENT_START,
  }
}

const getStudentSuccess = (student) => {
  return {
    type: GET_STUDENT_SUCCESS,
    student,
  }
}
const getStudentFailure = () => {
  return {
    type: GET_STUDENT_FAILURE,
  }
}

const fetchDataForStudent = ({ jwt, studentId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/students/${studentId}`
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }
  return fetch(url, options)
}
export const fetchStudent = (data) => {
  return async (dispatch) => {
    dispatch(getStudentStart())
    try {
      const receivedData = await fetchDataForStudent(data)
      const response = await receivedData.json()
      let student = response.data.student
      dispatch(getStudentSuccess(student))
    } catch (err) {
      dispatch(getStudentFailure())
    }
  }
}

const getEditStudentStart = () => {
  return {
    type: PATCH_STUDENT_START,
  }
}

const getEditStudentSuccess = (student) => {
  return {
    type: PATCH_STUDENT_SUCCESS,
    student,
  }
}
const getEditStudentFailure = () => {
  return {
    type: PATCH_STUDENT_FAILURE,
  }
}

const editDataForStudent = ({ jwt, studentId, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/students/${studentId}`
  const options = {
    method: 'PATCH',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const editStudent = (data) => {
  return async (dispatch) => {
    dispatch(getEditStudentStart())
    try {
      const receivedData = await editDataForStudent(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      let student = response.student
      dispatch(getEditStudentSuccess(student))
    } catch (err) {
      dispatch(getEditStudentFailure())
    }
  }
}

const removeStudentStart = () => {
  return {
    type: REMOVE_STUDENT_START,
  }
}

const removeStudentSuccess = (student) => {
  return {
    type: REMOVE_STUDENT_SUCCESS,
  }
}
const removeStudentFailure = () => {
  return {
    type: REMOVE_STUDENT_FAILURE,
  }
}

const deleteStudentApiCall = ({ jwt, payload }) => {
  console.log(payload.student_id, 'payload.student_id')
  const url = `${process.env.REACT_APP_SERVER}/api/students`
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const deleteStudent = (data) => {
  return async (dispatch) => {
    dispatch(removeStudentStart())
    try {
      const receivedData = await deleteStudentApiCall(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      dispatch(removeStudentSuccess())
    } catch (err) {
      dispatch(removeStudentFailure())
    }
  }
}

const unarchiveStudentStart = () => {
  return {
    type: UNARCHIVE_STUDENT_START,
  }
}

const unarchiveStudentSuccess = (student) => {
  return {
    type: UNARCHIVE_STUDENT_SUCCESS,
    student,
  }
}
const unarchiveStudentFailure = () => {
  return {
    type: UNARCHIVE_STUDENT_FAILURE,
  }
}

const unarchiveStudentApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/students/dearc/student/${payload.student_id}`
  const options = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const unarchiveStudent = (data) => {
  return async (dispatch) => {
    dispatch(unarchiveStudentStart())
    try {
      const receivedData = await unarchiveStudentApiCall(data)
      const response = await receivedData.json()
      let student = response.data.student
      dispatch(unarchiveStudentSuccess(student))
    } catch (err) {
      dispatch(unarchiveStudentFailure())
    }
  }
}

//Add Student
const postStudentStart = () => ({
  type: POST_STUDENT_START,
})

const postStudentFail = (errMsg) => ({
  type: POST_STUDENT_FAILURE,
  errMsg,
})

const postStudentSuccess = (data) => ({
  type: POST_STUDENT_SUCCESS,
  data,
})

export const startAddStudent = (data) => {
  return async (dispatch) => {
    dispatch(postStudentStart())
    try {
      const receivedData = await addStudent(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(postStudentSuccess(response.data))
    } catch (err) {
      dispatch(postStudentFail())
    }
  }
}

const addStudent = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/students`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

//Add bulkStudent
const postBulkStudentStart = () => ({
  type: POST_BULK_STUDENT_START,
})

const postBulkStudentFail = (errMsg) => ({
  type: POST_BULK_STUDENT_FAILURE,
  errMsg,
})

const postBulkStudentSuccess = (data) => ({
  type: POST_BULK_STUDENT_SUCCESS,
  data,
})

export const startBulkAddStudent = (data) => {
  return async (dispatch) => {
    dispatch(postBulkStudentStart())
    try {
      const receivedData = await bulkAddStudent(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(postBulkStudentSuccess(response.data))
    } catch (err) {
      dispatch(postBulkStudentFail())
    }
  }
}

const bulkAddStudent = ({ jwt, payload, batchId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/batches/${batchId}/students/csv`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

//put new Student user
const putStudentUserStart = () => ({
  type: PUT_STUDENT_NEW_USER_START,
})

const putStudentUserFail = (errMsg) => ({
  type: PUT_STUDENT_NEW_USER_FAIL,
  errMsg,
})

const putStudentUserSuccess = (data) => ({
  type: PUT_STUDENT_NEW_USER_SUCCESS,
  data,
})

export const startPutStudentUser = (data) => {
  return async (dispatch) => {
    dispatch(putStudentUserStart())
    try {
      const receivedData = await putStudentUser(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(putStudentUserSuccess(response.data))
    } catch (err) {
      dispatch(putStudentUserFail())
    }
  }
}

const putStudentUser = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/students/new-user`
  const options = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

//delete student from subject
const removeStudentFromSubjectStart = () => {
  return {
    type: DELETE_STUDENT_SUBJECT_START,
  }
}

const removeStudentFromSubjectSuccess = (student) => {
  return {
    type: DELETE_STUDENT_SUBJECT_SUCCESS,
  }
}
const removeStudentFromSubjectFailure = () => {
  return {
    type: DELETE_STUDENT_SUBJECT_FAILURE,
  }
}

const deleteStudentFromSubjectApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/batch-subjects/students`
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const deleteStudentFromSubject = (data) => {
  return async (dispatch) => {
    dispatch(removeStudentFromSubjectStart())
    try {
      const receivedData = await deleteStudentFromSubjectApiCall(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      dispatch(removeStudentFromSubjectSuccess())
    } catch (err) {
      dispatch(removeStudentFromSubjectFailure())
    }
  }
}
//add students to subjects
const addStudentToSubjectStart = () => {
  return {
    type: POST_STUDENT_SUBJECT_START,
  }
}

const addStudentToSubjectSuccess = (student) => {
  return {
    type: POST_STUDENT_SUBJECT_SUCCESS,
  }
}
const addStudentToSubjectFailure = () => {
  return {
    type: POST_STUDENT_SUBJECT_FAILURE,
  }
}

const addStudentToSubjectApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/batch-subjects/students`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const addStudenttoSubject = (data) => {
  return async (dispatch) => {
    dispatch(addStudentToSubjectStart())
    try {
      const receivedData = await addStudentToSubjectApiCall(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      dispatch(addStudentToSubjectSuccess())
    } catch (err) {
      dispatch(addStudentToSubjectFailure())
    }
  }
}
