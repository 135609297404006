import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Tab, Tabs } from 'react-bootstrap'

//assets
import Loader from '../../assets/molecules/loader'
import Select from '../../assets/molecules/select'
import PopupConfirm from '../../assets/layout/popupConfirm'
import { AlertSuccess, AlertError } from '../../assets/molecules/alerts'
import Button from '../../assets/molecules/button'

//reducers
import { getBatch } from '../../../redux/reducer/ui'
import {
  getBatches,
  getBatchesById,
  getIsLoading,
} from '../../../redux/reducer/batches'
import { setBatch } from '../../../redux/action/ui'
import {
  groupByBatchSubject,
  isSubjectsloading,
} from '../../../redux/reducer/subject'
import {
  getIsLoadingStudentSubject,
  getIsErrorStudentSubject,
} from '../../../redux/reducer/students'
import {
  addStudenttoSubject,
  deleteStudentFromSubject,
} from '../../../redux/action/students'
import { getJwt } from '../../../redux/reducer/account'
import { fetchBatches } from '../../../redux/action/batches'
import { getStudents } from '../../../redux/reducer/students'
import { fetchSubjects } from '../../../redux/action/subject'
import { usePrevious } from '../../helpers'

const AddSubjects = () => {
  const dispatch = useDispatch()

  const [selectedSubjectStudents, setSelectedStudents] = useState([])
  const [removeStudents, setRemoveStudents] = useState({})
  const [addStudents, setAddStudents] = useState({})
  const [popRemoveClassName, setPopRemoveClassName] = useState('hidePop')
  const [popAddClassName, setPopAddClassName] = useState('hidePop')
  const [removeSuccessAlert, setRemoveSucessAlert] = useState(false)
  const [addSuccessAlert, setAddSuccessAlert] = useState(false)
  const [selectAddAll, setSelectAddAll] = useState(false)
  const [selectedSubject, setSelectedSubject] = useState('')
  const [nonAddedStudent, setNonAddedStudent] = useState([])

  let isLoadingStudentSubject = useSelector(getIsLoadingStudentSubject)
  let subjects = useSelector(groupByBatchSubject)
  let isLoadingStudentSubjectPrev = usePrevious(isLoadingStudentSubject)
  let isLoading = useSelector(getIsLoading)
  let isLoadingPrev = usePrevious(isLoading)
  let subjectLoading = useSelector(isSubjectsloading)
  let subjectLoadingPrev = usePrevious(subjectLoading)
  let batches = useSelector(getBatches)
  let batch = useSelector(getBatch)
  const [batchSelected, setBatchSelected] = useState(batch)
  let students = useSelector(getStudents)
  const batchesById = useSelector(getBatchesById)
  const jwt = useSelector((state) => getJwt(state))

  useEffect(() => {
    dispatch(fetchBatches({ jwt }))
    dispatch(fetchSubjects({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    if (!isLoadingStudentSubject && isLoadingStudentSubjectPrev !== undefined) {
      dispatch(fetchBatches({ jwt }))
      dispatch(fetchSubjects({ jwt }))
    }
  }, [dispatch, isLoadingStudentSubject, isLoadingStudentSubjectPrev, jwt])

  useEffect(() => {
    if (
      !isLoading &&
      !subjectLoading &&
      isLoadingPrev !== undefined &&
      subjectLoadingPrev !== undefined &&
      selectedSubject
    ) {
      let allBatchStudents = batchesById[batchSelected]
        ? batchesById[batchSelected].students
        : []

      let studentsFromSubjects = subjects[batch]

      let allStudentsFromSubjects = studentsFromSubjects.find((stu, index) => {
        return stu._id === selectedSubject
      })
      setSelectedStudents(allStudentsFromSubjects)
      let nonAdded = findNonMatchingEntries(
        allBatchStudents,
        allStudentsFromSubjects ? allStudentsFromSubjects.students : []
      )
      setNonAddedStudent(allStudentsFromSubjects ? nonAdded : [])
    }
  }, [
    selectedSubject,
    students,
    isLoading,
    subjectLoading,
    isLoadingPrev,
    subjectLoadingPrev,
    batchesById,
    batchSelected,
    subjects,
    batch,
  ])

  const onChangeBatch = (e) => {
    e.preventDefault()
    setBatchSelected(e.target.value)
    setSelectedSubject('')
    dispatch(setBatch(e.target.value))
  }
  const onChangeSubject = (e) => {
    e.preventDefault()
    setSelectedSubject(e.target.value)
  }
  function findNonMatchingEntries(arr1, arr2) {
    const nonMatchingInArr1 = arr1.filter(
      (item1) => !arr2.some((item2) => item2.id === item1.id)
    )
    const nonMatchingInArr2 = arr2.filter(
      (item2) => !arr1.some((item1) => item1.id === item2.id)
    )
    return [...nonMatchingInArr1, ...nonMatchingInArr2]
  }

  const handleRemoveStudentsSubmit = () => {
    let student_ids = Object.keys(removeStudents).filter(
      (item) => removeStudents[item]
    )
    const payload = {
      batch_subject_id: selectedSubject,
      student_ids,
    }
    dispatch(deleteStudentFromSubject({ jwt, payload }))
      .then(setRemoveSucessAlert(true))
      .then(setPopRemoveClassName('hidePop'))
    setRemoveStudents({})
  }

  const handleAddStudentsSubmit = () => {
    let student_ids = Object.keys(addStudents).filter(
      (item) => addStudents[item]
    )
    const payload = {
      batch_subject_id: selectedSubject,
      student_ids,
    }
    dispatch(addStudenttoSubject({ jwt, payload }))
      .then(setAddSuccessAlert(true))
      .then(setPopAddClassName('hidePop'))
    setAddStudents({})
    setSelectAddAll(false)
  }

  const onRemoveStudentSelect = (e) => {
    setRemoveStudents({
      ...removeStudents,
      [e.target.value]: !removeStudents[e.target.value],
    })

    let studentState = { ...removeStudents }
    studentState[e.target.value] = !studentState[e.target.value]
  }
  const onAddStudentSelect = (e) => {
    setAddStudents({
      ...addStudents,
      [e.target.value]: !addStudents[e.target.value],
    })
    let studentState = { ...addStudents }
    studentState[e.target.value] = !studentState[e.target.value]

    const checked = Object.keys(studentState).filter((id) => studentState[id])

    if (checked.length === Object.keys(addStudents).length)
      setSelectAddAll(true)
    else setSelectAddAll(false)
  }
  const showRemoveStudentspop = () => {
    setPopRemoveClassName('showPop')
  }
  const closeRemovePopup = () => {
    setPopRemoveClassName('hidePop')
  }
  const onSelectAllAddChange = () => {
    setSelectAddAll(!selectAddAll)

    let updatedAddStudents = {}
    nonAddedStudent.forEach((student) => {
      updatedAddStudents[student.id] = !selectAddAll
    })

    setAddStudents(updatedAddStudents)
  }
  return (
    <div className="body__container">
      <div className="tabs__container">
        <section>
          {isLoading && !isLoadingStudentSubject ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className="row">
                <div className="col-md-6 padding--0">
                  <h1>Add/Remove Student from Subject</h1>
                </div>
                <div className="clear"></div>
              </div>

              <div className="row">
                <div className="col-md-6 padding--0">
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Add/Remove-Subject
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="col-md-6 padding--0">
                  <div className="customTab__dropdowns">
                    <Select
                      onChange={(e) => onChangeSubject(e)}
                      value={selectedSubject}
                    >
                      <option value="">Select Subject</option>
                      {subjects[batch]?.map((subject, index) => {
                        return (
                          <option value={subject._id} key={index}>
                            {subject.subject_title}
                          </option>
                        )
                      })}
                    </Select>
                  </div>
                  <div className="customTab__dropdowns">
                    <Select onChange={(e) => onChangeBatch(e)}>
                      <option value="All">All</option>
                      {batches.map((batch, index) => {
                        return (
                          <option
                            value={batch._id}
                            key={index}
                            selected={batch._id === batchSelected}
                          >
                            {batch.title}
                          </option>
                        )
                      })}
                    </Select>
                  </div>
                </div>
                <div className="clear"></div>
              </div>
              <Tabs
                className="square__tabs square__tabs--twoItems assessment__tab"
                defaultActiveKey="upcoming"
              >
                <Tab eventKey="upcoming" title={'Add-Student'}>
                  <p className="selfAssesment__info">
                    You can Assign Student to Subjects here.
                  </p>
                  <div className="clear"></div>
                  {!!selectedSubject ? (
                    <div className="table-responsive">
                      <table className={'table'}>
                        <thead>
                          <tr>
                            <th>Sl No.</th>
                            <th>Student Name</th>
                            <th>Add student</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td>
                              <input
                                type="checkbox"
                                className="checkbox__wrapperAddRemove"
                                onClick={onSelectAllAddChange}
                                checked={selectAddAll}
                              />
                            </td>
                          </tr>
                          {nonAddedStudent?.map((stu, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td className="pointer">
                                  <span>{stu.name}</span>
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="checkbox__wrapperAddRemove"
                                    value={stu.id}
                                    onClick={(e) => onAddStudentSelect(e)}
                                    checked={addStudents[stu.id]}
                                  />
                                </td>
                              </tr>
                            )
                          })}
                          <tr>
                            <td colSpan={1}></td>
                            <td></td>
                            <td>
                              <Button
                                className="button--tiny"
                                color="blue"
                                text="Add"
                                click={() => setPopAddClassName('showPop')}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Tab>
                <Tab eventKey="previous" title={'Remove-Student'}>
                  <p className="selfAssesment__info">
                    You can Remove Student to Subjects here.
                  </p>
                  <div className="clear"></div>
                  {!!selectedSubject ? (
                    <div className="table-responsive">
                      <table className={'table'}>
                        <thead>
                          <tr>
                            <th>Sl No.</th>
                            <th>Student Name</th>
                            <th>Remove Student</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedSubjectStudents ? (
                            selectedSubjectStudents.students?.map(
                              (stu, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td className="pointer">
                                      <span>{stu.name}</span>
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        className="checkbox__wrapperAddRemove"
                                        value={stu.id}
                                        onClick={(e) =>
                                          onRemoveStudentSelect(e)
                                        }
                                        checked={removeStudents[stu.id]}
                                      />
                                    </td>
                                  </tr>
                                )
                              }
                            )
                          ) : (
                            <tr></tr>
                          )}
                          <tr>
                            <td></td>
                            <td></td>
                            <td>
                              <Button
                                className="button--tiny"
                                color="blue"
                                text="Remove"
                                click={showRemoveStudentspop}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Tab>
              </Tabs>
            </React.Fragment>
          )}
        </section>
        <div className="clear"></div>
        <PopupConfirm
          className={popRemoveClassName}
          buttonText="Remove"
          popupHead={'Remove Student'}
          close={closeRemovePopup}
          onClick={handleRemoveStudentsSubmit}
        >
          <h2>Are you 100% sure it is the correct student?</h2>
          <p>Are you sure you want to remove this student's from subject !!!</p>
        </PopupConfirm>
        <PopupConfirm
          className={popAddClassName}
          buttonText="Add"
          popupHead={'Add Student'}
          close={() => setPopAddClassName('hidePop')}
          onClick={handleAddStudentsSubmit}
        >
          <h2>Are you 100% sure it is the correct student?</h2>
          <p>Are you sure you want to remove this student's from subject !!!</p>
        </PopupConfirm>
        {removeSuccessAlert && (
          <AlertSuccess>
            You have successfully Removed selected Students
          </AlertSuccess>
        )}
        {addSuccessAlert && (
          <AlertSuccess>
            You have successfully Added selected Students
          </AlertSuccess>
        )}
      </div>
    </div>
  )
}

export default AddSubjects
