import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import _ from 'underscore'

//assets
import Loader from '../../assets/molecules/loader'
import Col from '../../assets/molecules/column'
import Select from '../../assets/molecules/select'
import { downloadReport } from '../../helpers'

//helpers
import { getById } from '../../../helper/utils'

//action
import {
  fetchDailyReport,
  fetchAttendanceBySubject,
  fetchAttendanceByBatch,
  fetchAttendanceWeekly,
  fetchAttendanceLiveClass,
} from '../../../redux/action/reports'

//reducer
import {
  getIsStudentDailyReportLoading,
  getStudentDailyReport,
  getIsAttendanceBySubjectLoading,
  getAttendanceBySubject,
  getIsAttendanceByBatchLoading,
  getAttendanceByBatch,
  getAttendanceByWeek,
  getIsAttendanceByWeekLoading,
  getIsAttendanceLiveClassLoading,
  getAttendanceLiveClass,
} from '../../../redux/reducer/reports'

//account
import { getJwt, getUsersInstitutionId } from '../../../redux/reducer/account'

const Reports = () => {
  const dispatch = useDispatch()
  const [date, setDate] = useState(new Date())
  const [reportType, setReportType] = useState('report')
  const [batch, setBatch] = useState('all')

  const jwt = useSelector(getJwt)
  const institutionId = useSelector(getUsersInstitutionId)

  const isdailyReportLoading = useSelector(getIsStudentDailyReportLoading)
  const dailyReport = useSelector(getStudentDailyReport)
  const isAttendanceByBatchLoading = useSelector(getIsAttendanceByBatchLoading)
  const attendanceByBatch = useSelector(getAttendanceByBatch)
  const isAttendanceBySubjectLoading = useSelector(
    getIsAttendanceBySubjectLoading
  )
  const attendanceBySubject = useSelector(getAttendanceBySubject)
  const attendanceByWeek = useSelector(getAttendanceByWeek)
  const isAttendanceByWeekLoading = useSelector(getIsAttendanceByWeekLoading)
  const attendanceLiveClass = useSelector(getAttendanceLiveClass)
  const isAttendanceLiveClassLoading = useSelector(
    getIsAttendanceLiveClassLoading
  )

  useEffect(() => {
    const dateFormated = moment(date).format('YYYY-MM-DD')
    dispatch(fetchDailyReport({ jwt, institutionId, dateFormated }))
    dispatch(fetchAttendanceByBatch({ jwt, institutionId, dateFormated }))
    dispatch(fetchAttendanceWeekly({ jwt, institutionId, dateFormated }))
    dispatch(fetchAttendanceLiveClass({ jwt, dateFormated }))
    // dispatch(fetchAttendanceBySubject({ jwt, institutionId, dateFormated }))
  }, [date, dispatch, institutionId, jwt])

  const onChangeDate = (date) => {
    setDate(date)
  }

  const onChangeBatch = (e) => {
    setBatch(e.target.value)
  }

  const batchWeeklyAttendance = (batch) => {
    let dateToday = moment().format('YYYY-MM-DD')
    let groupByBatch = _.groupBy(attendanceByWeek, 'batch_title')
    let stdArr = groupByBatch[batch]
    let groupById = _.groupBy(stdArr, 'student_id')
    let groupValue = Object.values(groupById)
    let batchAttendance = []
    batchAttendance = groupValue.map((val) => {
      return {
        batch_title: val[0].batch_title,
        student_name: val[0].student_name,
        attendMon:
          val[0].date <= dateToday
            ? val[0]?.attendance.charAt(0).toUpperCase() +
              val[0]?.attendance.substr(1).toLowerCase()
            : '---',
        attendTues:
          val[1].date <= dateToday
            ? val[1]?.attendance.charAt(0).toUpperCase() +
              val[1]?.attendance.substr(1).toLowerCase()
            : '---',
        attendWed:
          val[2].date <= dateToday
            ? val[2]?.attendance.charAt(0).toUpperCase() +
              val[2]?.attendance.substr(1).toLowerCase()
            : '---',
        attendThurs:
          val[3].date <= dateToday
            ? val[3]?.attendance.charAt(0).toUpperCase() +
              val[3]?.attendance.substr(1).toLowerCase()
            : '---',
        attendFri:
          val[4].date <= dateToday
            ? val[4]?.attendance.charAt(0).toUpperCase() +
              val[4]?.attendance.substr(1).toLowerCase()
            : '---',
        attendSat:
          val[5].date <= dateToday
            ? val[5]?.attendance.charAt(0).toUpperCase() +
              val[5]?.attendance.substr(1).toLowerCase()
            : '---',
        attendSun:
          val[6].date <= dateToday
            ? val[6]?.attendance.charAt(0).toUpperCase() +
              val[6]?.attendance.substr(1).toLowerCase()
            : '---',
      }
    })
    return batchAttendance
  }

  const batchLiveClassAttendance = (batch) => {
    let groupByBatch = _.groupBy(attendanceLiveClass, 'batch_title')
    let sessionArr = groupByBatch[batch]
    let liveClass = []
    liveClass = sessionArr.map((session) => {
      return {
        student_name: session.student_name,
        batch_title: session.batch_title,
        subject_title: session.subject_title,
        topic_title: session.topic_title,
      }
    })
    return liveClass
  }

  const onChangeReport = (e) => {
    setReportType(e.target.value)
  }

  const getBaths = () => {
    let batch_ids = {}
    let list_of_batch_ids = []

    switch (reportType) {
      case 'report':
        batch_ids = _.groupBy(dailyReport, 'batch_title')
        list_of_batch_ids = Object.keys(batch_ids)
        return {
          batch_ids,
          list_of_batch_ids,
        }
      case 'attendance':
        batch_ids = _.groupBy(attendanceByBatch, 'batch_title')
        list_of_batch_ids = Object.keys(batch_ids)
        return {
          batch_ids,
          list_of_batch_ids,
        }
      case 'attendanceWeekly':
        batch_ids = _.groupBy(attendanceByWeek, 'batch_title')
        list_of_batch_ids = Object.keys(batch_ids)
        return {
          batch_ids,
          list_of_batch_ids,
        }
      case 'attendanceLiveClass':
        batch_ids = _.groupBy(attendanceLiveClass, 'batch_title')
        list_of_batch_ids = Object.keys(batch_ids)
        return {
          batch_ids,
          list_of_batch_ids,
        }
      case 'attendanceSubject':
        batch_ids = _.groupBy(attendanceBySubject, 'batch_title')
        list_of_batch_ids = Object.keys(batch_ids)
        return {
          batch_ids,
          list_of_batch_ids,
        }
      default:
        batch_ids = _.groupBy(dailyReport, 'batch_title')
        list_of_batch_ids = Object.keys(batch_ids)
        return {
          batch_ids,
          list_of_batch_ids,
        }
    }
  }

  const batchs = getBaths()

  const reportTypeDataFun = (reportType) => {
    switch (reportType) {
      case 'report':
        return {
          header: [
            'Sl No',
            'Student Name',
            'Batch',
            'Subject',
            'Topic',
            'Time Spent (Mins)',
          ],
          feilds: [
            'student_name',
            'batch_title',
            'subject_title',
            'topic_title',
            'time_spent',
          ],
          data:
            batch === 'all'
              ? dailyReport?.map((item) => {
                  return {
                    ...item,
                    time_spent: item?.time_spent.toFixed(2),
                  }
                })
              : batchs?.batch_ids[batch],
          isLoading: isdailyReportLoading,
        }
      case 'attendance':
        return {
          header: ['Sl No', 'Batch', 'Student Name', 'Attendance'],
          feilds: ['batch_title', 'student_name', 'attendance'],
          data: batch === 'all' ? attendanceByBatch : batchs?.batch_ids[batch],
          isLoading: isAttendanceByBatchLoading,
        }
      case 'attendanceWeekly':
        let dateToday = moment().format('YYYY-MM-DD')
        let groupById = _.groupBy(attendanceByWeek, 'student_id')
        let groupValue = Object.values(groupById)
        let weeklyAttendance = []
        weeklyAttendance = groupValue.map((val) => {
          return {
            batch_title: val[0].batch_title,
            student_name: val[0].student_name,
            attendMon:
              val[0].date <= dateToday
                ? val[0]?.attendance.charAt(0).toUpperCase() +
                  val[0]?.attendance.substr(1).toLowerCase()
                : '---',
            attendTues:
              val[1].date <= dateToday
                ? val[1]?.attendance.charAt(0).toUpperCase() +
                  val[1]?.attendance.substr(1).toLowerCase()
                : '---',
            attendWed:
              val[2].date <= dateToday
                ? val[2]?.attendance.charAt(0).toUpperCase() +
                  val[2]?.attendance.substr(1).toLowerCase()
                : '---',
            attendThurs:
              val[3].date <= dateToday
                ? val[3]?.attendance.charAt(0).toUpperCase() +
                  val[3]?.attendance.substr(1).toLowerCase()
                : '---',
            attendFri:
              val[4].date <= dateToday
                ? val[4]?.attendance.charAt(0).toUpperCase() +
                  val[4]?.attendance.substr(1).toLowerCase()
                : '---',
            attendSat:
              val[5].date <= dateToday
                ? val[5]?.attendance.charAt(0).toUpperCase() +
                  val[5]?.attendance.substr(1).toLowerCase()
                : '---',
            attendSun:
              val[6].date <= dateToday
                ? val[6]?.attendance.charAt(0).toUpperCase() +
                  val[6]?.attendance.substr(1).toLowerCase()
                : '---',
          }
        })
        return {
          header: [
            'Sl No',
            'Batch',
            'Student Name',
            'Monday (' +
              moment(date).startOf('week').add(1, 'day').format('DD-MM-YYYY') +
              ')',
            'Tuesday (' +
              moment(date).startOf('week').add(2, 'day').format('DD-MM-YYYY') +
              ')',
            'Wednesday (' +
              moment(date).startOf('week').add(3, 'day').format('DD-MM-YYYY') +
              ')',
            'Thursday (' +
              moment(date).startOf('week').add(4, 'day').format('DD-MM-YYYY') +
              ')',
            'Friday (' +
              moment(date).startOf('week').add(5, 'day').format('DD-MM-YYYY') +
              ')',
            'Saturday (' +
              moment(date).startOf('week').add(6, 'day').format('DD-MM-YYYY') +
              ')',
            'Sunday (' +
              moment(date).startOf('week').add(7, 'day').format('DD-MM-YYYY') +
              ')',
          ],
          feilds: [
            'batch_title',
            'student_name',
            'attendMon',
            'attendTues',
            'attendWed',
            'attendThurs',
            'attendFri',
            'attendSat',
            'attendSun',
          ],
          data:
            batch === 'all' ? weeklyAttendance : batchWeeklyAttendance(batch),
          isLoading: isAttendanceByWeekLoading,
        }
      case 'attendanceLiveClass':
        return {
          header: [
            'Sl No',
            'Student Name',
            'Batch',
            'Subject',
            'Online Session Name',
          ],
          feilds: [
            'student_name',
            'batch_title',
            'subject_title',
            'topic_title',
          ],
          data:
            batch === 'all'
              ? attendanceLiveClass
              : batchLiveClassAttendance(batch),
          isLoading: isAttendanceLiveClassLoading,
        }
      case 'attendanceSubject':
        return {
          header: ['Sl No', 'Batch', 'Subject', 'Student Name', 'Attendance'],
          feilds: [
            'batch_title',
            'subject_title',
            'student_name',
            'attendance',
          ],
          data:
            batch === 'all' ? attendanceBySubject : batchs?.batch_ids[batch],
          isLoading: isAttendanceBySubjectLoading,
        }
      default:
        return {
          header: ['Sl No', 'Batch', 'Subject', 'Topic', 'Time Spent'],
          feilds: ['batch_title', 'subject_title', 'topic_title', 'time_spent'],
          data: batch === 'all' ? dailyReport : batchs?.batch_ids[batch],
          isLoading: isdailyReportLoading,
        }
    }
  }

  const reportData = reportTypeDataFun(reportType)

  const downloadPdf = (reportType) => {
    if (reportType === 'attendance' || reportType === 'report') {
      const data = {
        reportData: reportData,
        date: date,
      }
      downloadReport({
        nameOfTemplate: 'cwxDailyAttendancebyBatch',
        dataForTemplate: data,
        downloadedFileName: `report`,
      })
    }
    if (reportType === 'attendanceWeekly') {
      const data = {
        reportData: reportData,
        startDate: moment(date).startOf('week').add(1, 'day'),
        endDate: moment(date).endOf('week'),
      }
      downloadReport({
        nameOfTemplate: 'cwxWeeklyAttendancebyBatch',
        dataForTemplate: data,
        downloadedFileName: `weeklyReport`,
      })
    }
  }

  return (
    <div className="tabs__container">
      <section>
        <nav className="padding--leftRight">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">Reports</li>
          </ol>
        </nav>
        <div className="clear"></div>
      </section>
      <section>
        <div className="select__container">
          <Col no="50">
            <DatePicker
              selected={date}
              onChange={(date) => onChangeDate(date)}
              placeholderText="Select Date"
              dateFormat="dd/MM/yyyy"
            />
          </Col>
        </div>
        <button
          className="button--blue"
          onClick={() => downloadPdf(reportType)}
        >
          Pdf
        </button>
        <Select className="float-right" onChange={(e) => onChangeReport(e)}>
          <option value="report">Attendance By Topic</option>
          <option value="attendance">Attendance By Batch</option>
          <option value="attendanceWeekly">Attendance By Week</option>
          <option value="attendanceLiveClass">Attendance For Live Class</option>
          {/* <option value="attendanceSubject">Attendance By Subject</option> */}
        </Select>
        <Select className="float-right" onChange={(e) => onChangeBatch(e)}>
          <option value="all">All</option>
          {batchs.list_of_batch_ids.map((batch_id) => {
            return (
              <option selected={batch === batch_id} value={batch_id}>
                {batch_id}
              </option>
            )
          })}
        </Select>

        <div className="clear"></div>
      </section>
      <section style={{ paddingTop: 20 }}>
        {reportData.isLoading ? (
          <Loader />
        ) : date ? (
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  {reportData.header.map((head, index) => {
                    return <th key={index}>{head}</th>
                  })}
                </tr>
              </thead>
              <tbody>
                {dailyReport &&
                  reportData?.data?.map((report, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        {reportData.feilds.map((feild, index) => {
                          return <td key={index}>{report[feild]}</td>
                        })}
                      </tr>
                    )
                  })}
              </tbody>
            </table>
            <div className="clear"></div>
          </div>
        ) : (
          <div> Please Select a date </div>
        )}
      </section>

      <div className="clear"></div>
    </div>
  )
}

export default Reports
