import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'

//actions
import {
  startGetAllInstitutions,
  startArchiveInstitution,
  startPatchInstitution,
  postInstitution,
} from '../../../redux/action/institution'
import { startFileUploads, clearFileUpload } from '../../../redux/action/upload'
import { startCreateUser } from '../../../redux/action/account'

//reducers
import {
  getJwt,
  getCreateLoginLoading,
  getIsError as getUserError,
} from '../../../redux/reducer/account'
import {
  getInstitutionLoading,
  getAllInstitution,
  getPatchInstitutionLoading,
  getIsError,
  getIsLoading as getPostLoading,
} from '../../../redux/reducer/institution'
import {
  getIsError as getFileUploadError,
  getFile,
  getIsLoadingUploadFile,
} from '../../../redux/reducer/upload'

//helpers
import { usePrevious } from '../../helpers'

//components
import Loader from '../../assets/molecules/loader'
import Popup from '../../assets/layout/popupConfirm'
import PopupForm from '../../assets/layout/popupForm'
import { AlertSuccess, AlertError } from '../../assets/molecules/alerts'

const Institutions = () => {
  const dispatch = useDispatch()
  const fileInputRef = useRef('')

  const jwt = useSelector(getJwt)
  const institutions = useSelector(getAllInstitution)
  const institutionLoading = useSelector(getInstitutionLoading)
  const institutionLoadingPrev = usePrevious(institutionLoading)
  const patchLoading = useSelector(getPatchInstitutionLoading)
  const patchLoadingPrev = usePrevious(patchLoading)
  const error = useSelector(getIsError)
  const uploadedFile = useSelector(getFile)
  const uploadLoading = useSelector(getIsLoadingUploadFile)
  const uploadLoadingPrev = usePrevious(uploadLoading)
  const fileUploadError = useSelector(getFileUploadError)
  const createLoginLoading = useSelector(getCreateLoginLoading)
  const createLoginLoadingPrev = usePrevious(createLoginLoading)
  const userError = useSelector(getUserError)
  const postLoading = useSelector(getPostLoading)
  const postLoadingPrev = usePrevious(postLoading)

  const [file, setFile] = useState()
  const [selectedInstitution, setSelectedInstitution] = useState({})
  const [showArchiveClassname, setShowArchiveClassname] = useState('hidePop')
  const [showEditClassname, setShowEditClassname] = useState('hidePop')
  const [showAddClassname, setShowAddClassname] = useState('hidePop')
  const [showUserClassname, setShowUserClassname] = useState('hidePop')
  const [formData, setFormData] = useState({})
  const [userInstitution, setUserInstitution] = useState({})
  const [userData, setUserData] = useState({})
  const [status, setStatus] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)
  const [editLogo, setEditLogo] = useState(false)

  useEffect(() => {
    dispatch(startGetAllInstitutions({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    if (
      !uploadLoading &&
      uploadLoadingPrev !== undefined &&
      uploadedFile &&
      uploadedFile?.length
    ) {
      let payload = {
        title: formData?.title,
        nav_logo: uploadedFile?.[0]?.publicUrl,
        academic_year: formData?.year,
      }
      dispatch(postInstitution({ jwt, payload }))
    }
  }, [
    dispatch,
    formData.title,
    formData.year,
    jwt,
    uploadLoading,
    uploadLoadingPrev,
    uploadedFile,
  ])

  useEffect(() => {
    if (
      !uploadLoading &&
      uploadLoadingPrev !== undefined &&
      uploadedFile &&
      uploadedFile?.length &&
      selectedInstitution &&
      selectedInstitution?._id
    ) {
      const payload = {
        title: selectedInstitution?.title,
        sealtabs_institution_id: selectedInstitution?.sealtabs_institution_id,
        academic_year: selectedInstitution?.academic_year,
        nav_logo: uploadedFile?.[0]?.publicUrl,
      }
      dispatch(
        startPatchInstitution({ jwt, id: selectedInstitution?._id, payload })
      )
    }
  }, [
    uploadLoading,
    uploadLoadingPrev,
    uploadedFile,
    selectedInstitution,
    dispatch,
    jwt,
  ])

  useEffect(() => {
    if (!postLoading && postLoadingPrev !== undefined) {
      setFormData({})
      dispatch(clearFileUpload())
      setShowAddClassname('hidePop')
      dispatch(startGetAllInstitutions({ jwt }))
      setShowSuccess(true)
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }, [dispatch, jwt, postLoading, postLoadingPrev])

  useEffect(() => {
    if (
      !patchLoading &&
      patchLoadingPrev !== undefined &&
      patchLoading !== patchLoadingPrev &&
      !error
    ) {
      dispatch(startGetAllInstitutions({ jwt }))
      setSelectedInstitution({})
      setShowArchiveClassname('hidePop')
      setShowEditClassname('hidePop')
      setShowSuccess(true)
      setEditLogo(false)
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }, [patchLoading, patchLoadingPrev, error, dispatch, jwt])

  useEffect(() => {
    if (
      !createLoginLoading &&
      createLoginLoadingPrev !== undefined &&
      !userError
    ) {
      setUserData({})
      setUserInstitution({})
      setShowUserClassname('hidePop')
      setShowSuccess(true)
    }
  }, [createLoginLoading, createLoginLoadingPrev, userError])

  const openArchivePopup = (e, item) => {
    setSelectedInstitution(item)
    setShowArchiveClassname('showPop')
    setStatus('')
    setShowSuccess(false)
  }

  const openEditPopup = (e, item) => {
    setSelectedInstitution(item)
    setShowEditClassname('showPop')
    setStatus('')
    setShowSuccess(false)
  }

  const openAddPopup = (e) => {
    setShowAddClassname('showPop')
    setStatus('')
    setShowSuccess(false)
  }

  const openUserPopup = (e) => {
    setShowUserClassname('showPop')
    setStatus('')
    setShowSuccess(false)
  }

  const closePopup = () => {
    setSelectedInstitution({})
    setShowArchiveClassname('hidePop')
    setShowEditClassname('hidePop')
    setShowAddClassname('hidePop')
    setShowUserClassname('hidePop')
    setStatus('')
    setShowSuccess(false)
  }

  const onChange = (e) => {
    setSelectedInstitution({
      ...selectedInstitution,
      [e.target.name]: e.target.value,
    })
  }

  const onFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const onInstitutionChange = (obj) => {
    setUserInstitution(obj)
  }

  const onUserChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  }

  const onFileUpload = (e) => {
    const fileData = e.target.files[0]
    const data = new FormData()
    data.append('file', fileData)
    setFile(data)
  }

  const onEditFileUpload = (e) => {
    const fileData = e.target.files[0]
    const data = new FormData()
    data.append('file', fileData)
    setFile(data)
    setEditLogo(true)
  }

  const handleEditSubmit = (e) => {
    if (editLogo) {
      dispatch(startFileUploads({ jwt, body: file }))
    } else {
      const payload = {
        title: selectedInstitution?.title,
        sealtabs_institution_id: selectedInstitution?.sealtabs_institution_id,
        academic_year: selectedInstitution?.academic_year,
      }
      dispatch(
        startPatchInstitution({ jwt, id: selectedInstitution?._id, payload })
      )
    }
    setStatus('edit')
  }

  const handleArchiveSubmit = () => {
    dispatch(startArchiveInstitution({ jwt, id: selectedInstitution?._id }))
    setStatus('archive')
  }

  const onSubmit = () => {
    dispatch(startFileUploads({ jwt, body: file }))
    setStatus('add')
  }

  const handleUser = () => {
    const data = {
      username: userData?.username,
      password: userData?.password,
      institution_id: userInstitution?.value,
      permission: 'moderator',
    }
    dispatch(startCreateUser({ jwt, payload: data }))
    setStatus('user')
  }

  return institutionLoading ? (
    <Loader />
  ) : (
    <div>
      <section>
        <div className="row">
          <div className="col-md-6 padding--0">
            <h1>Active Institutions</h1>
          </div>
          <div className="col-md-6 padding--0">
            <div className="button__wrapper">
              <button onClick={() => openAddPopup()} className="button--blue">
                + Add Institution
              </button>
              <button onClick={() => openUserPopup()} className="button--blue">
                + Add User
              </button>
            </div>
          </div>
          <div className="clear"></div>
        </div>
      </section>

      <div className="table-responsive">
        <table className={'table'}>
          <thead>
            <tr>
              <th>Sl No.</th>
              <th>Name</th>
              <th>Academic Year</th>
              <th>Edit</th>
              <th>Archive</th>
            </tr>
          </thead>
          <tbody>
            {institutions && institutions?.length > 0
              ? institutions
                  ?.filter((item) => item?.visibility)
                  ?.map((item, index) => {
                    return (
                      <tr key={item?._id}>
                        <td>{index + 1}</td>
                        <td className="pointer">{item?.title}</td>
                        <td>{item?.academic_year}</td>
                        <td>
                          <button onClick={(e) => openEditPopup(e, item)}>
                            Edit
                          </button>
                        </td>
                        <td>
                          <button onClick={(e) => openArchivePopup(e, item)}>
                            Archive
                          </button>
                        </td>
                      </tr>
                    )
                  })
              : null}
          </tbody>
        </table>
      </div>

      <PopupForm
        className={showEditClassname}
        close={() => {
          closePopup()
        }}
        onClickButton={handleEditSubmit}
        buttonText={'EDIT'}
        popupHead={'Edit Institution'}
      >
        <form>
          <ol>
            <li>
              <h2>Institution Name</h2>
              <div className="form-group sessionTitle">
                <input
                  type="text"
                  placeholder="Enter title"
                  name="title"
                  value={selectedInstitution?.title}
                  onChange={(e) => {
                    onChange(e)
                  }}
                />
              </div>
            </li>
            <li>
              <h2>Academic Year</h2>
              <div className="form-group sessionClass">
                <input
                  type="text"
                  name="academic_year"
                  value={selectedInstitution?.academic_year}
                  onChange={onChange}
                  placeholder="Academic Year"
                />
              </div>
            </li>
            <li>
              <h2>SEALTABS Institution ID</h2>
              <div className="form-group homeCard__subject">
                <input
                  type="text"
                  name="sealtabs_institution_id"
                  value={selectedInstitution?.sealtabs_institution_id}
                  onChange={onChange}
                  placeholder="SEALTABS Institution ID"
                />
              </div>
            </li>
            <li>
              <h2>Institution Logo</h2>
              <div className="form-group sessionTitle">
                <input
                  type="file"
                  placeholder="upload"
                  name="url"
                  onChange={onEditFileUpload}
                  ref={fileInputRef}
                />
                {/* {fileName && <p>Selected File: {fileName}</p>} */}
              </div>
            </li>
            {/* <li>
                <h2>Logo</h2>
                <div className="form-group homeCard__subject">
                  <input
                    type="file"
                    name={'nav_logo'}
                    // ref={notesRef.current[0]}
                    value={file}
                    onChange={onFileUpload}
                    placeholder="Logo"
                  />
                </div>
              </li> */}
          </ol>
        </form>
      </PopupForm>

      <Popup
        className={showArchiveClassname}
        buttonText="Archive"
        popupHead={'Archive Institution'}
        close={() => closePopup()}
        onClick={() => handleArchiveSubmit()}
      >
        <h2>Are you want to archive {selectedInstitution?.title}?</h2>
      </Popup>

      <PopupForm
        className={showAddClassname}
        close={() => {
          closePopup()
        }}
        onClickButton={(e) => onSubmit(e)}
        buttonText="Submit"
        popupHead="Add Institution"
        isLoading={postLoading || uploadLoading}
      >
        <form>
          <ol>
            <li>
              <h2>Enter the Institution Name *</h2>
              <div className="form-group sessionTitle">
                <input
                  name="title"
                  value={formData.title}
                  type="text"
                  placeholder="Title"
                  onChange={onFormChange}
                />
              </div>
            </li>

            <li>
              <h2>Academic year *</h2>
              <div className="form-group sessionDescription">
                <input
                  name="year"
                  value={formData.year}
                  type="text"
                  placeholder="Academic year | Ex: 2020-21"
                  onChange={onFormChange}
                />
              </div>
            </li>

            <li>
              <h2>Institution Logo *</h2>
              <div className="form-group sessionTitle">
                <input
                  type="file"
                  placeholder="upload"
                  name="url"
                  onChange={onFileUpload}
                  ref={fileInputRef}
                />
                {/* {fileName && <p>Selected File: {fileName}</p>} */}
              </div>
            </li>
          </ol>

          {/* {errorMessage && (
              <div className="alert alert-danger">{errorMessage}</div>
            )} */}
        </form>
      </PopupForm>

      <PopupForm
        className={showUserClassname}
        close={() => {
          closePopup()
        }}
        onClickButton={(e) => handleUser(e)}
        buttonText="Submit"
        popupHead="Add User - (Moderator)"
        isLoading={createLoginLoading}
      >
        <form>
          <ol>
            <li>
              <h2>Enter the Institution Name *</h2>
              <div className="form-group sessionTitle">
                <Select
                  name="faculty"
                  value={selectedInstitution.value}
                  onChange={onInstitutionChange}
                  options={institutions?.map((item) => {
                    return {
                      value: `${item?._id}`,
                      label: `${item?.title}`,
                    }
                  })}
                  // className="form-control"
                  placeholder="Enter Institution"
                />
              </div>
            </li>

            <li>
              <h2>User Name *</h2>
              <div className="form-group sessionDescription">
                <input
                  name="username"
                  value={userData?.username}
                  type="text"
                  placeholder="Ex: moderator_cwx"
                  onChange={onUserChange}
                />
              </div>
            </li>

            <li>
              <h2>Password *</h2>
              <div className="form-group sessionTitle">
                <input
                  type="text"
                  placeholder="Password"
                  value={userData?.password}
                  name="password"
                  onChange={onUserChange}
                />
              </div>
            </li>
          </ol>
        </form>
        {userError && (
          <div className="alert alert-danger">Username already exists.</div>
        )}
      </PopupForm>

      {showSuccess && status === 'add' ? (
        <AlertSuccess>Successfully created the Institution</AlertSuccess>
      ) : null}
      {showSuccess && status === 'edit' ? (
        <AlertSuccess>Successfully edited the Institution</AlertSuccess>
      ) : null}
      {showSuccess && status === 'archive' ? (
        <AlertSuccess>Successfully archived the Institution</AlertSuccess>
      ) : null}
      {showSuccess && status === 'user' ? (
        <AlertSuccess>Successfully created the User</AlertSuccess>
      ) : null}
    </div>
  )
}

export default Institutions
