import {
  APP_LOGIN_START,
  APP_LOGIN,
  APP_LOGIN_FAIL,
  APP_LOGOUT,
  APP_AUTH_FAILED,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  VERIFY_OTP_START,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  STUDENT_RESET_PASSWORD_START,
  STUDENT_RESET_PASSWORD_SUCCESS,
  STUDENT_RESET_PASSWORD_FAILURE,
  TEACHER_RESET_PASSWORD_START,
  TEACHER_RESET_PASSWORD_SUCCESS,
  TEACHER_RESET_PASSWORD_FAILURE,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CREATE_LOGIN_START,
  CREATE_LOGIN_SUCCESS,
  CREATE_LOGIN_FAIL,
} from '../actions.js'

const initialState = {
  jwt: '',
  user_id: '',
  username: '',
  institutionId: '',
  institutionName: '',
  institution_nav_logo: '',
  entity_id: '',
  entity_type: '',
  error: false,
  isLoggingIn: false,
  message: '',
  isLoading: false,
  forgotPassword: false,
  otpVerifed: false,
  passwordChanged: false,
  tempToken: '',
  studentResetIsLoading: false,
  studentResetSuccess: false,
  studentResetError: false,
  teacherResetIsLoading: false,
  teacherResetSuccess: false,
  teacherResetError: false,
  academicYear: '',
  createLoginLoading: false,
}

export const account = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case APP_LOGIN_START:
      return Object.assign({}, state, {
        isLoggingIn: true,
        isLoading: true,
        error: false,
        message: '',
      })
    case APP_LOGIN:
      return Object.assign({}, state, {
        isLoggingIn: false,
        isLoading: false,
        error: false,
        message: '',
        jwt: action.jwt,
        user_id: action.user_id,
        username: action.username,
        institutionId: action.institution_id,
        institutionName: action.institution_title,
        institution_nav_logo: action.institution_nav_logo,
        entity_id: action.entity_id,
        entity_type: action.entity_type,
        permission: action.permission,
        academicYear: action.academic_year,
      })
    case APP_LOGIN_FAIL:
      return Object.assign({}, state, {
        error: true,
        isLoggingIn: false,
        isLoading: false,
        message: 'Incorrect username/password. Please try again.',
      })
    case STUDENT_RESET_PASSWORD_START:
      return Object.assign({}, state, {
        studentResetIsLoading: true,
        studentResetError: false,
        studentResetSuccess: false,
      })
    case STUDENT_RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        studentResetIsLoading: false,
        studentResetError: false,
        studentResetSuccess: true,
      })
    case STUDENT_RESET_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        studentResetIsLoading: false,
        studentResetError: true,
        studentResetSuccess: false,
      })
    case TEACHER_RESET_PASSWORD_START:
      return Object.assign({}, state, {
        teacherResetIsLoading: true,
        teacherResetError: false,
        teacherResetSuccess: false,
      })
    case TEACHER_RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        teacherResetIsLoading: false,
        teacherResetError: false,
        teacherResetSuccess: true,
      })
    case TEACHER_RESET_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        teacherResetIsLoading: false,
        teacherResetError: true,
        teacherResetSuccess: false,
      })
    case APP_AUTH_FAILED:
      return Object.assign({}, initialState, {
        error: true,
      })
    case FORGOT_PASSWORD_START:
      return Object.assign({}, state, {
        isLoading: true,
        error: false,
        message: '',
      })
    case FORGOT_PASSWORD_SUCCESS:
      return Object.assign({}, initialState, {
        forgotPassword: true,
        otpVerifed: false,
        passwordChanged: false,
        isLoading: false,
        error: false,
        message: '',
      })
    case FORGOT_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        error: true,
        isLoading: false,
        message: 'invalid username',
      })
    case CHANGE_PASSWORD_START:
      return Object.assign({}, state, {
        isLoading: true,
        error: false,
        message: '',
      })
    case CHANGE_PASSWORD_SUCCESS:
      return Object.assign({}, initialState, {
        jwt: '',
        isLoading: false,
        error: false,
        message: '',
      })
    case CHANGE_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        error: true,
        isLoading: false,
        message: 'Please enter the correct Old Password',
      })
    case VERIFY_OTP_START:
      return Object.assign({}, initialState, {
        isLoading: true,
        error: false,
        message: '',
      })
    case VERIFY_OTP_SUCCESS:
      return Object.assign({}, state, {
        forgotPassword: false,
        otpVerifed: true,
        passwordChanged: false,
        isLoading: false,
        error: false,
        tempToken: action.temp_token,
      })
    case VERIFY_OTP_FAILURE:
      return Object.assign({}, initialState, {
        error: true,
        isLoading: false,
        message: 'invalid otp',
      })
    case RESET_PASSWORD_START:
      return Object.assign({}, state, {
        isLoading: true,
        error: false,
        message: '',
      })
    case RESET_PASSWORD_SUCCESS:
      return Object.assign({}, initialState, {
        forgotPassword: false,
        otpVerifed: false,
        passwordChanged: true,
        isLoading: false,
        error: false,
      })
    case RESET_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        error: true,
        isLoading: false,
      })
    case CREATE_LOGIN_START:
      return Object.assign({}, state, {
        createLoginLoading: true,
        error: false,
        message: '',
      })
    case CREATE_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        createLoginLoading: false,
        error: false,
      })
    case CREATE_LOGIN_FAIL:
      return Object.assign({}, state, {
        error: true,
        createLoginLoading: false,
      })
    default:
      return state
  }
}

export const getJwt = (state) => state.account.jwt
export const getLoggingIn = (state) => state.account.isLoggingIn
export const isLoggedIn = (state) => {
  const { isLoggingIn, jwt } = state?.account
  return !isLoggingIn && jwt?.length !== 0
}

export const isLoading = (state) => state.account.isLoading
export const getUsersInstitutionId = (state) => state.account.institutionId
export const getAcademicYear = (state) => state.account.academicYear
export const getUsersInstitutionTitle = (state) => state.account.institutionName
export const getUsersInstitutionNavLogo = (state) =>
  state.account.institution_nav_logo
export const getUsername = (state) => state.account.username
export const getUserId = (state) => state.account.user_id
export const getEntityId = (state) => state.account.entity_id
export const getEntityType = (state) => state.account.entity_type
export const getPermission = (state) => state.account.permission
export const getIsError = (state) => state.account.error
export const getMessage = (state) => state.account.message

export const getTempToken = (state) => state.account.tempToken
export const getIsForgotPassword = (state) => state.account.forgotPassword
export const getIsOTPVerifed = (state) => state.account.otpVerifed
export const getIsPasswordChanged = (state) => state.account.passwordChanged
export const getStudentResetIsLoading = (state) =>
  state.account.studentResetIsLoading
export const getStudentResetError = (state) => state.account.studentResetError
export const getStudentResetSuccess = (state) =>
  state.account.studentResetSuccess
export const getTeacherResetIsLoading = (state) =>
  state.account.teacherResetIsLoading
export const getTeacherResetError = (state) => state.account.teacherResetError
export const getTeacherResetSuccess = (state) =>
  state.account.teacherResetSuccess
export const getCreateLoginLoading = (state) => state.account.createLoginLoading
