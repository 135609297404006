import {
  AuthError,
  composeAuth,
  errorToast,
  parseJSON,
  successToast,
} from '../../helper/utils.js'
import {
  UPLOAD_FILE_START,
  UPLOAD_FILE,
  UPLOAD_FILE_FAIL,
  CLEAR_FILE_UPLOAD,
} from '../actions.js'

import { checkStatus } from '../helpers.js'

//clear file upload
export const clearFileUpload = () => ({
  type: CLEAR_FILE_UPLOAD,
})

//get profile info
const uploadFileStart = () => {
  return {
    type: UPLOAD_FILE_START,
  }
}

const uplaodFile = (data) => {
  return {
    type: UPLOAD_FILE,
    data,
  }
}

const uploadFileFailure = () => {
  return {
    type: UPLOAD_FILE_FAIL,
  }
}
export const fileUpload = (body) => {
  const url = `https://api2.sealtabs.com/api/s3/multiple/public`
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/*',
    },
    body: body,
  })
}

export const startFileUpload = (data) => {
  return async (dispatch) => {
    dispatch(uploadFileStart())
    try {
      const receivedData = await fileUpload(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(uplaodFile(response?.data))
    } catch (err) {
      dispatch(uploadFileFailure())
    }
  }
}

const emitFileUploadLInk = (data, original) => {
  console.log(data)
  const links = Array.isArray(data)
    ? data.map((el) => {
        return {
          publicUrl: el.publicUrl,
          filename: el.filename,
        }
      })
    : [{ publicUrl: data.publicUrl, filename: data.filename }]
  return {
    // type: FILE_UPLOAD_SUCCESS,
    data: links,
    original,
  }
}

export const fileUploads = ({ jwt, body }) => {
  console.log(body)
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER + '/api/s3/v2'

  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/*',
      Authorization,
    },
    body,
  })
}

export const startFileUploads = (data) => (dispatch) => {
  console.log(data)
  dispatch(uploadFileStart())

  return fileUploads(data)
    .then(checkStatus)
    .then(parseJSON)
    .then((json) => {
      console.log('data inside startFileUpload', json)

      const formattedData = emitFileUploadLInk(json.data, data)

      dispatch(uplaodFile(formattedData.data))

      return formattedData
    })
    .catch((err) => {
      console.error('error occurred')
      dispatch(uploadFileFailure())
    })
}
