import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import { usePrevious } from '../../helpers'
//assests
import Loader from '../../assets/molecules/loader'
import BatchesCard from '../../assets/molecules/batches'
import AddItems from '../../assets/molecules/addItems'
import Popup from '../../assets/layout/popupConfirm'
import PopupForm from '../../assets/layout/popupForm'
import PopupFaculty from '../../assets/layout/popupConfirm'
import Icon from '../../../assets/images/class.png'
import AddChapter from '../../../assets/images/addChapter.png'
import { AlertSuccess, AlertError } from '../../assets/molecules/alerts'

//action
import {
  fetchBatches,
  postBatch,
  startPatchBatch,
  startArchiveBatch,
} from '../../../redux/action/batches'
import { setBatch } from '../../../redux/action/ui'
import { postFaculty, getFaculties } from '../../../redux/action/faculty'
import {
  fetchSubjects,
  postFacultyToBatchSubject,
} from '../../../redux/action/subject'
import { startFileUpload } from '../../../redux/action/upload'

//reducers
import { getJwt } from '../../../redux/reducer/account'
import {
  getBatches,
  getIsLoading,
  getIsError,
  getEditBatchLoading,
} from '../../../redux/reducer/batches'
import {
  getIsFacultiesLoading,
  getIsPostFalcutiesLoading,
  getIsError as getIsErrorFaculty,
  getAllFaculties,
} from '../../../redux/reducer/faculty'
import {
  getSubjects,
  getIsFacultyAssignToSubjectLoading,
  getIsError as getIsFcltyError,
} from '../../../redux/reducer/subject'
import {
  getIsError as getFileUploadError,
  getFile,
  getIsLoadingUploadFile,
} from '../../../redux/reducer/upload'

const ModeratorDashboard = () => {
  const dispatch = useDispatch()

  let history = useHistory()
  let inputRef = useRef()

  let jwt = useSelector(getJwt)
  let batches = useSelector(getBatches)
  let isLoading = useSelector(getIsLoading)
  const error = useSelector(getIsError)
  const isLoadingPrev = usePrevious(isLoading)
  const editLoading = useSelector(getEditBatchLoading)
  const editLoadingPrev = usePrevious(editLoading)

  const errorAssign = useSelector(getIsFcltyError)
  const isAssignLoading = useSelector(getIsFacultyAssignToSubjectLoading)
  const isAssignLoadingPrev = usePrevious(isAssignLoading)
  const faculties = useSelector(getAllFaculties)
  const subjects = useSelector(getSubjects)

  const errorFaculty = useSelector(getIsErrorFaculty)
  const isLoadingFaculty = useSelector(getIsFacultiesLoading)
  const isPostFalcutiesLoading = useSelector(getIsPostFalcutiesLoading)
  const isPostFalcutiesLoadingPrev = usePrevious(isPostFalcutiesLoading)
  const isLoadingUploadFile = useSelector(getIsLoadingUploadFile)
  const isLoadingUploadFilePrev = usePrevious(isLoadingUploadFile)
  const uploadedFile = useSelector(getFile)
  const fileUploadError = useSelector(getFileUploadError)

  let [uploadAvatar, setUploadAvatar] = useState('')
  let [facultyList, setFacultyList] = useState([])
  let [formData, setFormData] = useState({})
  const [selectedFaculty, setSelectedFaculty] = useState({
    value: '',
    label: '',
  })
  let [errorMessage, setErrorMessage] = useState('')
  let [selectedBatch, setSelectedBatch] = useState('')
  const [formPopClassName, setFormPopClassName] = useState('hidePop')
  const [showAddFacultySuccess, setShowAddFacultySuccess] = useState(false)
  const [facultyAddPopClassName, setFacultyAddPopClassName] =
    useState('hidePop')
  const [facultyAssignPopClassName, setFacultyAssignPopClassName] =
    useState('hidePop')
  const [showEditBatchPopup, setShowEditBatchPopup] = useState('hidePop')
  const [showDeleteBatchPopup, setShowDeleteBatchPopup] = useState('hidePop')
  const [selectedBatchData, setSelectedBatchData] = useState({})
  const [success, setSuccess] = useState('')
  const [showBatchSuccess, setShowBatchSuccess] = useState(false)
  const [newBatchName, setNewBatchName] = useState('')

  const showPopup = () => {
    setFormPopClassName('showPop')
  }
  const closePopup = () => {
    setFormPopClassName('hidePop')
    setShowEditBatchPopup('hidePop')
    setShowDeleteBatchPopup('hidePop')
    setSelectedBatchData({})
    setSelectedBatch('')
    setSuccess('')
    setNewBatchName('')
  }
  const showAddFacultyPopup = () => {
    setFormData({
      name: '',
      email: '',
      mobile: '',
      prn: '',
      designation: '',
      // avatar: '',
      gender: '',
    })
    setErrorMessage('')
    setFacultyAddPopClassName('showPop')
  }
  const closeAddFacultyPopup = () => {
    setFacultyAddPopClassName('hidePop')
    setErrorMessage('')
    setFormData({})
    setUploadAvatar('')
  }
  const showAssignFacultyPopup = () => {
    setFormData({ faculty: '', subject: '' })
    setSelectedBatch('')
    setFacultyAssignPopClassName('showPop')
    let list = faculties.map((faculty) => {
      return {
        value: `${faculty._id}`,
        label: `${faculty.name} - ${faculty.prn}`,
      }
    })
    setFacultyList(list)
  }
  const closeAssignFacultyPopup = () => {
    setFacultyAssignPopClassName('hidePop')
    setFormData({})
    setSelectedFaculty({ value: '', label: '' })
  }

  useEffect(() => {
    dispatch(fetchBatches({ jwt }))
    dispatch(getFaculties({ jwt }))
    dispatch(fetchSubjects({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    error && setErrorMessage('Something went wrong. Please try again.')
    errorAssign && setErrorMessage('Something went wrong. Please try again.')
    errorFaculty && setErrorMessage('Something went wrong. Please try again.')
  }, [error, errorAssign, errorFaculty])

  useEffect(() => {
    if (
      isLoadingPrev !== undefined &&
      isLoadingPrev !== isLoading &&
      !isLoading &&
      !error
    ) {
      history.push({
        pathname: '/dashboard',
        state: { status: 'success' },
      })
    }
  }, [error, history, isLoading, isLoadingPrev])

  useEffect(() => {
    if (!editLoading && editLoadingPrev !== undefined && !error) {
      dispatch(fetchBatches({ jwt }))
      setSelectedBatchData({})
      setShowDeleteBatchPopup('hidePop')
      setShowEditBatchPopup('hidePop')
      setShowBatchSuccess(true)
      setNewBatchName('')
    }
  }, [dispatch, editLoading, editLoadingPrev, error, jwt])

  useEffect(() => {
    if (
      isPostFalcutiesLoadingPrev !== undefined &&
      !isPostFalcutiesLoading &&
      !errorFaculty
    ) {
      dispatch(getFaculties({ jwt }))
      setErrorMessage('')
      setFormData({})
      setUploadAvatar('')
      setShowAddFacultySuccess(true)
    }
    if (
      isPostFalcutiesLoadingPrev !== undefined &&
      isPostFalcutiesLoadingPrev !== isPostFalcutiesLoading &&
      !isPostFalcutiesLoading &&
      !errorFaculty
    ) {
      dispatch(getFaculties({ jwt }))
      setErrorMessage('')
      setFormData({})
      setUploadAvatar('')
      setShowAddFacultySuccess(true)
    }
  }, [isPostFalcutiesLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (uploadAvatar === '') {
      inputRef.current.value = ''
    }
  }, [uploadAvatar])

  useEffect(() => {
    if (
      isLoadingUploadFilePrev !== undefined &&
      isLoadingUploadFilePrev !== isLoadingUploadFile &&
      !isLoadingUploadFile &&
      !fileUploadError
    ) {
      setUploadAvatar(uploadedFile.publicUrl)
    }
  }, [
    fileUploadError,
    isLoadingUploadFile,
    isLoadingUploadFilePrev,
    uploadedFile.publicUrl,
  ])

  const onBatchSelect = (batch) => {
    dispatch(setBatch(batch._id, batch.title))
    history.push('/batch/subjects')
  }

  const onChange = (e) => {
    e.preventDefault()
    setFormData({ ...formData, [e.target.name]: e.target.value })
    errorMessage && setErrorMessage('')
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (
      formData?.title &&
      formData?.year &&
      formData?.year.split('-')[0].length === 4 &&
      formData?.year.split('-')[1].length === 2
    ) {
      const payload = {
        title: formData.title,
        academic_year: formData.year,
        display_order: 0,
      }
      dispatch(postBatch({ payload, jwt }))
      setFormPopClassName('hidePop')
      setErrorMessage('')
    } else {
      setErrorMessage('Please fill the form')
    }
  }

  const onChangeField = (e) => {
    e.preventDefault()
    setFormData({ ...formData, [e.target.name]: e.target.value })
    errorMessage && setErrorMessage('')
  }

  const onChangeAvatarFile = (e) => {
    setErrorMessage('')
    const fileData = e.target.files[0]
    const data = new FormData()
    data.append('file', fileData)
    dispatch(startFileUpload(data))
  }

  const onSubmitAddFaculty = (e) => {
    e.preventDefault()
    if (
      formData?.name &&
      formData?.gender &&
      formData?.email &&
      formData?.mobile &&
      formData?.prn &&
      formData?.designation
    ) {
      const payload = {
        name: formData.name,
        gender: formData?.gender,
        email: [formData.email],
        mobile: [formData.mobile],
        prn: formData.prn,
        designation: formData.designation,
        // avatar: formData.avatar ? formData.avatar : 'string',
        avatar: uploadAvatar ? uploadAvatar : 'string',
      }
      dispatch(postFaculty({ payload, jwt }))
      setFacultyAddPopClassName('hidePop')
      setErrorMessage('')
    } else {
      setErrorMessage('Please fill the form')
    }
  }

  const handleChange = (selectedFaculty) => {
    setSelectedFaculty({ selectedFaculty })
    setFormData({ ...formData, faculty: selectedFaculty.value })
  }
  const onChangeAssign = (e) => {
    e.preventDefault()
    setFormData({ ...formData, [e.target.name]: e.target.value })
    errorMessage && setErrorMessage('')
  }

  const onSubmitAssign = (e) => {
    e.preventDefault()
    if (formData?.faculty && formData?.subject) {
      const payload = {
        batch_subject_id: formData.subject,
        faculty_ids: [formData.faculty],
      }
      dispatch(postFacultyToBatchSubject({ payload, jwt }))
      setFacultyAssignPopClassName('hidePop')
    } else {
      setErrorMessage('Please fill the form')
    }
    setFormData({})
    setSelectedFaculty({ value: '', label: '' })
  }

  const onChangeBatch = (e) => {
    setSelectedBatch(e.target.value)
    const filteredSubs = subjects?.filter(
      (sub) => sub?.batch_id === e.target.value
    )
    setFormData({
      ...formData,
      subject: filteredSubs[0] ? filteredSubs[0]?._id : '',
    })
    setErrorMessage('')
  }

  const onBatchNameChange = (e) => {
    setNewBatchName(e.target.value)
  }

  const openEditBatch = (batch) => {
    setShowEditBatchPopup('showPop')
    setSelectedBatchData(batch)
    setShowBatchSuccess(false)
    setNewBatchName(batch?.title)
  }

  const openDeleteBatch = (batch) => {
    setShowDeleteBatchPopup('showPop')
    setSelectedBatchData(batch)
    setShowBatchSuccess(false)
  }

  const handleEditBatch = () => {
    setSuccess('edit')
    dispatch(
      startPatchBatch({
        jwt,
        id: selectedBatchData?._id,
        payload: { title: newBatchName },
      })
    )
  }

  const handleDeleteBatch = () => {
    setSuccess('archive')
    dispatch(startArchiveBatch({ jwt, id: selectedBatchData?._id }))
  }

  return (
    <div className="tabs__container">
      {showAddFacultySuccess && (
        <AlertSuccess>You have successfully added a Faculty</AlertSuccess>
      )}
      {isAssignLoadingPrev !== undefined &&
        isAssignLoadingPrev !== isAssignLoading &&
        !isAssignLoading &&
        !errorAssign && (
          <AlertSuccess>You have successfully assigned a Faculty</AlertSuccess>
        )}
      {showBatchSuccess && success === 'edit' && (
        <AlertSuccess>Successfully edited the Batch</AlertSuccess>
      )}
      {showBatchSuccess && success === 'archive' && (
        <AlertSuccess>Successfully archived the Batch</AlertSuccess>
      )}
      <section>
        <div className="row">
          <div className="col-md-6 padding--0">
            <h1>Batches</h1>
          </div>
          <div className="col-md-6 padding--0">
            <div className="button__wrapper">
              {/* <Link
              to={{
                pathname: `/batch/create`,
              }}
            > */}
              <button onClick={() => showPopup()} className="button--blue">
                + Add Batch
              </button>
              {/* </Link> */}
              {/* <Link
              to={{
                pathname: `/create-faculty`,
              }}
            > */}
              <button
                onClick={() => showAddFacultyPopup()}
                className="button--blue"
              >
                + Add Faculty
              </button>
              {/* </Link> */}

              <button
                onClick={() => showAssignFacultyPopup()}
                className="button--blue"
              >
                Assign Faculty
              </button>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        {isLoadingFaculty || isLoading ? (
          <Loader />
        ) : (
          batches?.map((batch, index) => {
            return (
              <BatchesCard
                key={index}
                icon={Icon}
                batchName={batch.title}
                noOfStudents={batch?.students?.length}
                onClick={() => onBatchSelect(batch)}
                editOnClick={() => openEditBatch(batch)}
                deleteOnClick={() => openDeleteBatch(batch)}
              />
            )
          })
        )}

        <div className="clear"></div>
      </section>
      {/* <AddItems
        imageURL={AddChapter}
        text="Add Batch"
        onClick={() => showPopup()}
      /> */}

      <PopupForm
        className={formPopClassName}
        close={() => {
          closePopup()
        }}
        onClickButton={(e) => onSubmit(e)}
        buttonText="Submit"
        popupHead="Add Batch"
      >
        <form>
          <ol>
            <li>
              <h2>Enter the batch title</h2>
              <div className="form-group sessionTitle">
                <input
                  name="title"
                  value={formData.title}
                  type="text"
                  placeholder="Title"
                  onChange={onChange}
                />
              </div>
            </li>

            <li>
              <h2>Academic year for the batch</h2>
              <div className="form-group sessionDescription">
                <input
                  name="year"
                  value={formData.year}
                  type="text"
                  placeholder="Academic year | Ex: 2020-21"
                  onChange={onChange}
                />
              </div>
            </li>
          </ol>

          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
        </form>
      </PopupForm>

      <PopupFaculty
        className={facultyAddPopClassName}
        close={() => {
          closeAddFacultyPopup()
        }}
        onClick={(e) => onSubmitAddFaculty(e)}
        buttonText="Submit"
        popupHead="Add Faculty"
      >
        <div className="form-row">
          <div className="form-group col">
            <input
              name="name"
              value={formData.name}
              type="text"
              placeholder="Name"
              onChange={onChangeField}
            />
          </div>
          <div className="form-group col">
            <select
              name="gender"
              onChange={onChangeField}
              value={formData.gender}
            >
              <option>Please select gender</option>
              <option value="male">male</option>
              <option value="female">female</option>
            </select>
          </div>
          <div className="clear"></div>
        </div>
        <div className="form-row">
          <div className="form-group col">
            <input
              name="email"
              value={formData.email}
              type="email"
              placeholder="Email"
              onChange={onChangeField}
            />
          </div>
          <div className="form-group col">
            <input
              name="mobile"
              value={formData.mobile}
              placeholder="Mobile Number"
              onChange={onChangeField}
            />
          </div>
          <div className="clear"></div>
        </div>
        <div className="form-row">
          <div className="form-group col">
            <input
              name="prn"
              value={formData.prn}
              type="text"
              placeholder="PRN"
              onChange={onChangeField}
            />
          </div>
          <div className="form-group col">
            <input
              name="designation"
              value={formData.designation}
              type="text"
              placeholder="Designation"
              onChange={onChangeField}
            />
          </div>
          <div className="clear"></div>
        </div>
        <div className="form-row">
          <div className="form-group col">
            <label for="avatar">Avatar</label>
            <input
              name="avatar"
              value={formData.avatar}
              type="file"
              placeholder="Avatar"
              onChange={onChangeAvatarFile}
              ref={inputRef}
            />
          </div>
          <div className="clear"></div>
        </div>

        {errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}
      </PopupFaculty>
      <PopupForm
        className={facultyAssignPopClassName}
        close={() => {
          closeAssignFacultyPopup()
        }}
        onClickButton={(e) => onSubmitAssign(e)}
        buttonText="Submit"
        popupHead="Assign Faculty"
      >
        <form>
          <ol>
            <li>
              <h2>Please select faculty</h2>
              <div className="form-group sessionTitle">
                <Select
                  name="faculty"
                  value={selectedFaculty.value}
                  onChange={handleChange}
                  options={facultyList}
                  // className="form-control"
                  placeholder="Enter faculty"
                />
              </div>
            </li>
            <li>
              <h2>Please select Batch</h2>
              <div className="form-group sessionTitle">
                <select
                  name="batch"
                  onChange={onChangeBatch}
                  value={selectedBatch}
                  className="form-control"
                >
                  <option>Please select batch</option>
                  {batches &&
                    batches?.map((batch, index) => {
                      return (
                        <option
                          key={index}
                          value={batch._id}
                        >{`${batch.title}`}</option>
                      )
                    })}
                </select>
              </div>
            </li>
            <li>
              <h2>Please select subject</h2>
              <div className="form-group sessionDescription">
                <select
                  name="subject"
                  onChange={onChangeAssign}
                  value={formData.subject}
                  className="form-control"
                >
                  <option>Please select subject</option>
                  {subjects
                    ?.filter((sub) => sub?.batch_id === selectedBatch)
                    ?.map((subject, index) => {
                      return (
                        <option
                          key={index}
                          value={subject._id}
                        >{`${subject.subject_title}`}</option>
                      )
                    })}
                </select>
              </div>
            </li>
          </ol>

          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
        </form>
      </PopupForm>

      <PopupForm
        className={showEditBatchPopup}
        close={() => {
          closePopup()
        }}
        onClickButton={(e) => handleEditBatch(e)}
        buttonText="Edit"
        popupHead="Edit Batch"
      >
        <form>
          <ol>
            <li>
              <h2>Change the batch title</h2>
              <div className="form-group sessionTitle">
                <input
                  name="title"
                  value={newBatchName}
                  type="text"
                  placeholder="Title"
                  onChange={onBatchNameChange}
                />
              </div>
            </li>
          </ol>
        </form>
      </PopupForm>

      <Popup
        className={showDeleteBatchPopup}
        close={() => closePopup()}
        onClick={(e) => handleDeleteBatch(e)}
        popupHead="Delete Batch"
        buttonText="Proceed"
      >
        <h2>
          Are you sure want to delete the batch - {selectedBatchData?.title}?
        </h2>
      </Popup>
      <div className="clear"></div>
    </div>
  )
}

export default ModeratorDashboard
