import {
  GET_BATCHES_START,
  GET_BATCHES_SUCCESS,
  GET_BATCHES_FAILURE,
  POST_BATCH_START,
  POST_BATCH_SUCCESS,
  POST_BATCH_FAILURE,
  SET_STUDENT_BATCH_START,
  SET_STUDENT_BATCH_SUCCESS,
  SET_STUDENT_BATCH_FAILURE,
  ASSIGN_STUDENT_BATCH_START,
  ASSIGN_STUDENT_BATCH_SUCCESS,
  ASSIGN_STUDENT_BATCH_FAILURE,
  GET_TEACHER_BATCH_SUBJECTS_START,
  GET_TEACHER_BATCH_SUBJECTS_SUCCESS,
  GET_TEACHER_BATCH_SUBJECTS_FAILURE,
  PATCH_BATCH_START,
  PATCH_BATCH_SUCCESS,
  PATCH_BATCH_FAIL,
  DELETE_BATCH_START,
  DELETE_BATCH_SUCCESS,
  DELETE_BATCH_FAIL,
} from '../actions'

let initialState = {
  batches: [],
  batchesById: {},
  isLoading: false,
  isLoadingSetStudentBatch: false,
  batchesReceived: false,
  isError: false,
  isLoadingGetTeacherBatchSubjects: false,
  isLoadingAssignStudentBatch: false,
  teacherBatchSubjects: [],
  editBatchLoading: false,
}
export const batches = (state = initialState, action) => {
  switch (action.type) {
    case GET_BATCHES_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case GET_BATCHES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        batches: action.batches,
        batchesById: action.batchesById,
      }
    case GET_BATCHES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case POST_BATCH_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case POST_BATCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      }
    case POST_BATCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case SET_STUDENT_BATCH_START:
      return {
        ...state,
        isLoadingSetStudentBatch: true,
        isError: false,
      }
    case SET_STUDENT_BATCH_SUCCESS:
      return {
        ...state,
        isLoadingSetStudentBatch: false,
        isError: false,
      }
    case SET_STUDENT_BATCH_FAILURE:
      return {
        ...state,
        isLoadingSetStudentBatch: false,
        isError: true,
      }
    case ASSIGN_STUDENT_BATCH_START:
      return {
        ...state,
        isLoadingAssignStudentBatch: true,
        isError: false,
      }
    case ASSIGN_STUDENT_BATCH_SUCCESS:
      return {
        ...state,
        isLoadingAssignStudentBatch: false,
        isError: false,
      }
    case ASSIGN_STUDENT_BATCH_FAILURE:
      return {
        ...state,
        isLoadingAssignStudentBatch: false,
        isError: true,
      }
    case GET_TEACHER_BATCH_SUBJECTS_START:
      return {
        ...state,
        isLoadingGetTeacherBatchSubjects: true,
        isError: false,
      }
    case GET_TEACHER_BATCH_SUBJECTS_SUCCESS:
      return {
        ...state,
        isLoadingGetTeacherBatchSubjects: false,
        teacherBatchSubjects: action.data,
        isError: false,
      }
    case GET_TEACHER_BATCH_SUBJECTS_FAILURE:
      return {
        ...state,
        isLoadingGetTeacherBatchSubjects: false,
        isError: true,
      }
    case PATCH_BATCH_START:
      return {
        ...state,
        editBatchLoading: true,
        isError: false,
      }
    case PATCH_BATCH_SUCCESS:
      return {
        ...state,
        editBatchLoading: false,
        isError: false,
      }
    case PATCH_BATCH_FAIL:
      return {
        ...state,
        editBatchLoading: false,
        isError: true,
      }
    case DELETE_BATCH_START:
      return {
        ...state,
        editBatchLoading: true,
        isError: false,
      }
    case DELETE_BATCH_SUCCESS:
      return {
        ...state,
        editBatchLoading: false,
        isError: false,
      }
    case DELETE_BATCH_FAIL:
      return {
        ...state,
        editBatchLoading: false,
        isError: true,
      }
    default:
      return state
  }
}

export const getBatches = (state) => state.batches.batches
export const getBatchesById = (state) => state.batches.batchesById
export const getIsLoading = (state) => state.batches.isLoading
export const getIsLoadingAssignStudentBatch = (state) =>
  state.batches.isLoadingAssignStudentBatch
export const getIsLoadingSetStudentBatch = (state) =>
  state.batches.isLoadingSetStudentBatch
export const getIsError = (state) => state.batches.isError
export const getIsLoadingGetTeacherBatchSubjects = (state) =>
  state.batches.isLoadingGetTeacherBatchSubjects
export const getTeacherBatchSubjects = (state) =>
  state.batches.teacherBatchSubjects
export const getEditBatchLoading = (state) => state.batches.editBatchLoading
