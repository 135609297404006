import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import useWindowFocus from 'use-window-focus'

// import _ from "underscore";
import { Tab, Tabs } from 'react-bootstrap'
import moment from 'moment'
import ReactPlayer from 'react-player'
//import ProfilePic from '../../assets/images/old/profile-pic.png'

//assets
import Loader from '../assets/molecules/loader'
import LeftRightLayout from '../assets/layout/leftrightlayout'
import Col75 from '../assets/layout/leftrightlayout/column75'
import Col25 from '../assets/layout/leftrightlayout/column25'
import DoubtsCard from '../assets/molecules/doubtsCard'
import NotesCard from '../assets/molecules/notesCard'
import AddNotes from '../assets/molecules/addNotes'
// import SharedFiles from '../assets/molecules/sharedFiles'
import ProfilePic from '../../assets/images/profile.png'
// import Documents from '../../assets/images/document.svg'
// import Notes from '../../assets/images/notes.svg'
// import PDF from '../../assets/images/pdf.svg'
// import Excel from '../../assets/images/excel.svg'
import Popup from '../../components/assets/layout/popupConfirm'
import PopupDoubt from '../../components/assets/layout/popupRight'
import InputSend from '../assets/molecules/inputSend'
import ReplyCard from '../assets/molecules/replyCard'

//import ProfileCard from '../assets/molecules/profileCard'

//action
import { fetchTopic } from '../../redux/action/topic'
import {
  fetchDoubtsByTopicId,
  fetchAskDoubt,
  postReplyDoubt,
  deleteStudentDoubtsById,
  editStudentDoubtsById,
  fetchDoubtsResponsesByIds,
  startResponseFromAI,
} from '../../redux/action/doubt'
import {
  fetchNotesByTopicIdForCurrentStudent,
  postAddnotes,
  fetchEditnotes,
  deleteNote,
} from '../../redux/action/note'

import { postImpressions } from '../../redux/action/impressions'
import { fetchChaptersBySubjectId } from '../../redux/action/chapter'

//reducer
import { getJwt, getUsername } from '../../redux/reducer/account'
import {
  getTopicTitle,
  getTopicId,
  getSubjectTitle,
  getSubjectId,
  getChapterTitle,
  getChapterId,
  getBatchSubjectId,
} from '../../redux/reducer/ui'
import { getTopic, getIsTopicsLoading } from '../../redux/reducer/topic'
import {
  getNotes,
  getIsNotesLoading,
  getIsError,
  getIsEditNotesLoading,
} from '../../redux/reducer/note'
import {
  getDoubtState,
  getResponsesGroupByDoubtId,
  getIsDoubtReplying,
  getIsError as getIsDoubtReplyError,
  getIsDoubtsLoading,
  getResponseFromAi,
  getResponseFromAiLoading,
} from '../../redux/reducer/doubt'
import { getBatchSubjects, getSubjects } from '../../redux/reducer/subject'
import { getChapters } from '../../redux/reducer/chapter'

//helpers
import { usePrevious } from '../helpers'

const TopicDetail = () => {
  const dispatch = useDispatch()

  const jwt = useSelector(getJwt)
  const topicId = useSelector(getTopicId)
  const topic = useSelector(getTopic)
  const { doubts } = useSelector(getDoubtState)
  const responsesGroupByDoubtId = useSelector(getResponsesGroupByDoubtId)
  const isTopicsLoading = useSelector(getIsTopicsLoading)
  const subjectTitle = useSelector(getSubjectTitle)
  const subjectId = useSelector(getSubjectId)
  const chapterTitle = useSelector(getChapterTitle)
  const chapterId = useSelector(getChapterId)
  const topicTitle = useSelector(getTopicTitle)
  const batch_subject_id = useSelector(getBatchSubjectId)
  const isNotesLoading = useSelector(getIsNotesLoading)
  const isError = useSelector(getIsError)
  const batchSubjects = useSelector(getBatchSubjects)
  const subjects = useSelector(getSubjects)
  const notes = useSelector(getNotes)
  const isEditNotesLoading = useSelector(getIsEditNotesLoading)
  const isEditNotesLoadingPrev = usePrevious(isEditNotesLoading)
  const isDoubtReplying = useSelector(getIsDoubtReplying)
  const isDoubtReplyingPrev = usePrevious(isDoubtReplying)
  const isErrorDoubtReply = usePrevious(getIsDoubtReplyError)
  const chapters = useSelector(getChapters)
  const username = useSelector(getUsername)
  const isDoubtsLoading = useSelector(getIsDoubtsLoading)
  const isDoubtsLoadingPrev = usePrevious(isDoubtsLoading)

  const responseFromAiLoading = useSelector(getResponseFromAiLoading)
  const responseFromAiLoadingPrev = usePrevious(responseFromAiLoading)
  const responseFromAi = useSelector(getResponseFromAi)

  const [noteText, setNoteText] = useState()
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [editText, setEditText] = useState()
  const [selectedNote, setSelectedNote] = useState('')
  const [selectedDoubt, setSelectedDoubt] = useState('')
  const [doubtClicked, setDoubtClicked] = useState()
  const [popClassName, setPopClassName] = useState('hidePop')
  const [deletePopClassName, setDeletePopClassName] = useState('hidePop')
  const [editDoubtPopClassName, setEditDoubtPopClassName] = useState('hidePop')
  const [deleteDoubtPopClassName, setDeleteDoubtPopClassName] =
    useState('hidePop')

  useEffect(() => {
    dispatch(fetchTopic({ jwt, topicId }))
    dispatch(fetchDoubtsByTopicId({ jwt, topicId }))
    dispatch(fetchNotesByTopicIdForCurrentStudent({ jwt, topicId }))
  }, [dispatch, jwt, topicId])

  useEffect(() => {
    if (chapterTitle === '' || chapterTitle === undefined) {
      dispatch(fetchChaptersBySubjectId({ jwt, subjectId }))
    }
  }, [chapterTitle, dispatch, jwt, subjectId])

  useEffect(() => {
    if (!isDoubtsLoading && isDoubtsLoadingPrev !== undefined) {
      const doubtIds = doubts?.map((doubt) => doubt._id)
      if (doubtIds && doubtIds.length > 0) {
        dispatch(fetchDoubtsResponsesByIds({ jwt, doubtIds }))
      }
    }
  }, [isDoubtsLoading, isDoubtReplying, isErrorDoubtReply]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !isDoubtReplying &&
      isDoubtReplyingPrev !== undefined &&
      !isErrorDoubtReply
    ) {
      const doubtIds = doubts?.map((doubt) => doubt._id)
      if (doubtIds && doubtIds.length > 0) {
        dispatch(fetchDoubtsResponsesByIds({ jwt, doubtIds }))
      }
    }
  }, [isDoubtReplying, isErrorDoubtReply]) // eslint-disable-line react-hooks/exhaustive-deps

  const windowFocused = useWindowFocus()

  // useEffect(() => {
  //   const body = {
  //     content_id: topicId,
  //     content_type: 'topics',
  //     time_spent: '2',
  //     batch_subject_id: batch_subject_id,
  //   }
  //   let timer = null
  //   if (windowFocused || videoPlaying) {
  //     timer = setInterval(() => {
  //       dispatch(postImpressions({ jwt, body }))
  //     }, 120000)
  //   }

  //   return () => {
  //     clearInterval(timer)
  //   }
  // }, [dispatch, jwt, topicId, batch_subject_id, windowFocused, videoPlaying])

  useEffect(() => {
    const body = {
      content_id: topicId,
      content_type: 'topics',
      time_spent: '.1',
      batch_subject_id: batch_subject_id,
    }
    dispatch(postImpressions({ jwt, body }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    !isNotesLoading && !isError && setNoteText('')
  }, [isNotesLoading, isError])

  useEffect(() => {
    if (!isEditNotesLoading && isEditNotesLoadingPrev !== undefined) {
      dispatch(fetchNotesByTopicIdForCurrentStudent({ jwt, topicId }))
    }
  }, [isEditNotesLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  const createMarkup = (data) => {
    return { __html: data }
  }

  const getChapterNameById = (id) => {
    const filtered = chapters?.filter((chapter) => chapter._id === id)
    return filtered.length ? filtered[0].title : ''
  }

  const openPopup = (note) => {
    setSelectedNote(note)
    setNoteText(note?.note_text)
    setPopClassName('showPop')
  }

  const closePopup = () => {
    setNoteText('')
    setPopClassName('hidePop')
  }

  const openDeletePopup = (note) => {
    setSelectedNote(note)
    setDeletePopClassName('showPop')
  }

  const closeDeletePopup = () => {
    setDeletePopClassName('hidePop')
  }

  const onEditNote = (e) => {
    dispatch(
      fetchEditnotes({ jwt, noteId: selectedNote._id, note_text: noteText })
    )
    closePopup()
  }

  const onDelete = () => {
    dispatch(deleteNote({ jwt, id: selectedNote._id }))
    closeDeletePopup()
  }

  const openEditDoubtPopup = (doubt) => {
    setEditDoubtPopClassName('showPop')
    setSelectedDoubt(doubt)
    setEditText(doubt.doubt_text)
  }

  const openDeleteDoubtPopup = (doubt) => {
    setDeleteDoubtPopClassName('showPop')
    setSelectedDoubt(doubt)
  }

  const closeEditDoubtPopup = () => {
    setEditText('')
    setEditDoubtPopClassName('hidePop')
  }

  const closeDeleteDoubtPopup = () => {
    setDeleteDoubtPopClassName('hidePop')
  }

  const onDoubtEdit = () => {
    dispatch(
      editStudentDoubtsById({
        jwt,
        doubtId: selectedDoubt._id,
        payload: {
          doubt_text: editText,
        },
      })
    )
    setEditText('')
    closeEditDoubtPopup()
  }

  const onDoubtDelete = () => {
    dispatch(
      deleteStudentDoubtsById({
        doubtId: selectedDoubt._id,
        jwt,
        isFromTopicDetail: true,
        topicId,
      })
    )
    setSelectedDoubt('')
    closeDeleteDoubtPopup()
  }

  const filteredBatchSubject = batchSubjects.filter(
    (batchSubject) => batchSubject._id === batch_subject_id
  )
  const currentBatchSubject = filteredBatchSubject.length
    ? filteredBatchSubject[0]
    : ''

  const getSubjectNameById = (id) => {
    const filtered = subjects.filter((subject) => subject._id === id)
    return filtered.length ? filtered[0].subject_title : ''
  }

  const postEnteredNotes = (e) => {
    const payload = {
      note_text: noteText,
      topic_id: topicId,
      batch_subject_id: batch_subject_id,
      topic_title: topicTitle,
      chapter_id: topic.chapter_id,
      chapter_title: chapterTitle,
      subject_id: topic.subject_id,
      subject_title: subjectTitle,
    }
    dispatch(postAddnotes({ jwt, payload }))
  }

  const [popupDoubtRepliesClassName, setPopupDoubtRepliesClassName] =
    useState('hidePop')
  const openPopupDoubtReplies = (doubt) => {
    setDoubtClicked(doubt)
    setPopupDoubtRepliesClassName('showPop')
  }
  const closePopupDoubtReplies = () => {
    setPopupDoubtRepliesClassName('hidePop')
    setDoubtClicked()
  }

  const videoPlayingFunction = (value) => {
    setVideoPlaying(value)
  }

  const handleDoubt = (e, doubt_text) => {
    dispatch(
      fetchAskDoubt({
        jwt,
        payload: {
          doubt_text,
          topic_id: topic._id,
          batch_subject_id: batch_subject_id,
        },
      })
    )
    dispatch(
      startResponseFromAI({
        payload: {
          query: doubt_text,
          data: topic?.wysiwyg_state,
        },
      })
    )
  }

  return (
    <div className="tabs__container">
      <section>
        <h1>{topic.title}</h1>
        <div className="row">
          <div className="col-md-8 padding--0">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/me/subjects">{subjectTitle}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/me/subjects/chapters">
                    {chapterTitle || getChapterNameById(chapterId)}
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/me/subjects/chapters/topics">{topicTitle}</Link>
                </li>
                <li className="breadcrumb-item active">Detail</li>
              </ol>
            </nav>
          </div>
          {topic?.self_assessment && (
            <div className="col-md-4 breadcrumb__rightSide padding--0">
              <Link to="/me/subjects/chapters/topics/assessment">
                <button className="button--blue">TAKE SELF ASSESMENT</button>
              </Link>
            </div>
          )}
        </div>
        <div className="clear"></div>
      </section>
      <section>
        <LeftRightLayout>
          <Col75>
            {isTopicsLoading ? (
              <Loader />
            ) : (
              <div className="topic__detailImage">
                {topic.type === 'image' ? (
                  <img src={topic.content_uri} alt={topic.title} />
                ) : (
                  <ReactPlayer
                    url={topic.content_uri}
                    onPlay={() => videoPlayingFunction(true)}
                    onPause={() => videoPlayingFunction(false)}
                    controls={true}
                    height="500px"
                    width="100%"
                  />
                  // <iframe
                  //   title={topic.title}
                  //   src={topic.content_uri}
                  //   height="500"
                  //   width="100%"
                  //   frameBorder="0"
                  //   allow="autoplay; fullscreen"
                  //   allowFullScreen
                  // ></iframe>
                )}
                <div className="clear"></div>
              </div>
            )}

            <Tabs>
              <Tab eventKey="description" title="Description">
                <div style={{ paddingLeft: '20px' }}>
                  <div
                    dangerouslySetInnerHTML={createMarkup(topic.wysiwyg_state)}
                  />
                </div>
              </Tab>
              {topic.notes && topic.notes.length > 0 && (
                <Tab eventKey="notes" title="Notes">
                  <div
                    className="noPending__button--Wrapper"
                    style={{ paddingLeft: '20px' }}
                  >
                    {topic.notes.map((file, index) => {
                      return (
                        <button
                          className={'button--blue'}
                          style={{ marginLeft: 0 }}
                          onClick={() => window.open(file, '_blank')}
                        >
                          Note {index + 1}
                        </button>
                      )
                    })}
                  </div>
                </Tab>
              )}
              {/* <Tab eventKey="sharedFiles" title="Shared Files(4)">
                <SharedFiles
                  icon={Notes}
                  heading="Important notes"
                  content="This includes a summary of all topics and important points for quick reference."
                />
                <SharedFiles
                  icon={Documents}
                  heading="Important notes"
                  content="This includes a summary of all topics and important points for quick reference."
                />
                <SharedFiles
                  icon={Excel}
                  heading="Important notes"
                  content="This includes a summary of all topics and important points for quick reference."
                />
                <SharedFiles
                  icon={PDF}
                  heading="Important notes"
                  content="This includes a summary of all topics and important points for quick reference."
                />
              </Tab> */}
            </Tabs>
            <Popup
              className={popClassName}
              close={() => closePopup()}
              onClick={() => onEditNote()}
              buttonText="Save Note"
              popupHead="Edit Note"
            >
              <textarea
                rows="5"
                value={noteText || ''}
                onChange={(e) => setNoteText(e.target.value)}
              ></textarea>
            </Popup>
            <Popup
              className={deletePopClassName}
              close={() => closeDeletePopup()}
              onClick={() => onDelete()}
              buttonText="yes"
              popupHead="Delete Note"
            >
              <h2>Are you sure want to delete?</h2>
            </Popup>
            <Popup
              className={editDoubtPopClassName}
              close={() => closeEditDoubtPopup()}
              onClick={() => onDoubtEdit()}
              buttonText="Save Doubt"
              popupHead="Edit Doubt"
            >
              <textarea
                rows="5"
                value={editText || ''}
                onChange={(e) => setEditText(e.target.value)}
              ></textarea>
            </Popup>
            <Popup
              className={deleteDoubtPopClassName}
              close={() => closeDeleteDoubtPopup()}
              onClick={() => onDoubtDelete()}
              buttonText="yes"
              popupHead="Delete Doubts"
            >
              <h2>Are you sure want to delete?</h2>
            </Popup>
          </Col75>
          <Col25>
            <Tabs>
              <Tab eventKey="notes" title={`Notes (${notes?.length})`}>
                {notes?.map((note, index) => {
                  return (
                    <NotesCard
                      key={index}
                      content={note.note_text}
                      editOnClick={(e) => {
                        openPopup(note)
                      }}
                      deleteOnClick={(e) => openDeletePopup(note)}
                    />
                  )
                })}
                <AddNotes
                  onBlur={(e) => postEnteredNotes(e)}
                  value={noteText || ''}
                  onChange={(e) => {
                    setNoteText(e.target.value)
                  }}
                  onClick={(e) => postEnteredNotes(e)}
                />
              </Tab>
              <Tab
                className="doubtsTab"
                eventKey="doubts"
                title={`Q&A (${doubts?.length})`}
              >
                {doubts?.length > 0 ? (
                  doubts
                    ?.sort(
                      (a, b) =>
                        new Date(a?.created_at) - new Date(b?.created_at)
                    )
                    ?.map((doubt, index) => {
                      return (
                        <DoubtsCard
                          key={index}
                          picture={ProfilePic}
                          name={
                            doubt?.author_name
                              ? doubt?.author_name === username
                                ? 'You'
                                : 'Anonymous'
                              : ''
                          }
                          subject={getSubjectNameById(doubt.batch_subject_id)}
                          chapter={doubt?.topic_title}
                          // responses={doubt?.response_count}
                          time={moment(doubt.created_at).fromNow()}
                          content={doubt.doubt_text}
                          responses={
                            responsesGroupByDoubtId?.[doubt._id]?.length || 0
                          }
                          buttonText={`SEE TEACHER REPLIES (${
                            responsesGroupByDoubtId?.[doubt._id]?.length || 0
                          })`}
                          replyButton={() => openPopupDoubtReplies(doubt)}
                          editOnClick={() => {
                            openEditDoubtPopup(doubt)
                          }}
                          deleteOnClick={() => {
                            openDeleteDoubtPopup(doubt)
                          }}
                        />
                      )
                    })
                ) : (
                  <p>No doubts available</p>
                )}

                {responseFromAiLoading ? (
                  <p>Thinkng...</p>
                ) : (
                  <div className="doubtsCard">
                    <div className="row">
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 doubtsCard__profile">
                        <img src={ProfilePic} alt="profile" />
                        <p className="doubtsCard__PersonName">CWX's Answer</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div
                          dangerouslySetInnerHTML={createMarkup(responseFromAi)}
                        />
                      </div>
                      <div className="clear"></div>
                    </div>
                  </div>
                )}

                <InputSend
                  placeholder="Ask your doubt"
                  onClick={(e, doubt_text) => handleDoubt(e, doubt_text)}
                />
              </Tab>
            </Tabs>
            <PopupDoubt
              className={popupDoubtRepliesClassName}
              close={() => closePopupDoubtReplies()}
              onClick=""
              buttonText="Save Doubt"
              popupHead="Doubt Replies"
            >
              <DoubtsCard
                picture={ProfilePic}
                name={doubtClicked?.author_name}
                subject={getSubjectNameById(doubtClicked?.batch_subject_id)}
                chapter={doubtClicked?.topic_title}
                responses={doubtClicked?.response_count}
                time={moment(doubtClicked?.created_at).fromNow()}
                content={doubtClicked?.doubt_text}
                editOnClick={() => {
                  setPopClassName('hidePop')
                  openEditDoubtPopup(doubtClicked)
                }}
                deleteOnClick={() => {
                  setPopClassName('hidePop')
                  openDeleteDoubtPopup(doubtClicked)
                }}
              />
              {responsesGroupByDoubtId?.[doubtClicked?._id]?.map((response) => {
                return (
                  <ReplyCard
                    picture={ProfilePic}
                    name={response.author_name}
                    time={moment(response.created_at).fromNow()}
                    content={response.response_text}
                  />
                )
              })}
              <InputSend
                placeholder="Reply your comment"
                onClick={(respondMsg) => {
                  const payload = {
                    response_text: respondMsg,
                    doubt_id: doubtClicked._id,
                    subject_id: doubtClicked.batch_subject_id,
                  }
                  dispatch(postReplyDoubt({ jwt, payload, topicId }))
                }}
              />
            </PopupDoubt>
          </Col25>
        </LeftRightLayout>
      </section>
      {topic?.self_assessment ? (
        <section>
          <div className="timeForSelfAssessment">
            <div className="timeForSelfAssessment__left">
              <h3>Time for Self Assessment</h3>
              <p>
                It’s always good to test your learning. Take a quick self
                assesment.
              </p>
            </div>
            <div className="timeForSelfAssessment__right">
              <Link to="/me/subjects/chapters/topics/assessment">
                <button className="button--white">TAKE SELF ASSESSMENT</button>
              </Link>
            </div>
            <div className="clear"></div>
          </div>
        </section>
      ) : null}
      <div className="clear"></div>
    </div>
  )
}

export default TopicDetail
