import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import Login from './login'
import ChangePassword from './login/changePassword'

import Dashboard from './dashboard'
// import Layouts from './layouts.js'
import ModeratorSubject from './modarator/subject'
import ModeratorSubjectCreate from './modarator/subject/create'
import ModeratorBatchCreate from './modarator/batch/create'
import ModeratorChapter from './modarator/chapter'
import ModeratorTopic from './modarator/topic'
import ModeratorAddTopics from './modarator/topic/addTopics.js'
import ModeratorCreateChapter from './modarator/chapter/createChapter'
import ModeratorEditChapter from './modarator/chapter/editChapter'
import ModeratorEditTopic from './modarator/topic/editTopic'
import ModeratorEditStudent from './modarator/student/editStudent'
import ModeratorTopicDetail from './modarator/topic/topicDetail'
import ModeratorCreateFaculty from './teacher/create'
import ModeratorAssignFaculty from './teacher/assignToBatchSubject'

import ModeratorReports from './modarator/reports'
import ModeratorOnlineSessionsReports from './modarator/reports/faculty/onlineSessionReports'
import ModeratorTopicsReports from './modarator/reports/topicsReport'
import ModeratorFacultyReports from './modarator/reports/faculty/facultyReport'
import ModeratorOnlineSessionsViewReport from './modarator/reports/faculty/onlineSessionViewReport'
import ModeratorAssessment from './modarator/assessment'
import ModeratorAssessmentMarks from './modarator/assessment/assessmentMarks'
import ModeratorAssessmentQuestions from './modarator/assessment/assessmentQuestion'

import MyProfile from './student/profile'
import StudentChapters from './student/chapters'
import StudentTopics from './student/topics'
import StudentTopicDetail from './student/topicDetail'
import StudentSubjets from './student/mySubjects'
import StudentDoubts from './student/myDoubts'
import StudentNotes from './student/myNotes'
import MyTeachers from './student/myteachers'
import StudentAssessment from './student/myAssessment'
import StudentAssessmentQuestions from './student/assessmentQuestions'
import AssessmentResults from './student/assessmentResults'
import StudentNotesDetail from './student/notesDetail'
import StudentTopicAssessment from './student/topicAssessment'
// import StudentTopicAssessment from './student/topicAssessment'
import StudentList from './modarator/batch/student/list'
import StudyplanCreation from './modarator/studyplan'
import AssignStudentToSubjects from './modarator/subject/add-removeStudent.js'

import FacultySubjects from './teacher/subject'
import FacultyChapters from './teacher/chapters'
import FacultyChaptersCreate from './teacher/chapters/createChapter'
import FacultyChaptersEdit from './teacher/chapters/editChapter'
import FacultyTopics from './teacher/topics'
import FacultyTopicCreate from './teacher/topics/addTopics'
import FacultyTopicEdit from './teacher/topics/editTopic'
import FacultyTopicDetail from './teacher/topics/topicDetail'
import FacultyDoubts from './teacher/doubts'
import FacultyAssessment from './teacher/evaluation/myAssessment'
import FacultyAssessmentSubmissions from './teacher/evaluation/assessmentSubmissions'
import MyFacultyProfile from './teacher/profile'

import OwnerCreateInstitution from './owner/institutions/create'

import OnlineSessions from './onlineSession'
import OnlineSessionCreate from './onlineSession/create'
import OnlineSessionDetail from './onlineSession/detail'
import OnlineSessionFaculty from './onlineSession/facultySessions'

import TeacherList from './modarator/teacher'
import EditTeacher from './modarator/teacher/editTeacher'

import BulkUplaodStudents from './modarator/student/bulkUploadStudent'
import BulkUpload from './modarator/bulkUpload'

import { PrivateLayout } from './layout'

export default function VicRouter() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          name="home"
          render={() => <Redirect to="/dashboard" />}
        />
        <Route path="/login">
          <Login />
        </Route>
        <PrivateLayout path="/dashboard">
          <Dashboard />
        </PrivateLayout>
        <Route path="/forgot-password">
          <ChangePassword />
        </Route>
        <PrivateLayout exact path="/batch/subjects">
          <ModeratorSubject />
        </PrivateLayout>
        <PrivateLayout exact path="/batch/subjects/create">
          <ModeratorSubjectCreate />
        </PrivateLayout>
        <PrivateLayout exact path="/batch/create">
          <ModeratorBatchCreate />
        </PrivateLayout>
        <PrivateLayout exact path="/batch/subjects/chapters">
          <ModeratorChapter />
        </PrivateLayout>
        <PrivateLayout exact path="/batch/subjects/chapters/topics">
          <ModeratorTopic />
        </PrivateLayout>
        <PrivateLayout
          exact
          path="/batch/:batchId/subjects/:subjectId/chapters/:chapterId/create-topic"
        >
          <ModeratorAddTopics />
        </PrivateLayout>
        <PrivateLayout
          exact
          path="/batch/:batchId/subjects/:subjectId/create-chapter"
        >
          <ModeratorCreateChapter />
        </PrivateLayout>
        <PrivateLayout
          exact
          path="/batch/:batchId/subjects/:subjectId/chapters/:chapterId/edit-chapter"
        >
          <ModeratorEditChapter />
        </PrivateLayout>
        <PrivateLayout
          exact
          path="/batch/:batchId/subjects/:subjectId/chapters/:chapterId/topics/:topicId/edit-topic"
        >
          <ModeratorEditTopic />
        </PrivateLayout>
        <PrivateLayout exact path="/edit/student/:studentId">
          <ModeratorEditStudent />
        </PrivateLayout>
        <PrivateLayout exact path="/batch/subjects/chapters/topics/topicDetail">
          <ModeratorTopicDetail />
        </PrivateLayout>
        <PrivateLayout exact path="/create-faculty">
          <ModeratorCreateFaculty />
        </PrivateLayout>
        <PrivateLayout exact path="/assign/faculty">
          <ModeratorAssignFaculty />
        </PrivateLayout>
        <PrivateLayout exact path="/reports">
          <ModeratorReports />
        </PrivateLayout>
        <PrivateLayout exact path="/onlinesessions/reports">
          <ModeratorOnlineSessionsReports />
        </PrivateLayout>
        <PrivateLayout exact path="/onlinesession/report">
          <ModeratorOnlineSessionsViewReport />
        </PrivateLayout>
        <PrivateLayout exact path="/faculty/reports">
          <ModeratorFacultyReports />
        </PrivateLayout>
        <PrivateLayout exact path="/topic/reports">
          <ModeratorTopicsReports />
        </PrivateLayout>
        <PrivateLayout exact path="/create/assessment">
          <ModeratorAssessment />
        </PrivateLayout>
        <PrivateLayout exact path="/assessment/marks">
          <ModeratorAssessmentMarks />
        </PrivateLayout>
        <PrivateLayout exact path="/assessment/questions/:id">
          <ModeratorAssessmentQuestions />
        </PrivateLayout>

        <PrivateLayout exact path="/me/subjects/chapters">
          <StudentChapters />
        </PrivateLayout>
        <PrivateLayout exact path="/me/subjects/chapters/topics">
          <StudentTopics />
        </PrivateLayout>
        <PrivateLayout exact path="/me/subjects/chapters/topics/detail">
          <StudentTopicDetail />
        </PrivateLayout>
        <PrivateLayout exact path="/me/subjects">
          <StudentSubjets />
        </PrivateLayout>
        <PrivateLayout exact path="/me/doubts">
          <StudentDoubts />
        </PrivateLayout>
        <PrivateLayout exact path="/me/notes">
          <StudentNotes />
        </PrivateLayout>
        <PrivateLayout exact path="/me/subjects/notes">
          <StudentNotesDetail />
        </PrivateLayout>
        <PrivateLayout exact path="/me/teachers">
          <MyTeachers />
        </PrivateLayout>
        <PrivateLayout exact path="/me/assessments">
          <StudentAssessment />
        </PrivateLayout>
        <PrivateLayout exact path="/assessments/questions">
          <StudentAssessmentQuestions />
        </PrivateLayout>
        <PrivateLayout exact path="/assessments/results">
          <AssessmentResults />
        </PrivateLayout>
        <PrivateLayout exact path="/me/faculty/subjects">
          <FacultySubjects />
        </PrivateLayout>
        <PrivateLayout exact path="/me/faculty/subjects/chapters/create">
          <FacultyChaptersCreate />
        </PrivateLayout>
        <PrivateLayout exact path="/me/faculty/subjects/chapters/edit">
          <FacultyChaptersEdit />
        </PrivateLayout>
        <PrivateLayout exact path="/me/faculty/subjects/chapters/topics">
          <FacultyTopics />
        </PrivateLayout>
        <PrivateLayout exact path="/me/faculty/subjects/chapters/topics/create">
          <FacultyTopicCreate />
        </PrivateLayout>
        <PrivateLayout exact path="/me/faculty/subjects/chapters/topics/edit">
          <FacultyTopicEdit />
        </PrivateLayout>
        <PrivateLayout exact path="/me/faculty/subjects/chapters/topics/detail">
          <FacultyTopicDetail />
        </PrivateLayout>
        <PrivateLayout exact path="/me/faculty/doubts">
          <FacultyDoubts />
        </PrivateLayout>
        <PrivateLayout exact path="/me/subjects/chapters/topics/assessment">
          <StudentTopicAssessment />
        </PrivateLayout>
        <PrivateLayout exact path="/student/list">
          <StudentList />
        </PrivateLayout>
        <PrivateLayout exact path="/me/profile">
          <MyProfile />
        </PrivateLayout>
        <PrivateLayout exact path="/faculty/profile">
          <MyFacultyProfile />
        </PrivateLayout>
        <PrivateLayout exact path="/create/study-plan">
          <StudyplanCreation />
        </PrivateLayout>
        <PrivateLayout exact path="/me/sessions">
          <OnlineSessions />
        </PrivateLayout>
        <PrivateLayout exact path="/me/faculty/sessions">
          <OnlineSessionFaculty />
        </PrivateLayout>
        <PrivateLayout exact path="/me/session/create">
          <OnlineSessionCreate />
        </PrivateLayout>
        <PrivateLayout exact path="/me/session/detail">
          <OnlineSessionDetail />
        </PrivateLayout>
        <PrivateLayout exact path="/create-institution">
          <OwnerCreateInstitution />
        </PrivateLayout>
        <PrivateLayout exact path="/me/faculty/subjects/chapters">
          <FacultyChapters />
        </PrivateLayout>
        <PrivateLayout exact path="/faculty/assessments">
          <FacultyAssessment />
        </PrivateLayout>
        <PrivateLayout exact path="/faculty/assessments/submissions">
          <FacultyAssessmentSubmissions />
        </PrivateLayout>
        <PrivateLayout exact path="/faculty/list">
          <TeacherList />
        </PrivateLayout>
        <PrivateLayout exact path="/edit/faculty/:facultyId">
          <EditTeacher />
        </PrivateLayout>
        <PrivateLayout exact path="/student/upload/bulk">
          <BulkUplaodStudents />
        </PrivateLayout>
        <PrivateLayout exact path="/bulk/upload">
          <BulkUpload />
        </PrivateLayout>
        <PrivateLayout exact path="/student/subject">
          <AssignStudentToSubjects />
        </PrivateLayout>
      </Switch>
    </Router>
  )
}
