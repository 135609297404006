export const APP_LOGIN_START = 'APP_LOGIN_START'
export const APP_LOGIN = 'APP_LOGIN'
export const APP_LOGIN_FAIL = 'APP_LOGIN_FAIL'
export const APP_LOGOUT = 'APP_LOGOUT'
export const APP_AUTH_FAILED = 'APP_AUTH_FAILED'
export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'
export const VERIFY_OTP_START = 'VERIFY_OTP_START'
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS'
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE'
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'
export const STUDENT_RESET_PASSWORD_START = 'STUDENT_RESET_PASSWORD_START'
export const STUDENT_RESET_PASSWORD_SUCCESS = 'STUDENT_RESET_PASSWORD_SUCCESS'
export const STUDENT_RESET_PASSWORD_FAILURE = 'STUDENT_RESET_PASSWORD_FAILURE'
export const TEACHER_RESET_PASSWORD_START = 'TEACHER_RESET_PASSWORD_START'
export const TEACHER_RESET_PASSWORD_SUCCESS = 'TEACHER_RESET_PASSWORD_SUCCESS'
export const TEACHER_RESET_PASSWORD_FAILURE = 'TEACHER_RESET_PASSWORD_FAILURE'
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'
export const GET_PROFILE_INFO_START = 'GET_PROFILE_INFO_START'
export const GET_PROFILE_INFO_SUCCESS = 'GET_PROFILE_INFO_SUCCESS'
export const GET_PROFILE_INFO_FAILURE = 'GET_PROFILE_INFO_FAILURE'
export const CREATE_LOGIN_START = 'CREATE_LOGIN_START'
export const CREATE_LOGIN_SUCCESS = 'CREATE_LOGIN_SUCCESS'
export const CREATE_LOGIN_FAIL = 'CREATE_LOGIN_FAIL'

//institution
//get institution
export const GET_INSTITUTION_START = 'GET_INSTITUTION_START'
export const GET_INSTITUTION_SUCCESS = 'GET_INSTITUTION_SUCCESS'
export const GET_INSTITUTION_FAILURE = 'GET_INSTITUTION_FAILURE'
export const POST_INSTITUTION_START = 'POST_INSTITUTION_START'
export const POST_INSTITUTION_SUCCESS = 'POST_INSTITUTION_SUCCESS'
export const POST_INSTITUTION_FAILURE = 'POST_INSTITUTION_FAILURE'
export const GET_ALL_INSTITUTION_START = 'GET_ALL_INSTITUTION_START'
export const GET_ALL_INSTITUTION_SUCCESS = 'GET_ALL_INSTITUTION_SUCCESS'
export const GET_ALL_INSTITUTION_FAILURE = 'GET_ALL_INSTITUTION_FAILURE'
export const DELETE_INSTITUTION_START = 'DELETE_INSTITUTION_START'
export const DELETE_INSTITUTION_SUCCESS = 'DELETE_INSTITUTION_SUCCESS'
export const DELETE_INSTITUTION_FAILURE = 'DELETE_INSTITUTION_FAILURE'
export const PATCH_INSTITUTION_START = 'PATCH_INSTITUTION_START'
export const PATCH_INSTITUTION_SUCCESS = 'PATCH_INSTITUTION_SUCCESS'
export const PATCH_INSTITUTION_FAILURE = 'PATCH_INSTITUTION_FAILURE'

//subject
//get subjects by batch id
export const GET_SUBJECTS_START = 'GET_SUBJECTS_START'
export const GET_SUBJECTS_SUCCESS = 'GET_SUBJECTS_SUCCESS'
export const GET_SUBJECTS_FAILURE = 'GET_SUBJECTS_FAILURE'
export const GET_SUBJECTS_DATA_START = 'GET_SUBJECTS_DATA_START'
export const GET_SUBJECTS_DATA_SUCCESS = 'GET_SUBJECTS_DATA_SUCCESS'
export const GET_SUBJECTS_DATA_FAILURE = 'GET_SUBJECTS_DATA_FAILURE'
export const POST_SUBJECT_START = 'POST_SUBJECT_START'
export const POST_SUBJECT_SUCCESS = 'POST_SUBJECT_SUCCESS'
export const POST_SUBJECT_FAILURE = 'POST_SUBJECT_FAILURE'
export const POST_ASSIGN_FACULTY_BATCH_SUBJECT_START =
  'POST_ASSIGN_FACULTY_BATCH_SUBJECT_START'
export const POST_ASSIGN_FACULTY_BATCH_SUBJECT_SUCCESS =
  'POST_ASSIGN_FACULTY_BATCH_SUBJECT_SUCCESS'
export const POST_ASSIGN_FACULTY_BATCH_SUBJECT_FAILURE =
  'POST_ASSIGN_FACULTY_BATCH_SUBJECT_FAILURE'
export const GET_BATCH_SUBJECT_DATA_BY_ID_START =
  'GET_BATCH_SUBJECT_DATA_BY_ID_START'
export const GET_BATCH_SUBJECT_DATA_BY_ID_SUCCESS =
  'GET_BATCH_SUBJECT_DATA_BY_ID_SUCCESS'
export const GET_BATCH_SUBJECT_DATA_BY_ID_FAILURE =
  'GET_BATCH_SUBJECT_DATA_BY_ID_FAILURE'
export const EDIT_SUBJECT_START = 'EDIT_SUBJECT_START'
export const EDIT_SUBJECT_SUCCESS = 'EDIT_SUBJECT_SUCCESS'
export const EDIT_SUBJECT_FAILURE = 'EDIT_SUBJECT_FAILURE'
export const DELETE_SUBJECT_START = 'DELETE_SUBJECT_START'
export const DELETE_SUBJECT_SUCCESS = 'DELETE_SUBJECT_SUCCESS'
export const DELETE_SUBJECT_FAILURE = 'DELETE_SUBJECT_FAILURE'
//batches
export const GET_BATCHES_START = 'GET_BATCHES_START'
export const GET_BATCHES_SUCCESS = 'GET_BATCHES_SUCCESS'
export const GET_BATCHES_FAILURE = 'GET_BATCHES_FAILURE'
export const POST_BATCH_START = 'POST_BATCH_START'
export const POST_BATCH_SUCCESS = 'POST_BATCH_SUCCESS'
export const POST_BATCH_FAILURE = 'POST_BATCH_FAILURE'
export const PATCH_BATCH_START = 'PATCH_BATCH_START'
export const PATCH_BATCH_SUCCESS = 'PATCH_BATCH_SUCCESS'
export const PATCH_BATCH_FAIL = 'PATCH_BATCH_FAIL'
export const DELETE_BATCH_START = 'DELETE_BATCH_START'
export const DELETE_BATCH_SUCCESS = 'DELETE_BATCH_SUCCESS'
export const DELETE_BATCH_FAIL = 'DELETE_BATCH_FAIL'
export const SET_STUDENT_BATCH_START = 'SET_STUDENT_BATCH_START'
export const SET_STUDENT_BATCH_SUCCESS = 'SET_STUDENT_BATCH_SUCCESS'
export const SET_STUDENT_BATCH_FAILURE = 'SET_STUDENT_BATCH_FAILURE'
export const ASSIGN_STUDENT_BATCH_START = 'ASSIGN_STUDENT_BATCH_START'
export const ASSIGN_STUDENT_BATCH_SUCCESS = 'ASSIGN_STUDENT_BATCH_SUCCESS'
export const ASSIGN_STUDENT_BATCH_FAILURE = 'ASSIGN_STUDENT_BATCH_FAILURE'
export const GET_TEACHER_BATCH_SUBJECTS_START =
  'GET_TEACHER_BATCH_SUBJECTS_START'
export const GET_TEACHER_BATCH_SUBJECTS_SUCCESS =
  'GET_TEACHER_BATCH_SUBJECTS_SUCCESS'
export const GET_TEACHER_BATCH_SUBJECTS_FAILURE =
  'GET_TEACHER_BATCH_SUBJECTS_FAILURE'

//UI
export const SET_BATCH = 'SET_BATCH'
export const SET_SUBJECT = 'SET_SUBJECT'
export const SET_CHAPTER = 'SET_CHAPTER'
export const SET_TOPIC = 'SET_TOPIC'
export const SET_ASSESSMENT = 'SET_ASSESSMENT'
export const SET_BATCH_SUBJECT_ID = 'SET_BATCH_SUBJECT_ID'
export const CLEAR_SUBJECT_SELECTED = 'CLEAR_SUBJECT_SELECTED'
export const SET_DOUBT_ID = 'SET_DOUBT_ID'

//chapters
export const GET_CHAPTERS_START = 'GET_CHAPTERS_START'
export const GET_CHAPTERS_SUCCESS = 'GET_CHAPTERS_SUCCESS'
export const GET_CHAPTERS_FAILURE = 'GET_CHAPTERS_FAILURE'
export const CLEAR_CHAPTER = 'CLEAR_CHAPTER'
export const CREATE_CHAPTER_START = 'CREATE_CHAPTERSSTART'
export const CREATE_CHAPTER_SUCCESS = 'CREATE_CHAPTER_SUCCESS'
export const CREATE_CHAPTER_FAILURE = 'CREATE_CHAPTER_FAILURE'
export const EDIT_CHAPTER_START = 'EDIT_CHAPTERSSTART'
export const EDIT_CHAPTER_SUCCESS = 'EDIT_CHAPTER_SUCCESS'
export const EDIT_CHAPTER_FAILURE = 'EDIT_CHAPTER_FAILURE'
export const DELETE_CHAPTER_START = 'DELETE_CHAPTERSSTART'
export const DELETE_CHAPTER_SUCCESS = 'DELETE_CHAPTER_SUCCESS'
export const DELETE_CHAPTER_FAILURE = 'DELETE_CHAPTER_FAILURE'

//topics
export const GET_TOPICS_START = 'GET_TOPICS_START'
export const GET_TOPICS_SUCCESS = 'GET_TOPICS_SUCCESS'
export const GET_TOPICS_FAILURE = 'GET_TOPICS_FAILURE'
export const GET_TOPIC_START = 'GET_TOPIC_START'
export const GET_TOPIC_SUCCESS = 'GET_TOPIC_SUCCESS'
export const GET_TOPIC_FAILURE = 'GET_TOPIC_FAILURE'
export const ADD_TOPIC_START = 'ADD_TOPIC_START'
export const ADD_TOPIC_SUCCESS = 'ADD_TOPIC_SUCCESS'
export const ADD_TOPIC_FAILURE = 'ADD_TOPIC_FAILURE'
export const EDIT_TOPIC_START = 'EDIT_TOPIC_SSTART'
export const EDIT_TOPIC_SUCCESS = 'EDIT_TOPIC_SUCCESS'
export const EDIT_TOPIC_FAILURE = 'EDIT_TOPIC_FAILURE'
export const DELETE_TOPIC_START = 'DELETE_TOPIC_SSTART'
export const DELETE_TOPIC_SUCCESS = 'DELETE_TOPIC_SUCCESS'
export const DELETE_TOPIC_FAILURE = 'DELETE_TOPIC_FAILURE'
export const CLEAR_TOPICS = 'CLEAR_TOPICS'
export const GET_ALL_TOPICS_START = 'GET_ALL_TOPICS_START'
export const GET_ALL_TOPICS_SUCCESS = 'GET_ALL_TOPICS_SUCCESS'
export const GET_ALL_TOPICS_FAILURE = 'GET_ALL_TOPICS_FAILURE'
export const GET_TOPICS_BY_FACULTIES_START = 'GET_TOPICS_BY_FACULTIES_START'
export const GET_TOPICS_BY_FACULTIES = 'GET_TOPICS_BY_FACULTIES'
export const GET_TOPICS_BY_FACULTIES_FAILURE = 'GET_TOPICS_BY_FACULTIES_FAILURE'

//doubts
export const GET_DOUBTS_BY_TOPIC_ID_START = 'GET_DOUBTS_BY_TOPIC_ID_START'
export const GET_DOUBTS_BY_TOPIC_ID_SUCCESS = 'GET_DOUBTS_BY_TOPIC_ID_SUCCESS'
export const GET_DOUBTS_BY_TOPIC_ID_FAILURE = 'GET_DOUBTS_BY_TOPIC_ID_FAILURE'
export const ASK_DOUBT_START = 'ASK_DOUBT_START'
export const ASK_DOUBT_SUCCESS = 'ASK_DOUBT_SUCCESS'
export const ASK_DOUBT_FAILURE = 'ASK_DOUBT_FAILURE'
export const GET_STUDENT_DOUBTS_START = 'GET_STUDENT_DOUBTS_START'
export const GET_STUDENT_DOUBTS_SUCCESS = 'GET_STUDENT_DOUBTS_SUCCESS'
export const GET_STUDENT_DOUBTS_FAILURE = 'GET_STUDENT_DOUBTS_FAILURE'
export const EDIT_STUDENT_DOUBTS_START = 'EDIT_STUDENT_DOUBTS_START'
export const EDIT_STUDENT_DOUBTS_SUCCESS = 'EDIT_STUDENT_DOUBTS_SUCCESS'
export const EDIT_STUDENT_DOUBTS_FAILURE = 'EDIT_STUDENT_DOUBTS_FAILURE'
export const DELETE_STUDENT_DOUBTS_START = 'DELETE_STUDENT_DOUBTS_START'
export const DELETE_STUDENT_DOUBTS_SUCCESS = 'DELETE_STUDENT_DOUBTS_SUCCESS'
export const DELETE_STUDENT_DOUBTS_FAILURE = 'DELETE_STUDENT_DOUBTS_FAILURE'
export const GET_DOUBTS_BY_SUBJECT_ID_START = 'GET_DOUBTS_BY_SUBJECT_ID_START'
export const GET_DOUBTS_BY_SUBJECT_ID_SUCCESS =
  'GET_DOUBTS_BY_SUBJECT_ID_SUCCESS'
export const GET_DOUBTS_BY_SUBJECT_ID_FAILURE =
  'GET_DOUBTS_BY_SUBJECT_ID_FAILURE'
export const REPLY_DOUBT_START = 'REPLY_DOUBT_START'
export const REPLY_DOUBT_SUCCESS = 'REPLY_DOUBT_SUCCESS'
export const REPLY_DOUBT_FAILURE = 'REPLY_DOUBT_FAILURE'
export const GET_DOUBT_RESPONSE_START = 'GET_DOUBT_RESPONSE_START'
export const GET_DOUBT_RESPONSE_SUCCESS = 'GET_DOUBT_RESPONSE_SUCCESS'
export const GET_DOUBT_RESPONSE_FAILURE = 'GET_DOUBT_RESPONSE_FAILURE'
export const GET_DOUBTS_START = 'GET_DOUBTS_START'
export const GET_DOUBTS_SUCCESS = 'GET_DOUBTS_SUCCESS'
export const GET_DOUBTS_FAILURE = 'GET_DOUBTS_FAILURE'
export const RESPONSE_FROM_AI_START = 'RESPONSE_FROM_AI_START'
export const RESPONSE_FROM_AI_SUCCESS = 'RESPONSE_FROM_AI_SUCCESS'
export const RESPONSE_FROM_AI_FAIL = 'RESPONSE_FROM_AI_FAIL'

//Notes
export const ADD_NOTES_START = 'ADD_NOTES_START'
export const ADD_NOTES_SUCCESS = 'ADD_NOTES_SUCCESS'
export const ADD_NOTES_FAILURE = 'ADD_NOTES_FAILURE'
export const EDIT_NOTES_START = 'EDIT_NOTES_START'
export const EDIT_NOTES_SUCCESS = 'EDIT_NOTES_SUCCESS'
export const EDIT_NOTES_FAILURE = 'EDIT_NOTES_FAILURE'
export const DELETE_NOTES_START = 'DELETE_NOTES_START'
export const DELETE_NOTES_SUCCESS = 'DELETE_NOTES_SUCCESS'
export const DELETE_NOTES_FAILURE = 'DELETE_NOTES_FAILURE'
export const GET_NOTES_BY_TOPIC_ID_START = 'GET_NOTES_BY_TOPIC_ID_START'
export const GET_NOTES_BY_TOPIC_ID_SUCCESS = 'GET_NOTES_BY_TOPIC_ID_SUCCESS'
export const GET_NOTES_BY_TOPIC_ID_FAILURE = 'GET_NOTES_BY_TOPIC_ID_FAILURE'
export const GET_NOTES_BY_BATCH_SUBJECT_ID_START =
  'GET_NOTES_BY_BATCH_SUBJECT_ID_START'
export const GET_NOTES_BY_BATCH_SUBJECT_ID_SUCCESS =
  'GET_NOTES_BY_BATCH_SUBJECT_ID_SUCCESS'
export const GET_NOTES_BY_BATCH_SUBJECT_ID_FAILURE =
  'GET_NOTES_BY_BATCH_SUBJECT_ID_FAILURE'
export const DELETE_NOTE_START = 'DELETE_NOTE_START'
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS'
export const DELETE_NOTE_FAILURE = 'DELETE_NOTE_FAILURE'
export const GET_NOTES_BY_SESSION_ID_START = 'GET_NOTES_BY_SESSION_ID_START'
export const GET_NOTES_BY_SESSION_ID_SUCCESS = 'GET_NOTES_BY_SESSION_ID_SUCCESS'
export const GET_NOTES_BY_SESSION_ID_FAILURE = 'GET_NOTES_BY_SESSION_ID_FAILURE'
export const EDIT_ONLINE_SESSION_BY_ID_START = 'EDIT_ONLINE_SESSION_BY_ID_START'
export const EDIT_ONLINE_SESSION_BY_ID_SUCCESS =
  'EDIT_ONLINE_SESSION_BY_ID_SUCCESS'
export const EDIT_ONLINE_SESSION_BY_ID_FAILURE =
  'EDIT_ONLINE_SESSION_BY_ID_FAILURE'

//assessments
export const GET_ASSESSMENT_START = 'GET_ASSESSMENT_START'
export const GET_ASSESSMENT_SUCCESS = 'GET_ASSESSMENT_SUCCESS'
export const GET_ASSESSMENT_FAILURE = 'GET_ASSESSMENT_FAILURE'
export const GET_ASSESSMENTS_START = 'GET_ASSESSMENTS_START'
export const GET_ASSESSMENTS_SUCCESS = 'GET_ASSESSMENTS_SUCCESS'
export const GET_ASSESSMENTS_LONG_POLLING_SUCCESS =
  'GET_ASSESSMENTS_LONG_POLLING_SUCCESS'
export const GET_ASSESSMENTS_FAILURE = 'GET_ASSESSMENTS_FAILURE'
export const GET_ASSESSMENT_QUESTIONS_BY_ID_START =
  'GET_ASSESSMENT_QUESTIONS_BY_ID_START'
export const GET_ASSESSMENT_QUESTIONS_BY_ID_SUCCESS =
  'GET_ASSESSMENT_QUESTIONS_BY_ID_SUCCESS'
export const GET_ASSESSMENT_QUESTIONS_BY_ID_FAILURE =
  'GET_ASSESSMENT_QUESTIONS_BY_ID_FAILURE'
export const POST_ASSESSMENT_SUBMISSION_START =
  'POST_ASSESSMENT_SUBMISSION_START'
export const POST_ASSESSMENT_SUBMISSION_SUCCESS =
  'POST_ASSESSMENT_SUBMISSION_SUCCESS'
export const POST_ASSESSMENT_SUBMISSION_FAILURE =
  'POST_ASSESSMENT_SUBMISSION_FAILURE'

export const GET_ASSESSMENT_SUBMISSION_BY_A_ID_AND_STU_ID_START =
  'GET_ASSESSMENT_SUBMISSION_BY_A_ID_AND_STU_ID_START'
export const GET_ASSESSMENT_SUBMISSION_BY_A_ID_AND_STU_ID_SUCCESS =
  'GET_ASSESSMENT_SUBMISSION_BY_A_ID_AND_STU_ID_SUCCESS'
export const GET_ASSESSMENT_SUBMISSION_BY_A_ID_AND_STU_ID_FAILURE =
  'GET_ASSESSMENT_SUBMISSION_BY_A_ID_AND_STU_ID_FAILURE'
export const POST_ASSESSMENT_EVALUATE_START = 'POST_ASSESSMENT_EVALUATE_START'
export const POST_ASSESSMENT_EVALUATE_SUCCESS =
  'POST_ASSESSMENT_EVALUATE_SUCCESS'
export const POST_ASSESSMENT_EVALUATE_FAILURE =
  'POST_ASSESSMENT_EVALUATE_FAILURE'
export const UNSET_ASSESSMENT_SUBMISSIONS = 'UNSET_ASSESSMENT_SUBMISSIONS'
export const POST_ASSESSMENT_START = 'POST_ASSESSMENT_START'
export const POST_ASSESSMENT_SUCCESS = 'POST_ASSESSMENT_SUCCESS'
export const POST_ASSESSMENT_FAILURE = 'POST_ASSESSMENT_FAILURE'
export const POST_ASSESSMENT_CSV_URL_START = 'POST_ASSESSMENT_CSV_URL_START'
export const POST_ASSESSMENT_CSV_URL_SUCCESS = 'POST_ASSESSMENT_CSV_URL_SUCCESS'
export const POST_ASSESSMENT_CSV_URL_FAILURE = 'POST_ASSESSMENT_CSV_URL_FAILURE'
export const PATCH_ASSESSMENT_START = 'PATCH_ASSESSMENT_START'
export const PATCH_ASSESSMENT_SUCCESS = 'PATCH_ASSESSMENT_SUCCESS'
export const PATCH_ASSESSMENT_FAILURE = 'PATCH_ASSESSMENT_FAILURE'

export const GET_ASSESSMENT_QUESTIONS_BY_TOPIC_ID_START =
  'GET_ASSESSMENT_QUESTIONS_BY_TOPIC_ID_START'
export const GET_ASSESSMENT_QUESTIONS_BY_TOPIC_ID_SUCCESS =
  'GET_ASSESSMENT_QUESTIONS_BY_TOPIC_ID_SUCCESS'
export const GET_ASSESSMENT_QUESTIONS_BY_TOPIC_ID_FAILURE =
  'GET_ASSESSMENT_QUESTIONS_BY_TOPIC_ID_FAILURE'
export const POST_SELF_ASSESSMENT_SUBMISSION_START =
  'POST_SELF_ASSESSMENT_SUBMISSION_START'
export const POST_SELF_ASSESSMENT_SUBMISSION_SUCCESS =
  'POST_SELF_ASSESSMENT_SUBMISSION_SUCCESS'
export const POST_SELF_ASSESSMENT_SUBMISSION_FAILURE =
  'POST_SELF_ASSESSMENT_SUBMISSION_FAILURE'
export const GET_SELF_ASSESSMENTS_BY_USER_START =
  'GET_SELF_ASSESSMENTS_BY_USER_START'
export const GET_SELF_ASSESSMENTS_BY_USER_SUCCESS =
  'GET_SELF_ASSESSMENTS_BY_USER_SUCCESS'
export const GET_SELF_ASSESSMENTS_BY_USER_FAILURE =
  'GET_SELF_ASSESSMENTS_BY_USER_FAILURE'
export const GET_ASSESSMENT_BY_ID_MARKS_START =
  'GET_ASSESSMENT_BY_ID_MARKS_START'
export const GET_ASSESSMENT_BY_ID_MARKS_SUCCESS =
  'GET_ASSESSMENT_BY_ID_MARKS_SUCCESS'
export const GET_ASSESSMENT_BY_ID_MARKS_FAILURE =
  'GET_ASSESSMENT_BY_ID_MARKS_FAILURE'
export const GET_ASSESSMENT_QUESTIONS_START = 'GET_ASSESSMENT_QUESTIONS_START'
export const GET_ASSESSMENT_QUESTIONS_SUCCESS =
  'GET_ASSESSMENT_QUESTIONS_SUCCESS'
export const GET_ASSESSMENT_QUESTIONS_FAILURE =
  'GET_ASSESSMENT_QUESTIONS_FAILURE'
export const PATCH_ASSESSMENT_STATUS_START = 'PATCH_ASSESSMENT_STATUS_START'
export const PATCH_ASSESSMENT_STATUS_SUCCESS = 'PATCH_ASSESSMENT_STATUS_SUCCESS'
export const PATCH_ASSESSMENT_STATUS_FAILURE = 'PATCH_ASSESSMENT_STATUS_FAILURE'

//faculties
export const GET_FACULTIES_OF_CURRENT_USER_START =
  'GET_FACULTIES_OF_CURRENT_USER_START'
export const GET_FACULTIES_OF_CURRENT_USER_SUCCESS =
  'GET_FACULTIES_OF_CURRENT_USER_SUCCESS'
export const GET_FACULTIES_OF_CURRENT_USER_FAILURE =
  'GET_FACULTIES_OF_CURRENT_USER_FAILURE'
export const POST_FACULTY_START = 'POST_FACULTY_START'
export const POST_FACULTY_SUCCESS = 'POST_FACULTY_SUCCESS'
export const POST_FACULTY_FAILURE = 'POST_FACULTY_FAILURE'
export const GET_FACULTIES_START = 'GET_FACULTIES_START'
export const GET_FACULTIES_SUCCESS = 'GET_FACULTIES_SUCCESS'
export const GET_FACULTY_SUCCESS = 'GET_FACULTY_SUCCESS'
export const GET_FACULTIES_FAILURE = 'GET_FACULTIES_FAILURE'
export const ARCHIVE_FACULTY_START = 'ARCHIVE_FACULTY_START'
export const ARCHIVE_FACULTY_SUCCESS = 'ARCHIVE_FACULTY_SUCCESS'
export const ARCHIVE_FACULTY_FAILURE = 'ARCHIVE_FACULTY_FAILURE'
export const UNARCHIVE_FACULTY_START = 'UNARCHIVE_FACULTY_START'
export const UNARCHIVE_FACULTY_SUCCESS = 'UNARCHIVE_FACULTY_SUCCESS'
export const UNARCHIVE_FACULTY_FAILURE = 'UNARCHIVE_FACULTY_FAILURE'
export const GETALL_FACULTY_START = 'GETALL_FACULTY_START'
export const GETALL_FACULTY_SUCCESS = 'GETALL_FACULTY_SUCCESS'
export const GETALL_FACULTY_FAILURE = 'GETALL_FACULTY_FAILURE'
export const POST_BULK_FACULTY_START = 'POST_BULK_FACULTY_START'
export const POST_BULK_FACULTY_SUCCESS = 'POST_BULK_FACULTY_SUCCESS'
export const POST_BULK_FACULTY_FAILURE = 'POST_BULK_FACULTY_FAILURE'
export const PUT_FACULTY_NEW_USER_START = 'PUT_FACULTY_NEW_USER_START'
export const PUT_FACULTY_NEW_USER_SUCCESS = 'PUT_FACULTY_NEW_USER_SUCCESS'
export const PUT_FACULTY_NEW_USER_FAIL = 'PUT_FACULTY_NEW_USER_FAIL'

//get student subjects
export const GET_STUDENT_SUBJECTS_START = 'GET_STUDENT_SUBJECTS_START'
export const GET_STUDENT_SUBJECTS_SUCCESS = 'GET_STUDENT_SUBJECTS_SUCCESS'
export const GET_STUDENT_SUBJECTS_FAILURE = 'GET_STUDENT_SUBJECTS_FAILURE'
export const GET_STUDENT_STUDY_PLAN_START = 'GET_STUDENT_STUDY_PLAN_START'
export const GET_STUDENT_STUDY_PLAN_SUCCESS = 'GET_STUDENT_STUDY_PLAN_SUCCESS'
export const GET_STUDENT_STUDY_PLAN_FAILURE = 'GET_STUDENT_STUDY_PLAN_FAILURE'
export const POST_STUDENT_STUDY_PLAN_START = 'POST_STUDENT_STUDY_PLAN_START'
export const POST_STUDENT_STUDY_PLAN_SUCCESS = 'POST_STUDENT_STUDY_PLAN_SUCCESS'
export const POST_STUDENT_STUDY_PLAN_FAILURE = 'POST_STUDENT_STUDY_PLAN_FAILURE'
export const GET_BATCH_SUBJETS_OF_STUDENT_START =
  'GET_BATCH_SUBJETS_OF_STUDENT_START'
export const GET_BATCH_SUBJETS_OF_STUDENT_SUCCESS =
  'GET_BATCH_SUBJETS_OF_STUDENT_SUCCESS'
export const GET_BATCH_SUBJETS_OF_STUDENT_FAILURE =
  'GET_BATCH_SUBJETS_OF_STUDENT_FAILURE'
export const GET_STUDENT_WEEKLY_PLAN_START = 'GET_STUDENT_WEEKLY_PLAN_START'
export const GET_STUDENT_WEEKLY_PLAN_SUCCESS = 'GET_STUDENT_WEEKLY_PLAN_SUCCESS'
export const GET_STUDENT_WEEKLY_PLAN_FAILURE = 'GET_STUDENT_WEEKLY_PLAN_FAILURE'
export const DELETE_STUDY_PLAN_START = 'DELETE_STUDY_PLAN_START'
export const DELETE_STUDY_PLAN_SUCCESS = 'DELETE_STUDY_PLAN_SUCCESS'
export const DELETE_STUDY_PLAN_FAILURE = 'DELETE_STUDY_PLAN_FAILURE'
export const GET_STUDENT_STUDY_BY_TOPICID_START =
  'GET_STUDENT_STUDY_BY_TOPICID_START'
export const GET_STUDENT_STUDY_BY_TOPICID_SUCCESS =
  'GET_STUDENT_STUDY_BY_TOPICID_SUCCESS'
export const GET_STUDENT_STUDY_BY_TOPICID_FAILURE =
  'GET_STUDENT_STUDY_BY_TOPICID_FAILURE'
export const GET_STUDENT_STUDY_PLAN_BY_CHAPTER_BACTCH_SUB_START =
  'GET_STUDENT_STUDY_PLAN_BY_CHAPTER_BACTCH_SUB_START'
export const GET_STUDENT_STUDY_PLAN_BY_CHAPTER_BACTCH_SUB_SUCCESS =
  'GET_STUDENT_STUDY_PLAN_BY_CHAPTER_BACTCH_SUB_SUCCESS'
export const GET_STUDENT_STUDY_PLAN_BY_CHAPTER_BACTCH_SUB_FAILURE =
  'GET_STUDENT_STUDY_PLAN_BY_CHAPTER_BACTCH_SUB_FAILURE'
export const POST_STUDENT_SUBJECT_START = 'POST_STUDENT_SUBJECT_START'
export const POST_STUDENT_SUBJECT_SUCCESS = 'POST_STUDENT_SUBJECT_SUCCESS'
export const POST_STUDENT_SUBJECT_FAILURE = 'POST_STUDENT_SUBJECT_FAILURE'
export const DELETE_STUDENT_SUBJECT_START = 'DELETE_STUDENT_SUBJECT_START'
export const DELETE_STUDENT_SUBJECT_SUCCESS = 'DELETE_STUDENT_SUBJECT_SUCCESS'
export const DELETE_STUDENT_SUBJECT_FAILURE = 'DELETE_STUDENT_SUBJECT_FAILURE'

///impressions
export const POST_IMPRESSIONS_START = 'POST_IMPRESSIONS_START'
export const POST_IMPRESSIONS_SUCCESS = 'POST_IMPRESSIONS_SUCCESS'
export const POST_IMPRESSIONS_FAILURE = 'POST_IMPRESSIONS_FAILURE'
export const GET_LAST_VIEWED_START = 'GET_LAST_VIEWED_START'
export const GET_LAST_VIEWED_SUCCESS = 'GET_LAST_VIEWED_SUCCESS'
export const GET_LAST_VIEWED_FAILURE = 'GET_LAST_VIEWED_FAILURE'
export const GET_PENDING_ITEMS_START = 'GET_PENDING_ITEMS_START'
export const GET_PENDING_ITEMS_SUCCESS = 'GET_PENDING_ITEMS_SUCCESS'
export const GET_PENDING_ITEMS_FAILURE = 'GET_PENDING_ITEMS_FAILURE'

//online sessions
export const POST_ONLINE_SESSION_START = 'POST_ONLINE_SESSION_START'
export const POST_ONLINE_SESSION_SUCCESS = 'POST_ONLINE_SESSION_SUCCESS'
export const POST_ONLINE_SESSION_FAILURE = 'POST_ONLINE_SESSION_FAILURE'
export const GET_ONLINE_SESSIONS_START = 'POST_ONLINE_SESSIONS_START'
export const GET_ONLINE_SESSIONS_SUCCESS = 'POST_ONLINE_SESSIONS_SUCCESS'
export const GET_ONLINE_SESSIONS_FAILURE = 'POST_ONLINE_SESSIONS_FAILURE'
export const DELETE_ONLINE_SESSION_START = 'DELETE_ONLINE_SESSION_START'
export const DELETE_ONLINE_SESSION_SUCCESS = 'DELETE_ONLINE_SESSION_SUCCESS'
export const DELETE_ONLINE_SESSION_FAILURE = 'DELETE_ONLINE_SESSION_FAILURE'
export const GET_ONLINE_SESSION_BY_ID_START = 'GET_ONLINE_SESSION_BY_ID_START'
export const GET_ONLINE_SESSION_BY_ID_SUCCESS =
  'GET_ONLINE_SESSION_BY_ID_SUCCESS'
export const GET_ONLINE_SESSION_BY_ID_FAILURE =
  'GET_ONLINE_SESSION_BY_ID_FAILURE'
export const GET_ONLINE_SESSIONS_SUCCESS_LONG_POLLING =
  'GET_ONLINE_SESSIONS_SUCCESS_LONG_POLLING'
export const GET_ONLINE_SESSION_BY_BATCH_ID_START =
  'GET_ONLINE_SESSION_BY_BATCH_ID_START'
export const GET_ONLINE_SESSION_BY_BATCH_ID_SUCCESS =
  'GET_ONLINE_SESSION_BY_BATCH_ID_SUCCESS'
export const GET_ONLINE_SESSION_BY_BATCH_ID_FAILURE =
  'GET_ONLINE_SESSION_BY_BATCH_ID_FAILURE'
export const POST_CUSTOM_SESSION_START = 'POST_CUSTOM_SESSION_START'
export const POST_CUSTOM_SESSION_SUCCESS = 'POST_CUSTOM_SESSION_SUCCESS'
export const POST_CUSTOM_SESSION_FAILURE = ' POST_CUSTOM_SESSION_FAILURE'

//reports
export const GET_DAILY_REPORT_START = 'GET_DAILY_REPORT_START'
export const GET_DAILY_REPORT_SUCCESS = 'GET_DAILY_REPORT_SUCCESS'
export const GET_DAILY_REPORT_FAILURE = 'GET_DAILY_REPORT_FAILURE'
export const GET_DAILY_ATTENDANCE_BY_BATCH_START =
  'GET_DAILY_ATTENDANCE_BY_BATCH_START'
export const GET_DAILY_ATTENDANCE_BY_BATCH_SUCCESS =
  'GET_DAILY_ATTENDANCE_BY_BATCH_SUCCESS'
export const GET_DAILY_ATTENDANCE_BY_BATCH_FAILURE =
  'GET_DAILY_ATTENDANCE_BY_BATCH_FAILURE'
export const GET_DAILY_ATTENDANCE_BY_SUBJECT_START =
  'GET_DAILY_ATTENDANCE_BY_SUBJECT_START'
export const GET_DAILY_ATTENDANCE_BY_SUBJECT_SUCCESS =
  'GET_DAILY_ATTENDANCE_BY_SUBJECT_SUCCESS'
export const GET_DAILY_ATTENDANCE_BY_SUBJECT_FAILURE =
  'GET_DAILY_ATTENDANCE_BY_SUBJECT_FAILURE'
export const GET_WEEKLY_ATTENDANCE_START = 'GET_WEEKLY_ATTENDANCE_START'
export const GET_WEEKLY_ATTENDANCE_SUCCESS = 'GET_WEEKLY_ATTENDANCE_SUCCESS'
export const GET_WEEKLY_ATTENDANCE_FAILURE = 'GET_WEEKLY_ATTENDANCE_FAILURE'
export const GET_REPORT_BY_TOPICID_START = 'GET_REPORT_BY_TOPICID_START'
export const GET_REPORT_BY_TOPICID_SUCCESS = 'GET_REPORT_BY_TOPICID_SUCCESS'
export const GET_REPORT_BY_TOPICID_FAILURE = 'GET_REPORT_BY_TOPICID_FAILURE'
export const GET_LIVE_CLASS_ATTENDANCE_START = 'GET_LIVE_CLASS_ATTENDANCE_START'
export const GET_LIVE_CLASS_ATTENDANCE_SUCCESS =
  'GET_LIVE_CLASS_ATTENDANCE_SUCCESS'
export const GET_LIVE_CLASS_ATTENDANCE_FAILURE =
  'GET_LIVE_CLASS_ATTENDANCE_FAILURE'

//faculty report
export const GET_FACULTY_REPORT_START = 'GET_FACULTY_REPORT_START'
export const GET_FACULTY_REPORT_SUCCESS = 'GET_FACULTY_REPORT_SUCCESS'
export const GET_FACULTY_REPORT_FAILURE = 'GET_FACULTY_REPORT_FAILURE'

//students
export const GET_STUDENT_START = 'GET_STUDENT_START'
export const GET_STUDENT_SUCCESS = 'GET_STUDENT_SUCCESS'
export const GET_STUDENT_FAILURE = 'GET_STUDENT_FAILURE'
export const REMOVE_STUDENT_START = 'REMOVE_STUDENT_START'
export const REMOVE_STUDENT_SUCCESS = 'REMOVE_STUDENT_SUCCESS'
export const REMOVE_STUDENT_FAILURE = 'REMOVE_STUDENT_FAILURE'
export const UNARCHIVE_STUDENT_START = 'UNARCHIVE_STUDENT_START'
export const UNARCHIVE_STUDENT_SUCCESS = 'UNARCHIVE_STUDENT_SUCCESS'
export const UNARCHIVE_STUDENT_FAILURE = 'UNARCHIVE_STUDENT_FAILURE'
export const PATCH_STUDENT_START = 'PATCH_STUDENT_START'
export const PATCH_STUDENT_SUCCESS = 'PATCH_STUDENT_SUCCESS'
export const PATCH_STUDENT_FAILURE = 'PATCH_STUDENT_FAILURE'
export const POST_STUDENT_START = 'POST_STUDENT_START'
export const POST_STUDENT_SUCCESS = 'POST_STUDENT_SUCCESS'
export const POST_STUDENT_FAILURE = 'POST_STUDENT_FAILURE'
export const POST_BULK_STUDENT_START = 'POST_BULK_STUDENT_START'
export const POST_BULK_STUDENT_SUCCESS = 'POST_BULK_STUDENT_SUCCESS'
export const POST_BULK_STUDENT_FAILURE = 'POST_BULK_STUDENT_FAILURE'
export const PUT_STUDENT_NEW_USER_START = 'PUT_STUDENT_NEW_USER_START'
export const PUT_STUDENT_NEW_USER_SUCCESS = 'PUT_STUDENT_NEW_USER_SUCCESS'
export const PUT_STUDENT_NEW_USER_FAIL = 'PUT_STUDENT_NEW_USER_FAIL'

//announcements
export const GET_ANNOUNCEMENTS_START = 'GET_ANNOUNCEMENTS_START'
export const GET_ANNOUNCEMENTS_SUCCESS = 'GET_ANNOUNCEMENTS_SUCCESS'
export const GET_ANNOUNCEMENTS_FAILURE = 'GET_ANNOUNCEMENTS_FAILURE'

//reaload the for student
export const EDIT_REALOAD_SUCCESS = 'EDIT_REALOAD_SUCCESS'

//file upload
export const UPLOAD_FILE_START = 'UPLOAD_FILE_START'
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL'
export const UPLOAD_FILE_START_TOPIC = 'UPLOAD_FILE_START_TOPIC'
export const UPLOAD_FILE_TOPIC = 'UPLOAD_FILE_TOPIC'
export const UPLOAD_FILE_FAIL_TOPIC = 'UPLOAD_FILE_FAIL_TOPIC'
export const CLEAR_FILE_UPLOAD = 'CLEAR_FILE_UPLOAD'

//vimeo upload
export const VIMEO_UPLOAD_START = 'VIMEO_UPLOAD_START'
export const VIMEO_UPLOAD_FILE = 'VIMEO_UPLOAD_FILE'
export const VIMEO_UPLOAD_FAIL = 'VIMEO_UPLOAD_FAIL'
