import Audio from '../assets/images/old/audio.png'
import Video from '../assets/images/old/video.png'
import Image from '../assets/images/old/image.png'

// export const errorToast = message => toast.error(`${message}`)
export const composeAuth = (jwt) => 'Bearer ' + jwt
export const parseJSON = (response) => response.json()

export const getById = ({ data, key }) => {
  let byId = {}
  const id = key ? key : '_id'
  data.forEach((s) => {
    byId[s[id]] = s
  })
  return byId
}

export const topicType = (type) => {
  switch (type) {
    case 'audio':
      return Audio
    case 'video':
      return Video
    case 'image':
      return Image
    default:
      return Video
  }
}

export const getFormData = (files) => {
  let formData = new FormData()
  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    const fsize = files[i].size
    const fileaccurate = Math.round(fsize / 1024)
    if (fileaccurate > 40960) {
      // errorToast('File should be less than 20MB.')
      break
    } else formData.append('file', file, file.name)
  }
  return formData
}
