import { isError } from 'underscore'
import {
  GET_STUDENT_START,
  GET_STUDENT_SUCCESS,
  GET_STUDENT_FAILURE,
  REMOVE_STUDENT_START,
  REMOVE_STUDENT_SUCCESS,
  REMOVE_STUDENT_FAILURE,
  UNARCHIVE_STUDENT_START,
  UNARCHIVE_STUDENT_SUCCESS,
  UNARCHIVE_STUDENT_FAILURE,
  PATCH_STUDENT_START,
  PATCH_STUDENT_SUCCESS,
  PATCH_STUDENT_FAILURE,
  POST_STUDENT_START,
  POST_STUDENT_SUCCESS,
  POST_STUDENT_FAILURE,
  POST_BULK_STUDENT_START,
  POST_BULK_STUDENT_SUCCESS,
  POST_BULK_STUDENT_FAILURE,
  PUT_STUDENT_NEW_USER_START,
  PUT_STUDENT_NEW_USER_SUCCESS,
  PUT_STUDENT_NEW_USER_FAIL,
  APP_LOGOUT,
  POST_STUDENT_SUBJECT_START,
  POST_STUDENT_SUBJECT_SUCCESS,
  POST_STUDENT_SUBJECT_FAILURE,
  DELETE_STUDENT_SUBJECT_START,
  DELETE_STUDENT_SUBJECT_SUCCESS,
  DELETE_STUDENT_SUBJECT_FAILURE,
} from '../actions'

let initialState = {
  students: [],
  isLoading: false,
  isError: false,
  studentListRemove: [],
  isLoadingRemoveStudent: false,
  isLoadingEdit: false,
  isErrorRemove: false,
  addedStudent: {},
  resetLoading: false,
  isLoadingStudentSubject: false,
  isErrorStudentSubject: false,
}
export const students = (state = initialState, action) => {
  switch (action.type) {
    case POST_STUDENT_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case POST_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addedStudent: action.data,
        isError: false,
      }
    case POST_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case POST_BULK_STUDENT_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case POST_BULK_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addedStudent: action.data,
        isError: false,
      }
    case POST_BULK_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case PUT_STUDENT_NEW_USER_START:
      return {
        ...state,
        resetLoading: true,
        isError: false,
      }
    case PUT_STUDENT_NEW_USER_SUCCESS:
      return {
        ...state,
        resetLoading: false,
        isError: false,
      }
    case PUT_STUDENT_NEW_USER_FAIL:
      return {
        ...state,
        resetLoading: false,
        isError: true,
      }
    case GET_STUDENT_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case GET_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        students: action.student,
      }
    case GET_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case PATCH_STUDENT_START:
      return {
        ...state,
        isLoadingEdit: true,
        isError: false,
      }
    case PATCH_STUDENT_SUCCESS:
      return {
        ...state,
        isLoadingEdit: false,
        isError: false,
        students: action.student,
      }
    case PATCH_STUDENT_FAILURE:
      return {
        ...state,
        isLoadingEdit: false,
        isError: true,
      }
    case REMOVE_STUDENT_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case REMOVE_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      }
    case REMOVE_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case UNARCHIVE_STUDENT_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case UNARCHIVE_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        students: action.student,
      }
    case UNARCHIVE_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case POST_STUDENT_SUBJECT_START:
      return {
        ...state,
        isLoadingStudentSubject: true,
        isErrorStudentSubject: false,
      }
    case POST_STUDENT_SUBJECT_SUCCESS:
      return {
        ...state,
        isLoadingStudentSubject: false,
        isErrorStudentSubject: false,
      }
    case POST_STUDENT_SUBJECT_FAILURE:
      return {
        ...state,
        isLoadingStudentSubject: false,
        isErrorStudentSubject: true,
      }
    case DELETE_STUDENT_SUBJECT_START:
      return {
        ...state,
        isLoadingStudentSubject: true,
        isErrorStudentSubject: false,
      }
    case DELETE_STUDENT_SUBJECT_SUCCESS:
      return {
        ...state,
        isLoadingStudentSubject: false,
        isError: false,
        studentsStudentSubject: action.student,
      }
    case DELETE_STUDENT_SUBJECT_FAILURE:
      return {
        ...state,
        isLoadingStudentSubject: false,
        isErrorStudentSubject: true,
      }
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

export const getStudents = (state) => state.students.students
export const getIsLoading = (state) => state.students.isLoading
export const getIsError = (state) => state.students.isError
export const getRemoveStudents = (state) => state.students.students
export const getRemoveIsLoading = (state) => state.students.isLoading
export const getRemoveIsError = (state) => state.students.isError
export const getUnarchiveStudents = (state) => state.students.students
export const getUnarchiveIsLoading = (state) => state.students.isLoading
export const getUnarchiveIsError = (state) => state.students.isError
export const getIsLoadingEdit = (state) => state.students.isLoadingEdit
export const getAddedStudent = (state) => state.students.addedStudent
export const getResetStudentLoading = (state) => state.students.resetLoading
export const getIsLoadingStudentSubject = (state) =>
  state.students.isLoadingStudentSubject
export const getIsErrorStudentSubject = (state) =>
  state.student.isErrorStudentSubject
