import {
  GET_BATCHES_START,
  GET_BATCHES_SUCCESS,
  GET_BATCHES_FAILURE,
  POST_BATCH_START,
  POST_BATCH_SUCCESS,
  POST_BATCH_FAILURE,
  SET_STUDENT_BATCH_START,
  SET_STUDENT_BATCH_SUCCESS,
  SET_STUDENT_BATCH_FAILURE,
  GET_TEACHER_BATCH_SUBJECTS_START,
  GET_TEACHER_BATCH_SUBJECTS_SUCCESS,
  GET_TEACHER_BATCH_SUBJECTS_FAILURE,
  ASSIGN_STUDENT_BATCH_START,
  ASSIGN_STUDENT_BATCH_SUCCESS,
  ASSIGN_STUDENT_BATCH_FAILURE,
  PATCH_BATCH_START,
  PATCH_BATCH_SUCCESS,
  PATCH_BATCH_FAIL,
  DELETE_BATCH_START,
  DELETE_BATCH_SUCCESS,
  DELETE_BATCH_FAIL,
} from '../actions'
import { getById } from '../../helper/utils'
import { checkStatus } from '../helpers.js'

const getBatchesStart = () => {
  return {
    type: GET_BATCHES_START,
  }
}

const getBatchesSuccess = (batches) => {
  const batchesById = getById({ data: batches })
  return {
    type: GET_BATCHES_SUCCESS,
    batches,
    batchesById,
  }
}
const getBatchesFailure = () => {
  return {
    type: GET_BATCHES_FAILURE,
  }
}

const fetchDataForBatches = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/batches`
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }
  return fetch(url, options)
}
export const fetchBatches = (data) => {
  return async (dispatch) => {
    dispatch(getBatchesStart())
    try {
      const receivedData = await fetchDataForBatches(data)
      const response = await receivedData.json()
      let batches = response.data.batches
      dispatch(getBatchesSuccess(batches))
    } catch (err) {
      dispatch(getBatchesFailure())
    }
  }
}

const postBatchStart = () => {
  return {
    type: POST_BATCH_START,
  }
}

const postBatchSuccess = () => {
  return {
    type: POST_BATCH_SUCCESS,
  }
}
const postBatchFailure = () => {
  return {
    type: POST_BATCH_FAILURE,
  }
}

const postBatchApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/batches`
  const options = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}
export const postBatch = (data) => {
  return async (dispatch) => {
    dispatch(postBatchStart())
    try {
      const receivedData = await postBatchApiCall(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      let batches = response.data.batches
      dispatch(fetchBatches({ jwt: data.jwt }))
      dispatch(postBatchSuccess(batches))
    } catch (err) {
      dispatch(postBatchFailure())
    }
  }
}

// edit batch
const patchBatchStart = () => {
  return {
    type: PATCH_BATCH_START,
  }
}

const patchBatchSuccess = () => {
  return {
    type: PATCH_BATCH_SUCCESS,
  }
}
const patchBatchFail = () => {
  return {
    type: PATCH_BATCH_FAIL,
  }
}

const patchBatch = ({ jwt, id, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/batches/${id}`
  const options = {
    method: 'PATCH',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const startPatchBatch = (data) => {
  return async (dispatch) => {
    dispatch(patchBatchStart())
    try {
      const receivedData = await patchBatch(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(patchBatchSuccess())
    } catch (err) {
      dispatch(patchBatchFail())
    }
  }
}

//archive institution
const archiveBatchStart = () => ({
  type: DELETE_BATCH_START,
})

const archiveBatchSuccess = () => ({
  type: DELETE_BATCH_SUCCESS,
})

const archiveBatchFail = () => ({
  type: DELETE_BATCH_FAIL,
})

const archiveBatch = ({ jwt, id }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/batches/${id}`
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const startArchiveBatch = (data) => {
  return async (dispatch) => {
    dispatch(archiveBatchStart())
    try {
      const receivedData = await archiveBatch(data)
      const response = await receivedData.json()
      dispatch(archiveBatchSuccess(response.data))
    } catch (err) {
      dispatch(archiveBatchFail())
    }
  }
}

// set student batch
const setStudentBatchStart = () => {
  return {
    type: SET_STUDENT_BATCH_START,
  }
}

const setStudentBatchSuccess = () => {
  return {
    type: SET_STUDENT_BATCH_SUCCESS,
  }
}
const setStudentBatchFailure = () => {
  return {
    type: SET_STUDENT_BATCH_FAILURE,
  }
}

const setStudentBatchApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/batches/student`
  const options = {
    method: 'PATCH',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const setStudentBatch = (data) => {
  return async (dispatch) => {
    dispatch(setStudentBatchStart())
    try {
      const receivedData = await setStudentBatchApiCall(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(setStudentBatchSuccess())
    } catch (err) {
      dispatch(setStudentBatchFailure())
    }
  }
}

// assign student batch
const assignStudentBatchStart = () => {
  return {
    type: ASSIGN_STUDENT_BATCH_START,
  }
}

const assignStudentBatchSuccess = () => {
  return {
    type: ASSIGN_STUDENT_BATCH_SUCCESS,
  }
}
const assignStudentBatchFailure = () => {
  return {
    type: ASSIGN_STUDENT_BATCH_FAILURE,
  }
}

const assignStudentBatchApiCall = ({ jwt, batchId, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/batches/${batchId}/students`
  const options = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const assignStudentBatch = (data) => {
  return async (dispatch) => {
    dispatch(assignStudentBatchStart())
    try {
      const receivedData = await assignStudentBatchApiCall(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(assignStudentBatchSuccess())
    } catch (err) {
      dispatch(assignStudentBatchFailure())
    }
  }
}

// get teacher batch-subjects
const getTeacherBatchSubjectsStart = () => {
  return {
    type: GET_TEACHER_BATCH_SUBJECTS_START,
  }
}

const getTeacherBatchSubjectsSuccess = (data) => {
  return {
    type: GET_TEACHER_BATCH_SUBJECTS_SUCCESS,
    data,
  }
}
const getTeacherBatchSubjectsFailure = () => {
  return {
    type: GET_TEACHER_BATCH_SUBJECTS_FAILURE,
  }
}

const getTeacherBatchSubjectsApiCall = ({ jwt, academicYear, facultyId }) => {
  const url = `${
    process.env.REACT_APP_SERVER
  }/api/batch-subjects/faculty_id/${facultyId}/academic_year/${encodeURIComponent(
    academicYear
  )}`
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }
  return fetch(url, options)
}

export const getTeacherBatchSubjects = (data) => {
  return async (dispatch) => {
    dispatch(getTeacherBatchSubjectsStart())
    try {
      const receivedData = await getTeacherBatchSubjectsApiCall(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getTeacherBatchSubjectsSuccess(response?.data?.batch_subjects))
    } catch (err) {
      dispatch(getTeacherBatchSubjectsFailure())
    }
  }
}
