import {
  GET_TOPICS_START,
  GET_TOPICS_SUCCESS,
  GET_TOPICS_FAILURE,
  ADD_TOPIC_START,
  ADD_TOPIC_SUCCESS,
  ADD_TOPIC_FAILURE,
  EDIT_TOPIC_START,
  EDIT_TOPIC_SUCCESS,
  EDIT_TOPIC_FAILURE,
  DELETE_TOPIC_START,
  DELETE_TOPIC_SUCCESS,
  DELETE_TOPIC_FAILURE,
  GET_TOPIC_START,
  GET_TOPIC_SUCCESS,
  GET_TOPIC_FAILURE,
  CLEAR_TOPICS,
  GET_ALL_TOPICS_START,
  GET_ALL_TOPICS_SUCCESS,
  GET_ALL_TOPICS_FAILURE,
  GET_TOPICS_BY_FACULTIES_START,
  GET_TOPICS_BY_FACULTIES,
  GET_TOPICS_BY_FACULTIES_FAILURE,
  UPLOAD_FILE_START_TOPIC,
  UPLOAD_FILE_TOPIC,
  UPLOAD_FILE_FAIL_TOPIC,
} from '../actions'

import { checkStatus } from '../helpers.js'
import { getById, composeAuth, parseJSON } from '../../helper/utils'
// import { faculty } from '../reducer/faculty'

export const clearTopic = () => {
  return {
    type: CLEAR_TOPICS,
  }
}

const getTopicsStart = () => {
  return {
    type: GET_TOPICS_START,
  }
}

const getTopicsSuccess = ({ topics }) => {
  const topicsById = getById({ data: topics })
  return {
    type: GET_TOPICS_SUCCESS,
    topics,
    topicsById,
  }
}

const getTopicsFailure = () => {
  return {
    type: GET_TOPICS_FAILURE,
  }
}

const apiCallTogetTopicssByChaptertId = ({ jwt, chapterId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/topics/chapter-id/${chapterId}`
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }
  return fetch(url, options)
}

export const fetchTopicsByChapterId = (data) => {
  return async (dispatch) => {
    dispatch(getTopicsStart())
    try {
      const receivedData = await apiCallTogetTopicssByChaptertId(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      let topics = response.data.topics
      dispatch(getTopicsSuccess({ topics }))
    } catch (err) {
      dispatch(getTopicsFailure())
    }
  }
}

const apiCallTogetTopicsByids = ({ jwt, topicIds }) => {
  let urlData = ''
  if (topicIds && topicIds.length > 0) {
    topicIds.forEach((id, index) => {
      urlData = urlData.concat(index ? '&ids=' + id : 'ids=' + id)
    })
  }
  const url = `${process.env.REACT_APP_SERVER}/api/topics?${urlData}`
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }
  return fetch(url, options)
}

export const fetchTopicsByids = (data) => {
  return async (dispatch) => {
    dispatch(getTopicsStart())
    try {
      const receivedData = await apiCallTogetTopicsByids(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      let topics = response.data.topics

      dispatch(getTopicsSuccess({ topics }))
    } catch (err) {
      dispatch(getTopicsFailure())
    }
  }
}

const addTopicStart = () => {
  return {
    type: ADD_TOPIC_START,
  }
}

const addTopicSuccess = () => {
  return {
    type: ADD_TOPIC_SUCCESS,
  }
}

const addTopicFailure = () => {
  return {
    type: ADD_TOPIC_FAILURE,
  }
}

const addTopic = ({ payload, jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/topics`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const fetchAddTopic = ({ data, jwt }) => {
  return async (dispatch) => {
    dispatch(addTopicStart())
    try {
      const receivedData = await addTopic({ payload: data, jwt })
      checkStatus(receivedData)
      dispatch(fetchTopicsByChapterId({ jwt, chapterId: data?.chapter_id }))
      dispatch(addTopicSuccess())
    } catch (err) {
      dispatch(addTopicFailure())
    }
  }
}

const editTopicStart = () => {
  return {
    type: EDIT_TOPIC_START,
  }
}

const editTopicSuccess = () => {
  return {
    type: EDIT_TOPIC_SUCCESS,
  }
}

const editTopicFailure = () => {
  return {
    type: EDIT_TOPIC_FAILURE,
  }
}

const editTopic = ({ payload, jwt, topicId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/topics/${topicId}`
  const options = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const fetchEditTopic = ({ data, topicId, jwt }) => {
  return async (dispatch) => {
    dispatch(editTopicStart())
    try {
      const receivedData = await editTopic({ payload: data, topicId, jwt })
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      dispatch(fetchTopicsByChapterId({ jwt, chapterId: data?.chapter_id }))
      dispatch(editTopicSuccess())
    } catch (err) {
      dispatch(editTopicFailure())
    }
  }
}

const deleteTopicStart = () => {
  return {
    type: DELETE_TOPIC_START,
  }
}

const deleteTopicSuccess = (topics) => {
  return {
    type: DELETE_TOPIC_SUCCESS,
    topics,
  }
}

const deleteTopicFailure = () => {
  return {
    type: DELETE_TOPIC_FAILURE,
  }
}

const deleteTopic = ({ jwt, topicId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/topics/${topicId}`
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchDeleteTopic = (data) => {
  return async (dispatch, getState) => {
    dispatch(deleteTopicStart())
    try {
      const receivedData = await deleteTopic(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      const { topic } = getState()
      const { topics } = topic
      const updatedTopics = topics.filter(
        (topic) => topic._id !== data?.topicId
      )
      dispatch(deleteTopicSuccess(updatedTopics))
    } catch (err) {
      dispatch(deleteTopicFailure())
    }
  }
}

const getTopicStart = () => {
  return {
    type: GET_TOPIC_START,
  }
}

const getTopicSuccess = (data) => {
  const { topic } = data
  return {
    type: GET_TOPIC_SUCCESS,
    topic,
  }
}

const getTopicFailure = () => {
  return {
    type: GET_TOPIC_FAILURE,
  }
}

const getTopic = ({ jwt, topicId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/topics/${encodeURIComponent(
    topicId
  )}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchTopic = (data) => {
  return async (dispatch) => {
    dispatch(getTopicStart())
    try {
      const receivedData = await getTopic(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getTopicSuccess(response?.data))
    } catch (err) {
      dispatch(getTopicFailure())
    }
  }
}

const getAllTopicsStart = () => {
  return {
    type: GET_ALL_TOPICS_START,
  }
}

const getAllTopicsSuccess = (data) => {
  const { topics } = data
  return {
    type: GET_ALL_TOPICS_SUCCESS,
    topics,
  }
}

const getAllTopicsFailure = () => {
  return {
    type: GET_ALL_TOPICS_FAILURE,
  }
}

const apiCallTogetAllTopics = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/topics`
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }
  return fetch(url, options)
}

export const fetchAllTopics = (data) => {
  return async (dispatch) => {
    dispatch(getAllTopicsStart())
    try {
      const receivedData = await apiCallTogetAllTopics(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getAllTopicsSuccess(response.data))
    } catch (err) {
      dispatch(getAllTopicsFailure())
    }
  }
}

// const getTopicsByFacultiesSuccess = (data) => {
//   const { topic } = data
//   return {
//     type: GET_TOPICS_BY_FACULTIES,
//     topic,
//   }
// }

const getTopicsByTeachers = ({ jwt, facultyIds }) => {
  let urlData = ''
  if (facultyIds && facultyIds.length) {
    facultyIds.forEach((id, index) => {
      urlData = urlData.concat(index ? '&author_ids=' + id : 'author_ids=' + id)
    })
  }
  const url = `${process.env.REACT_APP_SERVER}/api/topics/author-id?${urlData}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchTopicsByTeachers = (data) => {
  return async (dispatch) => {
    dispatch({ type: GET_TOPICS_BY_FACULTIES_START })
    try {
      const receivedData = await getTopicsByTeachers(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch({ type: GET_TOPICS_BY_FACULTIES, topics: response.data.topics })
    } catch (err) {
      dispatch({ type: GET_TOPICS_BY_FACULTIES_FAILURE })
    }
  }
}

const uploadFileStartTopic = () => {
  return {
    type: UPLOAD_FILE_START_TOPIC,
  }
}

const uplaodFileTopic = (data) => {
  return {
    type: UPLOAD_FILE_TOPIC,
    data,
  }
}

const uploadFileFailureTopic = () => {
  return {
    type: UPLOAD_FILE_FAIL_TOPIC,
  }
}

const emitFileUploadLInkTopic = (data, original) => {
  console.log(data)
  const links = Array.isArray(data)
    ? data.map((el) => {
        return {
          publicUrl: el.publicUrl,
          filename: el.filename,
        }
      })
    : [{ publicUrl: data.publicUrl, filename: data.filename }]
  return {
    // type: FILE_UPLOAD_SUCCESS,
    data: links,
    original,
  }
}

export const fileUploadsTopic = ({ jwt, fileData }) => {
  console.log(jwt, fileData)
  let Authorization = composeAuth(jwt)
  let url = process.env.REACT_APP_SERVER + '/api/s3/v2'

  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'multipart/form-data',
      Authorization,
    },
    fileData,
  })
}

export const startFileUploadsTopic = (data) => (dispatch) => {
  console.log(data)
  dispatch(uploadFileStartTopic())

  return fileUploadsTopic(data)
    .then(checkStatus)
    .then(parseJSON)
    .then((json) => {
      console.log('data inside startFileUpload', json)

      const formattedData = emitFileUploadLInkTopic(json.data, data)

      dispatch(uplaodFileTopic(formattedData.data))

      return formattedData
    })
    .catch((err) => {
      console.error('error occurred')
      dispatch(uploadFileFailureTopic())
    })
}
